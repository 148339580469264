import React, { useEffect, useState, useContext } from 'react';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
// import {makeStyles, Theme} from '@material-ui/core/styles';
import { styled } from '@mui/system'
import { AppBar, Box, LinearProgress, Tab, Tabs, Typography, CircularProgress, Grid, Button } from "@mui/material";
import { useLocation } from 'react-router-dom'

// import {ResearchCard} from "../cards/ResearchCard";
import { TabPanelProps } from "../../../interface";
import { MyContainer, MenuProfile, ResearchCard, ResearchModal } from '../../../components';
import Api from '../../../utils/api'
import { StateContext, DispatchContext } from '../../../store';
// import {positions} from "@material-ui/system";

const moment = extendMoment(Moment);
const currentDay = moment(new Date())
const interval = moment.rangeFromInterval('month', 2, currentDay)
const days = Array.from(interval.by('day', { step: 1 }));

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box pt={3} display={'flex'} flexDirection={'row'}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Root = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    width: '70%',
    margin: '20px 0px',
    marginTop: 102,
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginLeft: 0,
    },
}))

const BoxWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20
    },
}))

interface CustomizedState {
    surveyId: number
}

const Schedule = () => {
    const [schedules, setSchedules] = useState([])
    const [value, setValue] = React.useState(0);
    const [index, setIndex] = useState(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [showModal, setShowModal] = useState(false)
    const state = useContext(StateContext)
    const dispatch = useContext(DispatchContext)

    const location = useLocation()
    const { surveyId } = location.state as CustomizedState

    const fetchSchedules = async (date: string) => {
        setLoading(true)
        await Api.schedule(date, surveyId).then((res) => {
            const result = res.data
            setSchedules(result)
            console.log(res)
        })
        setLoading(false)
    }
    const handleSchedule = (index: number, date: string) => {
        fetchSchedules(date)
        setIndex(index)
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleCloseModal = () => {
        setShowModal(false)
    }

    useEffect(() => {
        fetchSchedules(days[0].format('YYYY-MM-DD'))
    }, [])

    return (
        <MyContainer wrapper={false}>
            <BoxWrapper>
                <MenuProfile />
                <Root>
                    <AppBar position="relative" color={'inherit'}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            aria-label="scrollable auto tabs example"
                        >
                            {days.map((day, index) => {
                                return (
                                    <Tab key={index} onClick={() => handleSchedule(index, day.format('YYYY-MM-DD'))}
                                        label={day.format('DD-MM-YYYY')} {...a11yProps(index)} />
                                )
                            })}
                        </Tabs>
                    </AppBar>
                    <Box height={2}>
                        {loading && <LinearProgress />}
                    </Box>
                    <TabPanel value={value} index={index}>
                        <Grid style={{ display: "flex", flexWrap: "wrap" }} container>
                            {schedules.length != 0 ? schedules.map((scheduleItem: any, index) => (
                                <Grid item lg={4} key={index}>
                                    <Box margin={'5px 9px'}>
                                        <Button onClick={() => {
                                            setShowModal(true)
                                            dispatch({ type: 'scheduleModal', payload: { datetime: scheduleItem.datetime, id: scheduleItem.id, name: scheduleItem.research.name } })
                                        }} variant={'contained'} color={'primary'}
                                        >
                                            {moment.tz(new Date(scheduleItem.datetime), '').format('HH:mm')}
                                        </Button>
                                    </Box>
                                </Grid>
                            )) : <Typography>На это день нет доступных приемов.</Typography>}
                        </Grid>
                    </TabPanel>
                </Root>
            </BoxWrapper>
            <ResearchModal showModal={showModal} handleCloseModal={handleCloseModal} />
        </MyContainer>
    );
}

export default Schedule