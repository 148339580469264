import React from 'react'

import StartScreen from './components/StartScreen'
import SixSurveyCaruselScreen from './components/SixSurveyCaruselScreen'
import ThreeScreen from './components/FiveScreen'

import { UsefulLinks } from '../../constructor'

const About = () => {
    return (
        <div>
            <StartScreen />
            {/* <TwoCaruselScreen /> */}

            {/* <CaruselPerson /> */}
            <ThreeScreen />
            {/* <UsefulLinks /> */}
            <SixSurveyCaruselScreen />
        </div>
    )
}

export default About