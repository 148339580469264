import React from "react";

import { FormattedMessage } from "react-intl";

const CardPerson = [
    {
        img: 'img/Element/Rectangle451.png',
        name: <FormattedMessage id={'aisen_name_slider'} />,
        tag: <FormattedMessage id={'aisen_position_slider'} />,
        infoImg: '',
        description: <FormattedMessage id={'aisen_description_slider'} />,
    },
]

export default CardPerson