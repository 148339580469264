import React, { useContext } from "react";
import { styled } from '@mui/system'
import { Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import Api from '../../../../../utils/api'
import { DispatchContext } from "../../../../../store";
import { ModalSurveyStatus, MyText, Form, MyButton } from '../../../../../components'
import { endometriosis_questions } from '../../../../../local_data/survey_form_questions'
import themeMain from '../../../../../theme'

const Root = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 75,
    paddingBottom: 50
})
export default function Endometrios() {
    const dispatch = useContext(DispatchContext)
    const location = useLocation()
    const navigate = useNavigate()

    const { handleSubmit, control } = useForm({
        defaultValues: {
            age: '',
            pelvicPain: '',
            painDuringIntercourse: '',
            spotting: '',
            spotting1: '',
            nervous: '',
            defecationPain: '',
            menstrualIrregularities: '',
            weakness: '',
            irritability: '',
            clotsBlood: '',
            pregnancyDoesNotOccur: ''
        },
    });
    let scoreInc = (answer) => {
        switch (answer) {
            case '2':
                return 'Да'
                break;
            case '1':
                return 'Иногда'
                break;
            case '0':
                return 'Нет'
                break;
            default:
                alert('Нет таких значений"');
        }
    }
    let scoreIncAge = (answer) => {
        switch (answer) {
            case '1':
                return '12-17'
                break;
            case '2':
                return '18–44'
                break;
            case '3':
                return '45 и старше'
                break;
            default:
                alert("Нет таких значений");
        }
    }
    const onSubmit = data => {
        Api.sendSurveys({
            research: location.state.surveyId,
            answers: [
                {
                    question: '1. Ваш возраст 12 -17 лет, 18–44, 45 и старше?',
                    answer: scoreIncAge(data.age),
                    score: data.age
                },
                {
                    question: '2. Есть ли у Вас болезненные менструации?',
                    answer: scoreInc(data.pelvicPain),
                    score: data.pelvicPain,
                },
                {
                    question: '3. Есть ли у вас мажущие выделения до или после менструации?',
                    answer: scoreInc(data.painDuringIntercourse),
                    score: data.painDuringIntercourse
                },
                {
                    question: '4. Тазовые боли или боли внизу живота во время менструального цикла, являющиеся причиной отсутствия на учебе или работе ?',
                    answer: scoreInc(data.spotting),
                    score: data.spotting
                },
                {
                    question: '5. Есть ли у Вас боль при половом акте?',
                    answer: scoreInc(data.spotting1),
                    score: data.spotting1
                },
                {
                    question: '6. Замечаете ли Вы сгустки крови «как печень» во время месячных?',
                    answer: scoreInc(data.nervous),
                    score: data.nervous
                },
                {
                    question: '7. Есть ли у Вас боль при акте дефекации или мочеиспускании, или примесь крови в моче во время менструации?',
                    answer: scoreInc(data.defecationPain),
                    score: data.defecationPain
                },
                {
                    question: '8. Планируете беременность в течение 1 года, но беременность не наступает?',
                    answer: scoreInc(data.menstrualIrregularities),
                    score: data.menstrualIrregularities
                },
                {
                    question: '9. Наблюдается ли у вас раздражительность, нервозность?',
                    answer: scoreInc(data.weakness),
                    score: data.weakness
                },
                {
                    question: '10. Бывают ли слабость, быстрая утомляемость, чаще чем обычно?',
                    answer: scoreInc(data.irritability),
                    score: data.irritability
                },
            ]
        }, dispatch, 'endometrios', navigate)
    };
    return (
        <Root component="main" maxWidth="md">
            <ModalSurveyStatus />
            <MyText variant="h5" sx={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 24 }}>Эндометриоз</MyText>
            <MyText variant="body1" sx={{ mt: 3, fontWeight: 'bold', fontStyle: 'normal' }}>Как узнать есть ли у вас эндометриоз, или начальная стадия эндометриоза и надо ли обратиться к врачу, пройдите краткую анкету, и ответьте на вопросы.</MyText>
            <Form onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', flexDirection: 'column' }}>
                {endometriosis_questions.map((item, index) => (
                    <FormControl component="fieldset" margin="normal" key={index}>
                        <FormLabel component="legend">{item.title}</FormLabel>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name={item.name}
                            render={({ field }) => (
                                <RadioGroup {...field} style={{ display: 'flex', flexDirection: 'row' }}>
                                    {item.radios.map((itemRadio, index) => (
                                        <FormControlLabel key={index} value={itemRadio.value} control={<Radio color="secondary" />} label={itemRadio.label} />
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                ))}
                <MyButton sx={{ mt: 3, bgcolor: themeMain.palette.primary.main }}>
                    СОХРАНИТЬ
                </MyButton>
            </Form>
        </Root>
    );
};