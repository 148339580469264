import React from 'react'

import { MyContainer, MyText } from '../../../components'
import { Grid, Box } from '@mui/material'
import { styled } from '@mui/system'

const TitleBox = styled(Box)({
    padding: 15,
    backgroundColor: '#01996D',
    textAlign: 'center',
    borderRadius: 5,
    color: 'white'
})

const ImgBox = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 50
    },
    [theme.breakpoints.down('md')]: {
        marginTop: 50,
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '-20px'
    },
}))

const FiveScreen = () => {
    return (
        <MyContainer wrapper={false} sx={{ mt: 8 }} minHeight={100}>
            <Box sx={{ textAlign: 'center' }}>
                <MyText variant="h6" sx={{ mt: 5, color: '#575A7B' }} md={16}>
                    Здоровье женщины имеет важное и ценное значение, ведь от здоровья матери напрямую зависит здоровье ребенка, т.е. будущего поколения.
                    Проект «Женское здоровье» разработан для сохранения и укрепления здоровья женского населения Республики Саха (Якутия).  Цель проекта - сохранение репродуктивного здоровья женщин и ранняя диагностика заболеваний женских органов.
                    В современном мире зачастую сложно уделять много времени своему здоровью.
                    Вопросы правильного планирования семьи, а именно успешного зачатия, нормального течения беременности и рождение здорового ребёнка являются основными  для женщин. Регулярное посещение врача-гинеколога позволяет сохранить женское здоровье и избежать репродуктивных проблем.
                    В период наступления менопаузы и после женщинам также стоит посещать гинеколога с целью сохранения качества жизни и профилактики сердечно-сосудистых заболеваний, остеопороза и тд.
                    Опытный специалист подберет соответствующую терапию, позволяющую существенно снизить выраженность проявлений менопаузы, тем самым облегчив состояние пациентки. Выявление  предраковых состояний и рака женских половых органов на ранних стадиях позволяет начать  своевременное лечение, чтобы сохранить самое ценное - жизнь.
                    Для участия в проекте необходимо пройти регистрацию, анкетирование. При выявлении среднего и высокого риска после прохождения анкет  предлагается пройти обследование на базе медицинских организаций города Якутска.
                </MyText>
            </Box>
        </MyContainer>
    )
}

export default FiveScreen