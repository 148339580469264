import React, { useContext } from "react";
import { styled } from '@mui/system'
import { Container, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import Api from '../../../../../utils/api'
import { DispatchContext } from "../../../../../store";
import { ModalSurveyStatus, MyText, Form, MyButton } from '../../../../../components'
import { climatic_period_questions } from '../../../../../local_data/survey_form_questions'
import themeMain from '../../../../../theme'

const Root = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 75,
    paddingBottom: 50
})

export default function BreathSurvey() {
    const dispatch = useContext(DispatchContext)
    const location = useLocation()
    const navigate = useNavigate()

    const { handleSubmit, control } = useForm({
        defaultValues: {
            champerPot: '',
            heartDisorder: '',
            insomnia: '',
            nerves: '',
            depression: '',
            headache: '',
            dizziness: '',
            weakness: '',
            paresthesia: '',
            hairLoss: '',
            drySkin: '',
            membrane: '',
            urination: '',
            vaginalDryness: '',
            jointPain: '',
            musclePain: '',
            plump: '',
            unpleasantBreast: '',
            natureOfMenstruation: '',
            libido: '',
            complaints: '',
            removedUterus: '',
            ovariesRemoved: ''
        },
    });
    let scoreInc = (answer) => {
        switch (answer) {
            case '2':
                return 'Да'
                break;
            case '1':
                return 'Иногда'
                break;
            case '0':
                return 'Нет'
                break;
            default:
                alert('Нет таких значений"');
        }
    }
    const onSubmit = (data) => {
        Api.sendSurveys({
            research: location.state.surveyId,
            answers: [
                {
                    question: '1. Наблюдете ли Вы у себя приливы или "ночной" пот?',
                    answer: scoreInc(data.champerPot),
                    score: data.champerPot
                },
                {
                    question: '2. Наблюдаете ли Вы у себя быстрое или сильное сердцебиение?',
                    answer: scoreInc(data.heartDisorder),
                    score: data.heartDisorder,
                },
                {
                    question: '3. Страдаете ли Вы бессонницей или изменениями ритма сна?',
                    answer: scoreInc(data.insomnia),
                    score: data.insomnia
                },
                {
                    question: '4. Наблюдете ли Вы в последнее время чаще, чем прежде нервозность, раздражительность или депрессивное состояние?',
                    answer: scoreInc(data.nerves),
                    score: data.nerves
                },
                {
                    question: '5. Беспокоят ли Вас частые головные боли?',
                    answer: scoreInc(data.depression),
                    score: data.depression
                },
                {
                    question: '6. Отмечаете ли Вы у себя сухость или зуд влагалища, в том числе боль или дискомфорт во время полового акта?',
                    answer: scoreInc(data.headache),
                    score: data.headache
                },
                {
                    question: '7. Отмечаете ли Вы потерю интереса к сексу?',
                    answer: scoreInc(data.dizziness),
                    score: data.dizziness
                },
                {
                    question: '8. Беспокоит ли Вас недержание мочи – теряете ли Вы непроизвольно капли?',
                    answer: scoreInc(data.weakness),
                    score: data.weakness
                },
                {
                    question: '9. Бывают ли у Вас частые падения и травмы (переломы конечностей)?',
                    answer: scoreInc(data.paresthesia),
                    score: data.paresthesia
                },
                {
                    question: '10. Операции по  удалению органов репродуктивной системы (матка, яичники)?',
                    answer: scoreInc(data.hairLoss),
                    score: data.hairLoss
                },
            ]
        }, dispatch, 'climatic_period', navigate)
    }
    return (
        <Root component="main" maxWidth="md">
            <ModalSurveyStatus />
            <MyText variant="h5" sx={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 24 }}>Климактерический синдром</MyText>
            <MyText variant="body1" sx={{ mt: 3, fontWeight: 'bold', fontStyle: 'normal' }}>Чтобы оценить выраженность у Вас климактерирического с-ма, прочтите эту анкету, ответьте на вопросы. Затем оцените каждый ответ следующим образом:</MyText>
            <Form onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', flexDirection: 'column' }}>
                {climatic_period_questions.map((item, index) => (
                    <FormControl component="fieldset" margin="normal" key={index}>
                        <FormLabel component="legend">{item.title}</FormLabel>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name={item.name}
                            render={({ field }) => (
                                <RadioGroup {...field} style={{ display: 'flex', flexDirection: 'row' }}>
                                    {item.radios.map((itemRadio, index) => (
                                        <FormControlLabel key={index} value={itemRadio.value} control={<Radio color="secondary" />} label={itemRadio.label} />
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                ))}
                <MyButton sx={{ mt: 3, bgcolor: themeMain.palette.primary.main }}>
                    СОХРАНИТЬ
                </MyButton>
            </Form>
        </Root>
    );
};