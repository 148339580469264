import React, { useContext, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, Dialog } from "@mui/material";
import { styled } from '@mui/system'
import { FormattedMessage } from "react-intl";

import { StateContext } from '../../store'
import { MyText } from '..'

const Modal = styled(Dialog)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const Paper = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
    '&:focus': {
        outline: 'none',
        opacity: 100,
        zIndex: 1,
    },
    width: 500,
    borderRadius: 20,
    transition: 'all 0.4s',
    padding: 20,
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
}))

interface ModalContacts {
    open: any,
    handleClose: any
}

const ModalContacts: React.FC<ModalContacts> = ({ open, handleClose }) => {
    const [name, setName] = React.useState('')
    const state = useContext(StateContext)

    const contacts_data = [
        {
            title: '',
            text: [
                {
                    label: 'Единый контактный центр',
                    phone: '8 800 100-14-03'
                },
                {
                    label: 'Единый контакт центр медицинских организаций',
                    phone: '122'
                }
            ]
        },
        {
            title: 'ГАУ РС (Я) "Поликлиника №1"',
            text: [
                {
                    label: 'Контакт-центр',
                    phone: '8 984  116 46 17'
                },
            ]
        },
        {
            title: 'ГАУ РС(Я) «Медицинский центр г. Якутска»',
            text: [
                {
                    label: 'Контакт-центр',
                    phone: '508-805'
                },
            ]
        },
        {
            title: 'ГАУ РС(Я) «Якутская городская больница №3»',
            text: [
                {
                    label: 'Контакт-центр',
                    phone: '507-507'
                },
            ]
        },
        {
            title: 'ГБУ РС(Я) «Якутская городская больница №2»',
            text: [
                {
                    label: 'Единый контакт-центр',
                    phone: '122 '
                },
            ]
        },
        {
            title: 'АНО «Белая Роза-Саха»',
            text: [
                {
                    label: 'Контакт-центр',
                    phone: '79142758855'
                },
            ]
        }
    ]

    return (
        <div>
            <Modal onClose={handleClose}
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description">
                <Paper style={{ paddingTop: 15 }}>
                    <Typography variant="h5" gutterBottom >
                        Министерство здравоохранения Республики Саха (Якутия)
                    </Typography>
                    <Box>
                        {contacts_data.map((item, index) => (
                            <Box sx={{ mt: 1 }}>
                                <MyText>
                                    {item.title}
                                </MyText>
                                <Box>
                                    {item.text.map((item, index) => (
                                        <MyText variant="body1">{item.label} - <span>{item.phone}</span></MyText>
                                    ))}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Button variant="contained" color={'secondary'} onClick={handleClose} sx={{ mt: 1 }}>
                        Закрыть
                    </Button>
                </Paper>
            </Modal>
        </div>
    );
}

export default ModalContacts