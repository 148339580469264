import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import { FormattedMessage } from "react-intl";

import Api from "../../../utils/api";
import { SurveyProps } from "../../../interface";
import themeMain from "../../../theme";
import {
    MenuProfile,
    MyContainer,
    CardSurveysProfile,
    MyText,
    ModalSurvey,
} from "../../../components";
import { DispatchContext } from "../../../store";

const BoxWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
}));

const Surveys = () => {
    const [data, setData] = useState<SurveyProps[]>([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const dispatch = useContext(DispatchContext);
    useEffect(() => {
        Api.getSurveysTypes().then((res) => {
            const data = res.data;
            console.log(data);
            setData(res.data);
            setLoading(false);
            setOpen(true);
        });
    }, []);
    return (
        <MyContainer wrapper={false} sx={{ pb: 10 }}>
            {/* {open &&
                <ModalSurvey />
            } */}
            <BoxWrapper>
                <MenuProfile />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        mt: 10,
                        ml: 2,
                        width: "100%",
                    }}
                >
                    <MyText
                        variant="h5"
                        sx={{ color: themeMain.palette.primary.main }}
                    >
                        <FormattedMessage id="surveys_main_page" />
                    </MyText>
                    {loading ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: 3,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container>
                            {data.length ? (
                                data.map((item, index) => (
                                    <Grid
                                        item
                                        lg={4}
                                        xl={4}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                        key={index}
                                    >
                                        <CardSurveysProfile
                                            label={item.name}
                                            img={item.image}
                                            link={item.page_link}
                                            id={item.id}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <MyText
                                    variant="h6"
                                    sx={{ margin: "0 auto", mt: 5 }}
                                >
                                    В соответствии с Вашим полом и
                                    возрастом, доступных для Вас анкет
                                    - нет.
                                </MyText>
                            )}
                        </Grid>
                    )}
                </Box>
            </BoxWrapper>
        </MyContainer>
    );
};

export default Surveys;
