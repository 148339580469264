import React, { useContext } from "react";
import { styled } from '@mui/system'
import { Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import Api from '../../../../../utils/api'
import { DispatchContext } from "../../../../../store";
import { ModalSurveyStatus, MyText, Form, MyButton } from '../../../../../components'
import { infertility_questions } from '../../../../../local_data/survey_form_questions'
import themeMain from '../../../../../theme'

const Root = styled(Container)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: 75
})

export default function Infertility() {
    const dispatch = useContext(DispatchContext)
    const location = useLocation()
    const navigate = useNavigate()

    const { handleSubmit, control } = useForm({
        defaultValues: {
            age: '',
            pelvicPain: '',
            painDuringIntercourse: '',
            spotting: '',
            spotting1: '',
            nervous: '',
            defecationPain: '',
            menstrualIrregularities: '',
            weakness: '',
            irritability: '',
            clotsBlood: '',
            pregnancyDoesNotOccur: ''
        },
    });
    let scoreInc = (answer) => {
        switch (answer) {
            case '2':
                return 'Да'
                break;
            case '0':
                return 'Нет'
                break;
            default:
                alert('Нет таких значений"');
        }
    }
    let scoreIncSpotting = (answer) => {
        switch (answer) {
            case '1':
                return 'Только роды '
                break;
            case '2':
                return 'Роды, аборты, выкидыши '
                break;
            case '3':
                return 'Только аборты или только выкидыши или неразвивающаяся беременность'
                break;
            default:
                alert('Нет таких значений"');
        }
    }
    let scoreIncSpotting1 = (answer) => {
        switch (answer) {
            case '1':
                return '1 -2 раза в неделю '
                break;
            case '2':
                return '2-3 раза в месяц '
                break;
            case '3':
                return 'Не чаще 1 раза в месяц '
                break;
            default:
                alert('Нет таких значений"');
        }
    }
    let scoreIncAge = (answer) => {
        switch (answer) {
            case '1':
                return 'Меньше 35 лет'
                break;
            case '3':
                return '35-39 лет '
                break;
            case '4':
                return '40 и старше '
                break;
            default:
                alert("Нет таких значений");
        }
    }
    const onSubmit = data => {
        Api.sendSurveys({
            research: location.state.surveyId,
            answers: [
                {
                    question: '1. Ваш возраст',
                    answer: scoreIncAge(data.age),
                    score: data.age
                },
                {
                    question: '2. Сколько месяцев не наступает беременность ',
                    answer: data.pelvicPain === '2' ? '12 месяцев и более' : '6-11 месяцев',
                    score: data.pelvicPain,
                },
                {
                    question: '3. Имеются ли нарушения менструальной функции (сильная болезненность в дни менструации, нерегулярная менструация)',
                    answer: data.painDuringIntercourse === '3' ? 'Да' : 'Нет',
                    score: data.painDuringIntercourse
                },
                {
                    question: '4. Чем закончились предыдущие беременности',
                    answer: scoreIncSpotting(data.spotting),
                    score: data.spotting
                },
                {
                    question: '5. При наличии постоянного полового партнера – регулярность половой жизни',
                    answer: scoreIncSpotting1(data.spotting1),
                    score: data.spotting1
                },
                {
                    question: '6. Имелись ли ранее гинекологические операции',
                    answer: scoreInc(data.nervous),
                    score: data.nervous
                },
                {
                    question: '7. Были ли у Вас ранее воспалительные гинекологические заболевания или заболевания, передающиеся половым путем (хламидии, гонорея, трихомониаз, генитальный микоплазмоз)?',
                    answer: scoreInc(data.defecationPain),
                    score: data.defecationPain
                },
                {
                    question: '8. Имеются ли у вашего полового партнера эректильные и сексуальные нарушения (снижение полового влечения, слабая эрекция), варикозное расширение вен мошонки и/или воспалительные заболевания половых органов',
                    answer: scoreInc(data.menstrualIrregularities),
                    score: data.menstrualIrregularities
                },
            ]
        }, dispatch, 'infertility', navigate)
    };

    return (
        <Root component="main" maxWidth="md">
            <ModalSurveyStatus />
            <MyText variant="h5" sx={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: 24 }}>Бесплодие</MyText>
            <Form onSubmit={handleSubmit(onSubmit)} sx={{ display: 'flex', flexDirection: 'column' }}>
                {infertility_questions.map((item, index) => (
                    <FormControl component="fieldset" margin="normal" key={index}>
                        <FormLabel component="legend">{item.title}</FormLabel>
                        <Controller
                            rules={{ required: true }}
                            control={control}
                            name={item.name}
                            render={({ field }) => (
                                <RadioGroup {...field} style={{ display: 'flex', flexDirection: 'row' }}>
                                    {item.radios.map((itemRadio, index) => (
                                        <FormControlLabel key={index} value={itemRadio.value} control={<Radio color="secondary" />} label={itemRadio.label} />
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                ))}
                <MyButton sx={{ mt: 3, bgcolor: themeMain.palette.primary.main }}>
                    СОХРАНИТЬ
                </MyButton>
            </Form>
        </Root>
    );
};