import React from 'react'
import { Grid, Box, Container } from '@mui/material'
import { styled } from '@mui/system'

import { MyText, MyContainer } from '../../../components'
import { PageHeaderText } from '../../../constructor'
import { FormattedMessage } from "react-intl";

const TextBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: 80
}))

const GridContainer = styled(Grid)(({ theme }) => ({
    marginTop: 80,
}))


const BoxCard = styled(Box)(({ theme }) => ({
    backgroundColor: 'white',
    color: 'black',
    padding: 5,
    minHeight: 200,
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
        marginTop: 10,
        marginBottom: 10
    },
}))

const StartScreen = () => {
    const data = [
        {
            number: '48.8 лет',
            description: 'Средний возраст наступления менопаузы в мире'
        },
        {
            number: '49-51 год',
            description: 'Средний возраст наступления менопаузы в РФ'
        },
        {
            number: '7.4 года',
            description: 'Средняя продолжительность приливов'
        },
        {
            number: '75%',
            description: 'Симптомы климакса беспокоят женщин в возрасте от 45-55 лет'
        }
    ]

    return (
        <MyContainer wrapper={true} sx={{
            background: `url(${"/img/Background/Rectangle471.png"})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom',
            color: 'white',
            pb: 25
        }}
        // lg={1100}
        // md={1300}
        // sm={1700}
        >
            <TextBox>
                <PageHeaderText
                    title='Женское здоровье'
                    titleSize="h2"
                    descriptionColor="white"
                    tagColor="white"
                />
                <GridContainer container>
                    {data.map(item => (
                        <Grid item lg={3} xl={3} md={3} sm={6} xs={12} key={item.number}>
                            <BoxCard>
                                <MyText variant="h4" sx={{ fontWeight: 600 }}>{item.number}</MyText>
                                <MyText variant="body1" sx={{ width: '95%' }}>{item.description}</MyText>
                            </BoxCard>
                        </Grid>
                    ))}
                </GridContainer>
            </TextBox>
        </MyContainer>
    )
}

export default StartScreen