import React from "react";

import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import cookie from "js-cookie";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { MyLink } from "..";

const Root = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: 10,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
    width: "max-content",
    marginTop: 100,
    height: "max-content",
    [theme.breakpoints.down("sm")]: {
        width: "90%",
    },
}));

const MenuProfile = () => {
    const navigate = useNavigate();
    const menuList = [
        {
            label: <FormattedMessage id="profile_menu" />,
            link: "/form-profile",
            disabled: false,
        },
        {
            label: <FormattedMessage id="surveys_main_page" />,
            link: "/surveys",
            disabled: false,
        },
        {
            label: <FormattedMessage id="results" />,
            link: "/results",
            disabled: false,
        },
        {
            label: <FormattedMessage id="schudule" />,
            link: "/schedule-allowed",
            disabled: false,
        },
        {
            label: "Приёмы",
            link: "/my-schedule",
            disabled: false,
        },
    ];
    return (
        <Root>
            {menuList.map((item, index) => (
                <MenuItem
                    key={index}
                    sx={{
                        mt: 0.5,
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                    }}
                    disabled={item.disabled}
                    onClick={() => navigate(item.link)}
                >
                    {item.label}
                </MenuItem>
            ))}
            <a
                href="/"
                style={{
                    color: "rgba(0, 0, 0, 0.5)",
                    fontStyle: "normal",
                    textDecoration: "none",
                }}
            >
                <MenuItem
                    sx={{
                        color: "rgba(0, 0, 0, 0.5)",
                        fontStyle: "normal",
                        mt: 0.5,
                    }}
                    onClick={() => {
                        cookie.remove("jwttoken");
                    }}
                >
                    <FormattedMessage id="back" />
                </MenuItem>
            </a>
        </Root>
    );
};

export default MenuProfile;
