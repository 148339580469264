import React, { useState } from 'react'

import { InfoScreen, TextInfoScreen, ThreeScreen, FourScreen, LinkToSurveysScreen, MyContainer, MyButton } from '../../../components'

import { CaruselSurvey, TitleScreen } from '../../../constructor'
import { FormattedMessage } from "react-intl";
import { useNavigate } from 'react-router-dom'
import cookie from 'js-cookie'

const Cervix = () => {
    const [state, setState] = useState(false)
    const navigate = useNavigate()
    const data1 = [
        {
            title: 'Факторы риска:',
            type: 'column',
            elem: [
                'раннее начало половой жизни;',
                'частая смена половых партнеров;',
                'отказ от барьерной контрацепции (презервативов);',
                'курение;',
                'прием иммуносупрессивных препаратов; ',
                'травмы шейки матки в родах;',
                'наличие хронических воспалительных заболеваний как эрозия, лейкоплакия и другие;',
                'инфекции, передаваемые половым путем;'
            ],
        },
        // {
        //     type: 'column',
        //     elem: [
        //         <FormattedMessage id="cervix_data1_text1_column1" />,
        //         <FormattedMessage id="cervix_data1_text2_column1" />,
        //         <FormattedMessage id="cervix_data1_text3_column1" />,
        //         <FormattedMessage id="cervix_data1_text4_column1" />,
        //         <FormattedMessage id="cervix_data1_text5_column1" />,
        //         <FormattedMessage id="cervix_data1_text6_column1" />,
        //         <FormattedMessage id="cervix_data1_text7_column1" />,
        //         <FormattedMessage id="cervix_data1_text8_column1" />,
        //     ],
        // },
        {
            title: 'Симптомы РШМ.',
            type: 'text',
            elem: [
                'К наиболее часто встречающимся симптомам РШМ относятся обильные водянистые бели и «контактные» кровянистые выделения из половых путей.',
                'При значительном распространении опухоли появляются боли, нарушения мочеиспускания и затруднения при акте дефекации',
            ],
        },
        {
            title: 'МЕТОДЫ ДИАГНОСТИКИ РШМ',
            type: 'text',
            elem: [
                'Скрининг рака шейки матки заключается в регулярном исследовании цитологических мазков (ПАП-тест), полученных с шейки матки, а также в проведении ВПЧ-тестирования и жидкостной цитологии. При подозрении на предопухолевое или опухолевое заболевание выполняется кольпоскопия с прицельной биопсией с подозрительных участков. Данная методика позволяет не только выявить рак шейки матки на начальных стадиях, но и предупредить его развитие. Регулярный скрининг РШМ позволяет снизить заболеваемость раком шейки матки на 80% и смертность от этого заболевания на 72%',
                'В рамках реализации Национального проекта «Здравоохранение» разработан универсальный анкетный скрининг для выявления групп риска с учетом региональной специфики.',
            ],
        },
        {
            title: 'ВНИМАНИЕ!!!!',
            type: 'text',
            elem: [
                'Так как АНО МДЦ "Белая роза - Саха" проводит комплексное обследование женщин, просим  при записи на обследование учитывать фазу менстр цикла для повышения качества диагностики .',
                '-Женщины без регулярного менстр цикла ( климакс, стойкое нарушение менструации, более 3 мес) записываются в любой день.',
                '-Женщины с регулярным менструальным циклом записываются с 5-12 день ( считать м/ц  с 1го дня менструации).',
                'При записи на приём просим обратить внимание на противопоказания к скринингу на РШМ.',
                'Не следует сдавать анализы:',
            ],
        },
        {
            type: 'column',
            elem: [
                'ранее 48 часов после полового акта;',
                'во время менструации;',
                'во время лечения генитальных инфекций (введение свечей необходимо отменить за 48часов до исследования); ',
                'во время лечения генитальных инфекций (введение свечей необходимо отменить за 48часов до исследования); ',
                'ранее 48 часов после кольпоскопии, спринцеваний, введения спермицидов, тампонов, любрикантов;',
                'ранее 3-6 месяцев после сдачи мазка на онкоцитологию в любой другой клинике.'
            ],
        },
    ]

    const data2 = [
        {
            title: <FormattedMessage id="cervix_data1_title_column2" />,
            type: 'column',
            elem: [
                <FormattedMessage id="cervix_data1_text1_column2" />,
                <FormattedMessage id="cervix_data1_text2_column2" />,
                <FormattedMessage id="cervix_data1_text3_column2" />
            ],
        },
    ]

    const data3 = [
        {
            text: [
                <FormattedMessage id="cervix_data3_text1" />,
                <FormattedMessage id="cervix_data3_text2" />,
            ],
        },
    ]

    return (
        <div>
            <InfoScreen
                title={<FormattedMessage id="cervix_cancer" />}
                description1='РАК ШЕЙКИ МАТКИ (далее РШМ) - это злокачественная опухоль, которая развивается из слизистой оболочки шейки матки. РШМ является наиболее часто встречающейся злокачественной опухолью женской половой системы и стабильно занимает 5-е место в структуре онкологической заболеваемости женщин в России.'
                description2="Злокачественные новообразования шейки матки составляют 10% от всех злокачественных новообразований у женщин  в России. 
                В Республике Саха (Якутия) в 2021 году было выявлено 130 случаев рака шейки матки. При этом на I-II стадии процесса было диагностировано 58,5% заболеваний, на III стадии – 33,1% и на IV стадии – 8,5%.
                Доказано, что основной причиной развития РШМ является вирус папилломы человека (далее -ВПЧ) онкогенных типов. 
ВПЧ - это общее название группы вирусов, чрезвычайно широко распространенных во всем мире. Известно более 100 типов ВПЧ, из которых 14 являются онкогенными. ВПЧ передается, главным образом, половым путем, и большинство людей заражаются ВПЧ вскоре после начала половой жизни. В 70% случаев причиной РШМ оказываются ВПЧ двух типов -16-го и 18-го.
"
                img="cervix34"
                descriptionSize='h6'
            />
            <TextInfoScreen data={data1} />
            <ThreeScreen data={data2} showInfoText={true} />
            <FourScreen data={data3} />
            <LinkToSurveysScreen link="" />

            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MyButton sx={{ bgcolor: '#EB5757', mb: 2 }}
                    onClick={() => {
                        navigate(cookie.get('jwttoken') ? '/surveys' : '/login')
                    }}
                >
                    <FormattedMessage id="view_surveys_lung_cancer" />
                </MyButton>
            </div>
            <MyContainer
                wrapper={false}
                minHeight={600}
            >
                <CaruselSurvey />
            </MyContainer>
        </div>
    )
}

export default Cervix