import React from "react";

import { styled } from "@mui/system";
import {
    Box,
    Card,
    CardMedia,
    MenuItem,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import { CardProps } from "../../interface";
import { MyText, MyLink } from "..";

const CardRoot = styled(Box)(({ theme }) => ({
    width: "95%",
    minHeight: 400,
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginTop: 20,
    boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.15)",
    borderRadius: "25px 0px",
    transition: "all 1s ease",
    "&:hover": {
        boxShadow: "0px 0px 20px rgba(0,0,0,0.8)",
    },
    [theme.breakpoints.down("sm")]: {
        height: "90%",
        padding: 10,
    },
}));

const CardImg = styled(CardMedia)(({ theme }) => ({
    width: 210,
    height: 210,
}));

const Box2 = styled(Box)(({ theme }) => ({
    height: "50%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 20,
}));

const ResearchCard: React.FC<CardProps> = ({ schedule }) => {
    return (
        <CardRoot>
            <Box>
                <CardImg
                    image={`https://xn-----8kcahlfadeo7a1bpea7akm5f8g.xn--p1ai${schedule.research.image}`}
                />
            </Box>
            <Box2>
                <MyText variant="h6" sm={25}>
                    {schedule.research.name}
                </MyText>
                <MyText variant="body1" sx={{ marginTop: 1 }}>
                    {schedule.research.address}
                </MyText>
                <MyText variant="body1" sx={{ mt: 2 }}>
                    Дата:{" "}
                    {moment
                        .tz(new Date(schedule.datetime), "")
                        .format("YYYY-MM-DD")}
                </MyText>
                <MyText variant="body1">
                    Время:{" "}
                    {moment
                        .tz(new Date(schedule.datetime), "")
                        .format("HH:mm")}
                </MyText>
            </Box2>
        </CardRoot>
    );
};

export default ResearchCard;
