import React, { useState } from 'react'

import { InfoScreen, TextInfoScreen, ThreeScreen, LinkToSurveysScreen, MyContainer, MyButton, MyText } from '../../../components'

import { CaruselSurvey, TitleScreen } from '../../../constructor'
import { FormattedMessage } from "react-intl";
import { useNavigate } from 'react-router-dom'
import cookie from 'js-cookie'

const Liver = () => {
    const navigate = useNavigate()
    const data1 = [
        {
            title: '',
            type: 'text',
            elem: [
                'По данным Российской ассоциации гинекологов-эндокринологов, климактерический синдром встречается у 37 % женщин в пременопаузе, у 40 % – в менопаузе и у 21 % – в постменопаузе.',
                'Самые распространенные симптомы климактерического синдрома – приливы жара или озноба. Они беспокоят около 75 % женщин в перименопаузе. Большинство испытывает приливы около двух лет, однако часто женщины страдают от них более 10 лет. Вазомоторные симптомы вызывают физические и психосоциальные затруднения и являются одними из основных причин, по которым женщины обращаются за врачебной помощью при менопаузе',
                'Климактерический синдром у женщин подразделяется на пременопаузу (от 45–47 лет до менопоузы), менопаузу (примерно от 50 лет, период прекращения менструации) и постменопаузу (менопауза продолжается 6–8 лет с момента прекращения менструации). Время наступления климактерических фаз связано с состоянием здоровья женщины, условиями труда, нюансами рациона питания, климатическими условиями и др.',
            ],
        },
        {
            title: 'Возраст развития климактерического синдрома',
            type: 'text',
            elem: [
                'Средний возраст наступления менопаузы в мире 48 лет. Средний возраст наступления менопаузы в России  49 - 51 год',
                'В 45 - 55 лет приливы беспокоят 75% женщин, в 28.5% случаев ─ средней или тяжелой степени.',
                'Вазомоторные симптомы максимально выражены в первые годы постменопаузы.'
            ],
        },
        {
            title: 'Причины развития климактерического синдрома',
            type: 'text',
            elem: [
                'В результате дефицита гормонов изменения в организме женщины появляются еще в пременопаузе. Период менопаузы характеризуется постепенным снижением, а затем и «выключением» функции яичников с полным исчезновением фолликулов',
            ],
        },
        {
            title: ' Характерные проявления климактерического синдрома',
            type: 'text',
            elem: [
                'Все симптомы можно разделить на три основные группы: нервно-психические, вегето-сосудистые и эндокринные.',
                'К нервно-психическим симптомам относятся: раздражительность, колебания настроения, снижение внимания, ухудшение памяти, чувство тоски и тревоги',
                'Среди вегетативно-сосудистых расстройств приливы жара к голове, рукам, верхней половине туловища, головная боль, головокружение, тахикардия, повышенная потливость, вагоинсулярные кризы.',
                'Эндокринные симптомы: остеопороз (повышение риска переломов), трофические изменения кожи, вульвы, мочевого пузыря, влагалища, гипергликемия.',
                'Серьезная гормональная перестройка организма может постепенно привести к сердечно-сосудистым заболеваниям, инсулинорезистентности, ожирению, остеопорозу, маточным кровотечениям, миоме матки.'
            ],
        },
        //     { 
        //         type: 'column',
        //         elem: [
        //             'инсулинорезистентностью',
        //             'эндотелиальной дисфункцией',
        //             'кальцификацией аорты',
        //             'толщиной интимы-медии (ТИМ) сонной артерии',
        //             'уровнем маркеров коагуляции и воспаления'
        //         ],
        //     },
        //     {
        //         title: 'Эпидемиология',
        //         type: 'text',
        //         elem: [
        //             'Средний возраст наступления менопаузы в мире 48 лет',
        //             'Средний возраст наступления менопаузы в РФ 49-51 год.',
        //             'В пери- и постменопаузе более 21 млн. россиянок',
        //             'В 45 - 55 лет приливы беспокоят 75% женщин, в 28.5% случаев - средней или тяжелой степени',
        //             'Вазомоторные симптомы максимально выражены в первые годы постменопаузы.',
        //             'Средняя продолжительность приливов 7.4 года, но могут продолжаться ≥10 лет',
        //         ],
        //     },
        //     {
        //         title: 'Классификация',
        //         type: 'text',
        //         elem: [
        //             'Типы менопаузы:'
        //         ],
        //     },
        //     {
        //         type: 'column',
        //         elem: [
        //             'самопроизвольная (естественная)',
        //             'ятрогенная (вторичная) после двухсторонней овариоэктомии, химио- или лучевой терапии',
        //             'преждевременная (до 40 лет)',
        //             'ранняя (40-44 года)',
        //             'своевременная (45-55 лет)',
        //             'поздняя (старше 55 лет)'
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'Стадии старения репродуктивной системы женщин STRAW+10:',
        //             'Период менопаузального перехода с 40-45 лет и до менопаузы. На фоне задержек менструаций могут появляться вазомоторные, психологиеческие, урогенитальные симптомы дефицита эстрогенов.',
        //             'Менопауза - последняя самостоятельная менструация. Дата оценивается ретроспективно - спустя 12 мес. отсутствия менструации.',
        //             'Перименопауза включает период менопаузального перехода + 12 мес. после последней самостоятельной менструации.',
        //             'Постменопауза - период после наступления менопаузы:',
        //         ],
        //     },
        //     {
        //         type: 'column',
        //         elem: [
        //             'Ранняя постменопаузальная фаза (+1a, +1b, +1c) продолжается 5-8 лет, персистируют симптомы климактерического синдрома.',
        //             'Поздняя постменопаузальная фаза (+2): вазомоторные симптомы мало выражены, но у 15% персистируют длительно, превалирует соматическое старение.',
        //         ],
        //     },
        //     {
        //         title: 'ДИАГНОСТИКА',
        //         type: 'text',
        //         elem: [
        //             'Жалобы и анамнез',
        //             'Характерные проявления:',
        //         ],
        //     },
        //     {
        //         type: 'column',
        //         elem: [
        //             'вазомоторные — приливы, повышенная потливость;',
        //             'психоэмоциональные — депрессия, раздражительность, возбудимость, расстройствосна, слабость, снижение памяти и концентрации внимания;',
        //             'урогенитальные и сексуальные — зуд, жжение, сухость во влагалище, диспареуния, дизурия;',
        //             'скелетно-мышечные — остеопения, остеопороз; повышение риска переломов; саркопения.'
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'Многие женщины испытывают более одной группы симптомов.',
        //             'Тяжесть менопаузальных симптомов определяется по шкале Грина.',
        //         ],
        //     },
        //     {
        //         title: 'Лабораторная диагностика',
        //         type: 'text',
        //         elem: [
        //             'Гормональное обследование (кровь)',
        //         ],
        //     },
        //     {
        //         type: 'column',
        //         elem: [
        //             'ФСГ на 2-4 день менструального цикла;',
        //             'Эстрадиол;',
        //             'АМГ у женщин до 40 лет с подозрением на преждевременную недостаточность яичников;',
        //             'ТТГ для диффдиагностики заболевания щитовидной железы;',
        //             'Пролактин для диффдиагностики нарушений менструального цикла.',
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'При признаках генитоуринарного синдрома:',
        //         ],
        //     },
        //     {
        //         type: 'column',
        //         elem: [
        //             'цитологическое исследование: определение индекса созревания эпителия влагалища (ИСЭВ);',
        //             'рН влагалищного содержимого.'
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'При анамнезе переломов и факторах риска остеопороза - определение уровня витамина D.',
        //         ],
        //     },
        //     {
        //         title: 'Инструментальеая диагностика',
        //         type: 'text',
        //         elem: [
        //             'Измерение:',
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'АД - важного факторая риска сердечно-сосудистых нарушений;',
        //             'окружности талии (ОТ) - ключесвого факторая висцерального ожирения;',
        //             'веса, роста и ИМТ - важного фаткорая риска метаболических нарушений.'
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'Трансвагинальная ультрасонография для определения признаков “старения” яичников: уменьшения объема и измерения числа антральных фолликулов.',
        //             'Комплексное уродинамическое обследование при нарушениях мочеиспускания с определоением:'
        //         ],
        //     },
        //     {
        //         type: 'column',
        //         elem: [
        //             'объема мочевого пузыря,',
        //             'максимальной скорости потока мочи,',
        //             'максимального уретрального сопротивления и его индекса,',
        //             'внезапных подъемов уретального и/или детрузорного давления,',
        //             'остаточной мочи.'
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'При анамнезе переломов и факторах риска остеопороза - денситометрия (двух\нергетическая рентгеновская абсорбциометрия (ДЭРА) поясничного отдела позвоночника и шейки бедра.',
        //             'Интерпретация ДЭРА (по ВОЗ):',
        //         ],
        //     },
        //     {
        //         type: 'column',
        //         elem: [
        //             'норма - Т-критерий от +2,5 до -1 стандартных отклонений от пиковой костной массы;',
        //             'остеопения - Т-критерий от -1 до -2,5 стандартных отклонений;',
        //             'остеопороз - Т-критерий -2,5 стандартных отклонений и ниже;',
        //             'тяжелый остеопороз - Т-критерий ниже -2,5 стандартных отклонений с анамнезом одного и более переломов.'
        //         ],
        //     },
        //     {
        //         type: 'text',
        //         elem: [
        //             'При факторах риска остеопороза в постменопаузе у женщины старше 50 лет проводится оценка индивидуальной 10-летней вероятности переломов (FRAX).',
        //         ],
        //     },
    ]

    // const data2 = [
    //     {
    //         title: 'К основным методам диагностики климактерического периода относятся: ',
    //         type: 'column',
    //         elem: [
    //             'Рекомендуется проводить гормональное обследование  особенно, в случае неясного менопаузального статуса (уровень ФСГ в крови на 2-4 день менструального цикла;уровень эстрадиола в крови; уровень ТТГ в крови для дифференциальной диагностики заболеваний щитовидной железы; уровень пролактина в крови для дифференциальной диагностики нарушений менструального цикла).',
    //             'Биохимическое исследование показателей анализа крови и свертывающих факторов (коагулограмма).',
    //             'Рекомендуется при наличии переломов в анамнезе/ наличии факторов риска остеопороза проведение денситометрии (двухэнергетическая рентгеновская абсорбциометрия (ДЭРА) поясничного отдела позвоночника и шейки бедра ',
    //             'УЗИ органов малого таза;',
    //             'УЗИ молочных желез и маммографию;',
    //             'Цитологический анализ мазков из шейки матки и цервикального канала;'
    //         ],
    //     },
    // ]

    return (
        <div>
            <InfoScreen
                title='Климактерический синдром'
                description1='Климактерический синдром (климакс)  - состояние, которое характеризуется нервно-психическими, вегето-сосудистыми, эндокринными и др. нарушениями, возникающими в связи с возрастными изменениями в климактерический синдром на фоне угасания репродуктивных функций.'
                description2='Заметим, что климакс — естественный этап в жизни каждого человека. Климактерический синдром связан с патологическими расстройствами и характерен преимущественно для женщин.'
                img="cervix34"
                descriptionSize='h6'
            />
            <TextInfoScreen data={data1} />
            {/* <ThreeScreen data={data2} showInfoText={true} /> */}
            <MyContainer wrapper={false} minHeight={1}>
                <MyText variant="h6" sx={{ fontWeight: 'normal' }}>В рамках реализации Национального проекта «Здравоохранение» разработан универсальный анкетный скрининг для выявления групп риска с учетом региональной специфики.</MyText>
            </MyContainer>
            <LinkToSurveysScreen link="" />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MyButton sx={{ bgcolor: '#EB5757', mb: 2 }}
                    onClick={() => {
                        navigate(cookie.get('jwttoken') ? '/surveys' : '/login')
                    }}
                >
                    <FormattedMessage id="view_surveys_lung_cancer" />
                </MyButton>
            </div>
            <MyContainer
                wrapper={false}
                minHeight={600}
            >
                <CaruselSurvey />
            </MyContainer>
        </div>
    )
}

export default Liver