import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import {
    Avatar,
    FormControlLabel,
    Checkbox,
    Grid,
    Box,
    Typography,
    CssBaseline,
    Container,
    TextField,
    MenuItem,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import InputMask from "react-input-mask";

import {
    Input,
    Form,
    MyButton,
    MyLink,
    MyText,
    Consent,
} from "../../components";
import themeMain from "../../theme";
import { DispatchContext } from "../../store";
import API from "../../utils/api";

const theme = createTheme();

export default function Register() {
    const [open, setOpen] = useState(false);
    const dispatch = useContext(DispatchContext);
    const navigate = useNavigate();
    const { register, handleSubmit } = useForm({
        mode: "onBlur",
    });
    function Copyright(props: any) {
        return (
            <Typography
                variant="body2"
                color="text.secondary"
                align="center"
                {...props}
            >
                «Белая Роза-Саха» Медицинский диагностический центр
                {new Date().getFullYear()}.
            </Typography>
        );
    }
    const onSubmit = (data: any) => {
        API.register(data, dispatch, navigate);
    };
    return (
        <ThemeProvider theme={theme}>
            {open && <Consent open={open} setOpen={setOpen} />}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar
                        sx={{ m: 1, bgcolor: themeMain.palette.primary.main }}
                    >
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        <FormattedMessage id="registration" />
                    </Typography>
                    <Form sx={{ mt: 1 }} onSubmit={handleSubmit(onSubmit)}>
                        <Input
                            variant="outlined"
                            label={<FormattedMessage id="registration_name" />}
                            {...register("first_name")}
                            required
                        />
                        <Input
                            variant="outlined"
                            label={
                                <FormattedMessage id="registration_last_name" />
                            }
                            {...register("last_name")}
                            required
                        />
                        <Input
                            variant="outlined"
                            label={
                                <FormattedMessage id="registration_patronymic" />
                            }
                            {...register("patronymic")}
                            required
                        />
                        <InputMask
                            mask="79999999999"
                            disabled={false}
                            {...register("username")}
                            required
                        >
                            {() => (
                                <Input
                                    {...register("username")}
                                    id="phone"
                                    label={
                                        <FormattedMessage id="entry_phone" />
                                    }
                                    required
                                />
                            )}
                        </InputMask>
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: 10,
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                label=""
                            />
                            <Typography
                                variant="body1"
                                onClick={() => setOpen(true)}
                                sx={{
                                    cursor: "pointer",
                                    color: themeMain.palette.primary.main,
                                    textDecoration: "underline",
                                }}
                            >
                                <FormattedMessage id="registration_agreement" />
                            </Typography>
                        </Box>
                        <MyButton
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                width: "100%",
                                bgcolor: themeMain.palette.primary.main,
                            }}
                        >
                            <FormattedMessage id="enter" />
                        </MyButton>
                    </Form>
                    <Grid container>
                        <Grid item xs>
                            <MyLink
                                href="/login"
                                sx={{
                                    fontSize: 14,
                                    color: themeMain.palette.primary.main,
                                }}
                            >
                                <FormattedMessage id="recovery_enter_phone_number" />
                            </MyLink>
                        </Grid>
                    </Grid>
                    {/* <Copyright sx={{ mt: 2, mb: 4 }} /> */}
                </Box>
            </Container>
        </ThemeProvider>
    );
}
