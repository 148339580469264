const climatic_period_questions = [
    {
        title: '1. Наблюдете ли Вы у себя приливы или "ночной" пот?',
        name: 'champerPot',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '2. Наблюдаете ли Вы у себя быстрое или сильное сердцебиение? ',
        name: 'heartDisorder',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '3. Страдаете ли Вы бессонницей или изменениями ритма сна?',
        name: 'insomnia',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '4. Наблюдете ли Вы в последнее время чаще, чем прежде нервозность, раздражительность или депрессивное состояние?',
        name: 'nerves',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '5. Беспокоят ли Вас частые головные боли?',
        name: 'depression',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '6. Отмечаете ли Вы у себя сухость или зуд влагалища, в том числе боль или дискомфорт во время полового акта?',
        name: 'headache',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '7. Отмечаете ли Вы потерю интереса к сексу?',
        name: 'dizziness',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '8. Беспокоит ли Вас недержание мочи – теряете ли Вы непроизвольно капли?',
        name: 'weakness',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '9. Бывают ли у Вас частые падения и травмы (переломы конечностей)?',
        name: 'paresthesia',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '10. Операции по  удалению органов репродуктивной системы (матка, яичники)?',
        name: 'hairLoss',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    // {
    //     title: '11. Стала ли сухой кожа и ухудшается ли ее эластичность?',
    //     name: 'drySkin',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '12. Заметили ли Вы, что слизистая оболочка рта или глаз стала более сухой (например, появились трудности при ношении зубного протеза или в отличие от прошлого у Вас возникает раздражение во время ношения контактных линз)?',
    //     name: 'membrane',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '13.	Есть ли у Вас учащенное мочеиспускание или затруднения с удержанием мочи (недержание мочи) - теряете ли Вы непроизвольно капли?',
    //     name: 'urination',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '14. Страдаете ли Вы сухостью влагалища, которая вызывает раздражение слизистой оболочки или болезненность во время полового акта?',
    //     name: 'vaginalDryness',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '15. Есть ли у Вас боли в суставах, особенно в суставах пальцев или стоп?',
    //     name: 'jointPain',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '16.	Есть ли у Вас боли в мышцах или костях?',
    //     name: 'musclePain',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '17.	Чувствуете ли Вы себя иногда “пополневшей” (узка юбка, кольца не снимаются)?',
    //     name: 'plump',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '18.	Нет ли у Вас неприятного ощущения нагрубания молочных желез?',
    //     name: 'unpleasantBreast',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '19.	Изменился ли ритм и характер менструаций в последнее время, или есть ли у Вас жалобы перед ее началом?',
    //     name: 'natureOfMenstruation',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '20.	Заметили ли Вы, что у Вас изменилось либидо - потребность в сексуальной жизни?',
    //     name: 'libido',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '21.	Наблюдается ли у Вас другие симптомы или жалобы, которые появились лишь в последние годы?',
    //     name: 'complaints',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Иногда',
    //             value: 2
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '22.	Была ли удалена матка?',
    //     name: 'removedUterus',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // },
    // {
    //     title: '23.	Были ли удаленые также яичники?',
    //     name: 'ovariesRemoved',
    //     radios: [
    //         {
    //             label: 'Да',
    //             value: 1
    //         },
    //         {
    //             label: 'Нет',
    //             value: 3
    //         },
    //     ]
    // }
]

const endometriosis_questions = [
    {
        title: '1.	Ваш возраст 12 -17 лет, 18–44, 45 и старше?',
        name: 'age',
        radios: [
            {
                label: '12-17',
                value: 1
            },
            {
                label: '18–44',
                value: 2
            },
            {
                label: '45 и старше',
                value: 3
            },
        ]
    },
    {
        title: '2.	Есть ли у Вас болезненные менструации?',
        name: 'pelvicPain',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '3.	Есть ли у вас мажущие выделения до или после менструации?',
        name: 'painDuringIntercourse',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '4.	Тазовые боли или боли внизу живота во время менструального цикла, являющиеся причиной отсутствия на учебе или работе ?',
        name: 'spotting',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '5.  Есть ли у Вас боль при половом акте?',
        name: 'spotting1',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '6.	Замечаете ли Вы сгустки крови «как печень» во время месячных?',
        name: 'nervous',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '7.	Есть ли у Вас боль при акте дефекации или мочеиспускании, или примесь крови в моче во время менструации?',
        name: 'defecationPain',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '8.	Планируете беременность в течение 1 года, но беременность не наступает?',
        name: 'menstrualIrregularities',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '9.	Наблюдается ли у вас раздражительность, нервозность?',
        name: 'weakness',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '10.	Бывают ли слабость, быстрая утомляемость, чаще чем обычно?',
        name: 'irritability',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Иногда',
                value: 1
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
]

const infertility_questions = [
    {
        title: '1. Ваш возраст',
        name: 'age',
        radios: [
            {
                label: 'Меньше 35 лет',
                value: 1
            },
            {
                label: '35-39 лет ',
                value: 2
            },
            {
                label: '40 и старше ',
                value: 4
            },
        ]
    },
    {
        title: '2. Сколько месяцев не наступает беременность ',
        name: 'pelvicPain',
        radios: [
            {
                label: '6-11 месяцев ',
                value: 1
            },
            {
                label: '12 месяцев и более ',
                value: 2
            },
        ]
    },
    {
        title: '3. Имеются ли нарушения менструальной функции (сильная болезненность в дни менструации, нерегулярная менструация)',
        name: 'painDuringIntercourse',
        radios: [
            {
                label: 'Да',
                value: 3
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '4. Чем закончились предыдущие беременности',
        name: 'spotting',
        radios: [
            {
                label: 'Только роды ',
                value: 1
            },
            {
                label: 'Роды, аборты, выкидыши ',
                value: 2
            },
            {
                label: 'Только аборты или только выкидыши или неразвивающаяся беременность',
                value: 3
            },
        ]
    },
    {
        title: '5.  При наличии постоянного полового партнера – регулярность половой жизни',
        name: 'spotting1',
        radios: [
            {
                label: '1 -2 раза в неделю ',
                value: 1
            },
            {
                label: '2-3 раза в месяц ',
                value: 2
            },
            {
                label: 'Не чаще 1 раза в месяц ',
                value: 3
            },
        ]
    },
    {
        title: '6.	Имелись ли ранее гинекологические операции ',
        name: 'nervous',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '7.	Были ли у Вас ранее воспалительные гинекологические заболевания или заболевания, передающиеся половым путем (хламидии, гонорея, трихомониаз, генитальный микоплазмоз)?',
        name: 'defecationPain',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
    {
        title: '8.	Имеются ли у вашего полового партнера эректильные и сексуальные нарушения (снижение полового влечения, слабая эрекция), варикозное расширение вен мошонки и/или воспалительные заболевания половых органов',
        name: 'menstrualIrregularities',
        radios: [
            {
                label: 'Да',
                value: 2
            },
            {
                label: 'Нет',
                value: 0
            },
        ]
    },
]

export {
    climatic_period_questions,
    endometriosis_questions,
    infertility_questions
}   