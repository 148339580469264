import React from 'react'

import { Grid, Box } from '@mui/material'
import { styled } from '@mui/system'
import { MyContainer, MyText } from '../../../components'
import { FormattedMessage } from "react-intl";

const Companys = styled(Grid)(({ }) => ({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',

}))

const CompanyItem = styled(Box)(({ theme }) => ({
    marginLeft: 15,
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
        marginTop: 30
    },
}))


const Box1 = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
        marginTop: 0
    },
}))

const PartnersScreen = () => {
    const CompanyList = ['brand_2_min', 'brand_3_iarm', 'mcia']
    const CompanyList2 = ['white', 'policlinic1', 'aigb2', 'aigb3']
    return (
        <MyContainer wrapper={true}
            sx={{
                pt: 5,
                pb: 3
            }}
        >
            <Companys container>
                <MyText variant="h5">
                    <FormattedMessage id={'partners_project'} />
                </MyText>
                <Box1>
                    {CompanyList.map((itemCompany) => (
                        <CompanyItem key={itemCompany}>
                            <img src={`/img/Element/${itemCompany}.png`} />
                        </CompanyItem>
                    ))}
                </Box1>
                <Box1>
                    {CompanyList2.map((itemCompany) => (
                        <CompanyItem key={itemCompany}>
                            <img src={`/img/Element/${itemCompany}.png`} />
                        </CompanyItem>
                    ))}
                </Box1>
                {/* <Grid container sx={{ mt: 5 }}>

                    <Grid item lg={3} xl={3} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`/img/Element/white.png`} style={{ width: '80%' }} />
                    </Grid>
                    <Grid item lg={3} xl={3} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`/img/Element/policlinic1.png`} style={{ width: '80%' }} />
                    </Grid>
                    <Grid item lg={3} xl={3} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`/img/Element/aigb2.png`} style={{ width: '45%' }} />
                    </Grid>
                    <Grid item lg={3} xl={3} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={`/img/Element/aigb3.png`} style={{ width: '45%' }} />
                    </Grid>
                </Grid> */}
            </Companys>
        </MyContainer>
    )
}

export default PartnersScreen