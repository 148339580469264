import React from 'react'

import StartScreen from './components/StartScreen'
import ThreeScreen from './components/ThreeScreen'
import TwoScreen from './components/TwoScreen'

const Contacts = () => {
    return (
        <div style={{ marginBottom: 80 }}>
            <StartScreen />
            <TwoScreen />
            <ThreeScreen />
        </div>
    )
}

export default Contacts