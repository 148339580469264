import { LOCALES } from './locales'

export const messages = {
    [LOCALES.RUSSIAN]: {
        question_answers_menu: 'Вопрос/ответ',
        about_menu: 'О проекте',
        index_menu: 'Главная',
        contacts_menu: 'Контакты',
        profile_menu: 'Личный кабинет',
        index_page_title: 'ЖЕНСКОЕ ЗДОРОВЬЕ',
        partners_project: 'Партнеры проекта',
        partners_names: 'ЯРОД, МИНЗДРАВ, ЯРМИАЦ, ЭСФЕРАЛЬ, АОРСЯ',
        profile_main_page: 'Личный кабинет.',
        profile_description_main_page: 'Результаты будут храниться в личном кабинете. Плюсы:',
        profile_plus_access_main_page: ' Доступ 24/7',
        profile_plus_integration_main_page: ' Интеграция с медицинскими системами',
        profile_plus_appointment_time_main_page: 'Выбор удобного времени приема',
        profile_button_main_page: 'Перейти в личный кабинет',
        registration_main_page: 'Регистрация',
        registration_description_main_page: ' Быстрая регистрация обеспечивается путем получения смс сообщений, также удобным интерфейсом с простыми формами для регистрации. ',
        questioning_main_page: 'Анкеты',
        questioning_description_main_page: ' Эффективный процесс анкетирования обеспечивается благодаря проработанным анкетам, которые созданы лучшими специалистами. ',
        reception_main_page: 'Прием',
        reception_description_main_page: ' Наша система записи позволит вам быстро и удобно выбрать время приема, чтобы не стоять в очередях. ',
        aisen_name_slider: 'Айсен Сергеевич Николаев',
        aisen_position_slider: ' Глава Республики Саха (Якутия)',
        aisen_description_slider: ' «Для достижения целевых показателей реализации национальных проектов нам требуется перейти от шаблонных решений на своевременное и эффективное управление»',
        lena_name_slider: 'Лена Николаевна Афанасьева',
        lena_position_slider: ' Министр Здравоохранения Республики Саха (Якутия)',
        // lena_description_slider: ' «Уверена, что проект будет полезным для жителей Якутии и будет способствовать нашей победе в нелегкой борьбе с онкологическими заболеваниями»',
        lena_description_slider: '«Проект разработан в целях раннего выявления онкологических заболеваний.  Уверена, что применение IT технологий в профилактической медицине внесет свой существенный вклад»',
        tatiana_name_slider: 'Татьяна Ивановна Николаева.',
        tatiana_position_slider: ' Главный врач государственного бюджетного учреждения Республики Саха (Якутия)',
        tatiana_description_slider: ' «Уверена, что проект будет полезным для жителей Якутии и будет способствовать нашей победе в нелегкой борьбе с онкологическими заболеваниями»',
        feodosia_name_slider: 'Феодосия Гаврильевна Иванова.',
        feodosia_position_slider: ' Главный внештатный онколог Министерства здравоохранения Республики Саха (Якутия)',
        feodosia_description_slider: ' «Практическая мудрость, направленная на конкретные обстоятельства, становится искусством, которое ведет к благополучию и доброму делу»',
        surveys_main_page: 'Анкеты',
        screening_lungs: 'Скрининг рака легкого',
        screening_climatic: 'Скрининг рака печени',
        screening_colon: 'Скрининг рака ободочной кишки',
        screening_cervix: 'Скрининг рака шейки матки',
        screening_prostate: 'Скрининг рака простаты',
        screening_mammary_gland: 'Скрининг рака молочной железы',
        our_mission_about_tag_page: 'Наша миссия.',
        our_mission_about_page: 'Скрининг и ранняя диагностика рака в Якутии.',
        population_about_page: '964330 население РС (Я)',
        registered_about_page: '12881 человек состоит на онкологическом учете в РС (Я)',
        mortality_about_page: '1250 человек в среднем умирает на территории РС (Я) от рака ',
        population_50_about_page: '24% населения РС (Я) от 50 лет и старше',
        pilot_project_about_page: 'В рамках реализации национального проекта «Здравоохранение» Якутским республиканским онкологическим диспансером разработан пилотный проект «ОНКОПОИСКСАХА.РФ»',
        about_about_page: 'О Проекте',
        about_1_about_page: 'Скрининговый онлайн проект «ОНКОПОИСК» ориентирован на выявление предраковых состояний у практически здоровых людей или рака на ранних стадиях. Цель проекта – выявить заболевание как можно раньше, поставить без промедления диагноз, начать своевременное лечение. Скрининговый онлайн проект «ОНКОПОИСК» адаптирован к региональным особенностям Якутии и используется для формирования групп повышенного риска и эффективного отбора лиц, нуждающихся в первоочередном целенаправленном обследовании. Нами выбраны 6 основных видов рака, которые являются ведущими причинами смерти населения региона. Именно при этих опухолях имеется доказанная эффективность применения скрининговых технологий.',
        about_2_about_page: 'При выявлении, у анкетируемого высокой группы риска пациенту предлагается пройти высокоточное обследование в условиях Якутского республиканского онкологического диспансера в рамках ОМС с возможностью выбора даты и времени посещения клиники. В программу обследования входят основные лабораторные и инструментальные исследования, УЗИ-диагностика, низкодозная компьютерная томография органов грудной клетки и средостения в зависимости от группы риска. При этом от Вас необходимо только лишь согласие.',
        about_3_about_page: 'По результатам анкетирования и дополнительного обследования предоставляется возможность пройти более глубокое обследование.',
        about_4_about_page: 'Скрининг рака легкого - при наличии группы высокого риска Вам предлагается пройти низкодозную компьютерную томографию (НДКТ) органов грудной клетки и средостения на современном 32-срезном компьютерном томографе BodyTom. Преимуществом НДКТ является высокая точность распознавания очаговых образований при низкой лучевой нагрузке, получаемой при обычном ФЛГ. Заодно Вам предлагается сдать кровь на онкомаркеры.',
        about_5_about_page: 'Скрининг рака молочной железы - при наличии группы высокого риска Вам предлагается сдать кровь на онкомаркеры и пройти комплексное обследование молочной железы с применением ультразвукового исследования и маммографии.',
        about_6_about_page: 'Скрининг колоректального рака - при наличии группы высокого риска Вам предлагается сдать кровь на онкомаркеры и пройти экспресс-тест на скрытую кровь в кале в домашних условиях. Тест в виде полоски, простой и понятный в использовании.',
        about_7_about_page: 'Скрининг рака печени - при наличии группы высокого риска Вам предлагается сдать кровь на онкомаркеры и пройти прицельное УЗИ печени, лабораторное обследование крови – биохимические показатели, наличие или отсутствие носительства вирусного гепатита В и С.',
        about_8_about_page: 'Скрининг рака шейки матки - при наличии группы высокого риска Вам предлагается сдать кровь на онкомаркеры и пройти осмотр врача онкогинеколога, лабораторное обследование мазков из шейки матки для ПАП-теста и жидкостной биопсии, определение носительства вируса папилломы человека (ВПЧ).',
        about_9_about_page: 'Скрининг рака предстательной железы - при наличии группы высокого риска Вам предлагается сдать кровь на онкомаркеры и пройти УЗИ предстательной железы и расширенное исследование простат специфического антигена (ПСА).',
        faq_description: 'Здесь мы решили написать ответы на популярные вопросы, связанные с работой сервиса и темой скрининга.',
        faq_screening_canser: 'Какие гормоны назначают для лечения климактерического синдрома?',
        faq_early_diagnosis: 'Есть ли противопоказания для заместительной гормональной терапии?',
        faq_how_does: 'К какому доктору обращаться с ранними симптомами климактерического синдрома?',
        faq_not_working_surveys: 'Может ли гинеколог — эндокринолог помочь в лечении остеопороза?',
        faq_i_have_cancer: 'Как понять, связаны ли признаки определённых заболеваний с климактерическим синдромом?',
        faq_why_pilot_project: 'Почему проект пилотный?',
        faq_answer_screening_canser: 'Препараты в период менопаузы подбираются врачом индивидуально. Это комплексные средства, содержащие прогестерон и эстрогены.',
        faq_answer_early_diagnosis: 'Да, конечно, к ним относятся: Онкологические заболевания, особенно женской половой системы и рак молочной железы; Тромбозы и тромбоэмболия; Гепатиты группы B и C; Индивидуальная непереносимость компонентов препарата, вызывающая аллергические реакции; В некоторых случая к противопоказаниям могут отнести миому и эндометриоз.',
        faq_answer_how_does: 'В этом случае первоочерёдно вам следует обратиться к гинекологу-эндокринологу, доктор, в свою очередь, может дополнительно направить вас к кардиологу, психологу или неврологу.',
        faq_answer_not_working_surveys: 'Да, именно гинеколог-эндокринолог может впервые рассказать вам о таком заболевании, как остеопороз. Но, разумеется, речь идёт о постменопаузальном остеопорозе. В этом случае доктор чаще всего посоветует вам получить дополнительную консультацию у ревматолога или ортопеда.',
        faq_answer_i_have_cancer: ' Важно понимать, что проводить такие связи можно лишь по достижении определённого возраста (42-45 лет) и имея отягчённую наследственность. Если у вас существуют проблемы со здоровьем необходимо лечить их в любом возрасте, чтобы с наступлением менопаузы они не усугублялись.',
        faq_answer_why_pilot_project: 'Почему проект пилотный? Проект является совершенно новым проектом для Якутии и требует тестирования для выявления положительных и отрицательных сторон. Для тиражирования данного проекта на первом этапе планируется обследовать определенное количество людей, чтобы правильно организовать и улучшить маршрутизацию пациентов. В последующем в проект будут подключены поликлиники города Якутска и районов.',
        faq_contact_us: 'СВЯЖИТЕСЬ С НАМИ',
        faq_questions_remain: 'Остались вопросы?',
        faq_write_us: 'Напишите нам. Мы ответим в течение 24 часов.',
        faq_call: 'ПОЗВОНИТЕ',
        faq_during_day: '8-18 в течение дня',
        faq_name: 'Ваше имя',
        faq_email: 'Ваша почта',
        faq_message: 'Ваше сообщение. ',
        faq_send_message: 'Отправить сообщение. ',
        contacts_reference_phones: 'Справочные телефоны. ',
        contacts_reference_phones_description: 'Телефоны медицинских учреждений, горячей линии, администрации, полезная информация',
        contacts_yarod_name: 'ГБУ РС (Я) Якутский республиканский онкологический диспансер, г. Якутск',
        contacts_chief_physician_label: 'Главный врач:',
        contacts_chief_physician: 'Николаева Татьяна Ивановна',
        contacts_address_label: 'Адрес:',
        contacts_address: 'г. Якутск, ул. Феликса Кона 1/3',
        contacts_email_label: 'e-mail:',
        contacts_email: 'yarod@gov14.ru',
        contacts_reception_label: 'Приемная:',
        contacts_reception: '(4112) 507-642',
        contacts_fax_label: 'Факс:',
        contacts_fax: '(4112) 43-21-63',
        contacts_site_label: 'Cайт:',
        contacts_site: 'onkosakha.ru',
        contacts_hot_line_label: 'Круглосуточная "горячая линия":',
        contacts_hot_line: ' 8 (914) 270-53-79',
        contacts_russian_line_label: 'Телефон Всероссийской горячей линии помощи онкологическим больным:',
        contacts_russian_line: '8 (800) 100-01-91',
        entry: 'Вход',
        entry_phone: 'Номер телефона',
        entry_password: 'Пароль',
        entry_remember_device: 'Запомнить устройство',
        entry_forgot_password: 'Забыли пароль?',
        entry_no_account: 'У вас нет аккаунта? Зарегистрируйтесь',
        registration: 'Регистрация',
        registration_name: 'Имя*',
        registration_last_name: 'Фамилия*',
        registration_patronymic: 'Отчество*',
        registration_missing: '/Отсутствует',
        registration_city: 'Город',
        registration_phone: 'Номер телефона*',
        registration_agreement: 'Согласие на обработку персональных данных.',
        recovery_password: 'Восстановление пароля',
        recovery_enter_phone_number: 'Введите номер телефона, указанный при регистрации.',
        go_to: 'перейти',
        about_us: 'О нас',
        about_title: 'Ваше здоровье - для нас важно!',
        about_description: 'Мы заботимся о вас, поэтому создали максимально простую и эффективную систему скрининга.',

        lung_cancer: 'РАК ЛЕГКОГО',
        description_lung_cancer: 'РАК ЛЕГКОГО - один из самых распространенных и агрессивных видов рака. Более 65% выявляется на поздних стадиях (III,IV), когда опухолевый процесс распространяется на соседние органы или появляются метастазы. Отмечается и высокий уровень летальности.',
        development_lung_cancer: 'Развитие рака легкого зависит от многих факторов. Основной причиной развития рака легкого по данным ВОЗ считается воспалительные заболевания (часто рецидивирующие хронические бронхиты, ХОБЛ, бронхоэктатическая болезнь и др.).',
        role_smoking_lung_cancer: 'Роль курения, безусловно, является самым распространенным фактором риска развития рака легкого. По данным международного агентства по изучению рака, 70-90% рака связано с курением, и курение повышает риск развития рака в 10 раз.',
        reveald_lung_cancer: 'В Республике Саха (Якутия) в 2019 году было выявлено 401 случаев рака легкого. При этом на I-II стадии процесса было диагностировано 24,4% заболеваний, на III стадии - 35,8% и на IV стадии - 39,4%. В течение первого года с момента установления диагноза умерло 55,9% пациентов.',
        symptoms_lung_cancer: 'Симптомы',
        asymptomatic_development_lung_cancer: 'В большинстве случаев рак легкого развивается бессимптомно, поэтому на ранних стадиях диагностируется благодаря ежегодному медицинскому осмотру или при обследовании пациента по другому поводу.',
        manifestation_symptoms_lung_cancer: 'В некоторых случаях все же болезнь проявляет свои симптомы, но эти симптомы характерны и для многих заболеваний.',
        main_symptoms_lung_cancer: 'Основные симптомы:',
        dyspnea_lung_cancer: '- одышка и затруднение вдоха;',
        dry_cough_lung_cancer: '- хронический постоянный сухой кашель;',
        hoarseness_lung_cancer: '- осиплость голоса;',
        constant_weakness_lung_cancer: '- постоянная слабость;',
        weight_reduction_lung_cancer: '- неконтролируемое снижение массы тела;',
        specific_symptom_lung_canser: 'При больших стадиях рака легких симптомы уже более специфичные:',
        loss_voice_lung_cancer: '- исчезновение голоса или осиплый голос длительное время;',
        debilitating_cough_lung_cancer: '- изнуряющий кашель с трудноотходимой мокротой (иногда с примесью крови и гноя);',
        chest_pain_lung_cancer: '- боль в грудной клетке;',
        lymph_nodes_lung_cancer: '- появление плотных надключичных лимфатических узлов.',
        take_test_lung_cancer: 'Для определения группы риска просим зарегистрироваться на портале и пройти универсальный анкетный тест',
        view_surveys_lung_cancer: 'Посмотреть доступные анкеты',
        diagnostic_methods_lung_cancer: 'МЕТОДЫ ДИАГНОСТИКИ',
        lung_ldct_lung_cancer: 'С профилактической целью для ранней диагностики рака легкого применяется стандартная флюорография органов грудной клетки. Также на сегодняшний день выявление возможно с помощью низкодозной компьютерной томографии (НДКТ). НДКТ — метод скрининга легких и органов средостения, позволяющий выявить даже незначительные изменения. Проведенный скрининг с помощью НДКТ достоверно снижает смертность от рака легкого на 44%.',
        universal_screening_lung_cancer: 'В рамках реализации Национального проекта «Здравоохранение» разработан универсальный анкетный скрининг для выявления групп риска с учетом региональной специфики.',
        indications_ldct_lung_cancer: 'ПОКАЗАНИЯ ДЛЯ ПРОВЕДЕНИЯ НДКТ:',
        procedures_lung_cancer: 'Проведение процедуры необходимо тем пациентам, у которых ожидаемая польза от исследования должна быть больше, чем потенциальный вред.',
        smokers_lung_cancer: 'Люди, не имеющие симптомы заболевания и имеющие малые симптомы заболевания бронхолегочной системы старше 40 лет, а также курильщики, чей стаж курения больше 20 лет (выкуривающие 1 пачку в течение 20 лет или по 2 пачки в течение 10 лет).',
        contraindications_ldct_lung_cancer: 'ПРОТИВОПОКАЗАНИЯ ДЛЯ ПРОВЕДЕНИЯ НДКТ:',
        age_lung_cancer: '- Возраст до 40 лет',
        covid_lung_cancer: '- Перенесенное заболевание (пневмония, ОРВИ, COVID-19) менее 1 месяца',
        malignant_neoplasms_lung_cancer: '- Пациенты, имеющие в анамнезе злокачественные новообразования',
        year_ct_lung_cancer: '- Пациенты, у которых прошло меньше 1 года с момента проведения КТ',
        fever_lung_cancer: '- Пациенты с повышенной температурой 37,5*С',
        elderly_lung_cancer: '- Пожилые (ослабленные) пациенты',
        take_test_2_lung_cancer: 'Для определения группы риска просим зарегистрироваться на портале и пройти универсальный анкетный тест',
        view_surveys_2_lung_cancer: 'Посмотреть доступные анкеты',
        climatic_cancer: 'КЛИМАКТЕРИЧЕСКИЙ СИНДРОМ',
        description_climatic_cancer: 'МЕНОПАУЗА - последняя менструация (ПМ). КЛИМАКТЕРИЧЕСКИЙ СИНДРОМ - симптомокомплекс с приливами, гипергидрозом, сердцебиением, нарушением сна, различными психическими, поведенческими и эмоционально-аффективными расстройствами при дефиците эстрогенов.',
        devlopment_climatic_cancer: 'Период менопаузы характеризуется постепенным снижением, а затем и “выключением” функции яичников с полным исчезновением фолликулов.',
        reveald_climatic_cancer: 'Вазомоторные симптомы коррелируют с:',
        symptoms_climatic_cancer: 'Симптомы',
        diagnostics_methods_climatic_cancer: 'МЕТОДЫ ДИАГНОСТИКИ',
        signs_hepatitis_climatic_cancer: 'Заболевание развивается быстро, часто обнаруживается на запущенных стадиях. Рак печени имеет одинаковые признаки, которые свойственны и другим неонкологическим заболеваниям (обострение хронического гепатита, желчнокаменная болезнь и другие). Можно выделить несколько характерных симптомов рака печени:',
        bloating_climatic_cancer: '- вздутие живота, дискомфорт в брюшной полости;',
        jaundice_climatic_cancer: '- желтуха (желтая окраска кожи и слизистых вследствие избыточного накопления желчи в крови);',
        colorless_feces_climatic_cancer: '- темный цвет мочи и бесцветный кал;',
        constant_fatigue_climatic_cancer: ' - постоянное недомогание, усталость, слабость;',
        heaviness_climatic_cancer: ' - тяжесть в правом подреберье;',
        easy_bruising_climatic_cancer: '- легкие кровоподтеки или кровотечения;',
        loss_appetite_climatic_cancer: '- потеря аппетита или ощущение полноты после еды;',
        weight_loss_climatic_cancer: '  - потеря веса по неизвестной причине.',
        specific_symptom_climatic_canser: 'При больших стадиях рака печени симптомы уже более специфичные:',
        take_test_climatic_cancer: 'Для определения группы риска просим зарегистрироваться на портале и пройти универсальный анкетный тест',
        view_syrveys_climatic_cancer: 'МЕТОДЫ ДИАГНОСТИКИ',
        early_diagnosis_climatic_cancer: 'Как и в случае любого онкологического заболевания, лечение рака печени более эффективно на ранних стадиях. Поэтому ранняя диагностика - не менее важная задача, чем комплексная терапия.',
        standart_procedure_climatic_cancer: 'На сегодняшний день стандартной процедурой скрининга у групп высокого риска является:',
        ultrasound_climatic_cancer: '1) ультразвуковое исследование (УЗИ) печени',
        tumor_marker_climatic_cancer: '"2) определение онкомаркера (АФП)."',
        indications_screening_climatic_cancer: 'ПОКАЗАНИЯ ДЛЯ ПРОВЕДЕНИЯ СКРИНИНГА:',
        loss_voice_climatic_cancer: 'исчезновение голоса или осиплый голос длительное время;',
        debilitating_cough_climatic_cancer: 'изнуряющий кашель с трудноотходимой мокротой (иногда с примесью крови и гноя);',
        chest_pain_climatic_cancer: 'боль в грудной клетке;',
        lymph_nodes_climatic_cancer: 'появление плотных надключичных лимфатических узлов.',
        take_test_2_climatic_cancer: 'Для определения группы риска просим зарегистрироваться на портале и пройти универсальный анкетный тест',
        view_surveys_climatic_cancer: 'Посмотреть доступные анкеты',
        diagnostic_methods: 'МЕТОДЫ ДИАГНОСТИКИ',
        cervix_cancer: 'РАК ШЕЙКИ МАТКИ',
        cervix_cancer_description: 'РАК ШЕЙКИ МАТКИ - это злокачественная опухоль, которая развивается из слизистой оболочки шейки матки. РШМ является наиболее часто встречающейся злокачественной опухолью женской половой системы и стабильно занимает 5-е место в структуре онкологической заболеваемости женщин.',
        cervix_data1_text1: 'Доказано, что основной причиной развития РШМ является вирус папилломы человека (ВПЧ) онкогенных типов.',
        cervix_data1_text2: 'ВПЧ - это общее название группы вирусов, чрезвычайно широко распространенных во всем мире. Известно более 100 типов ВПЧ, из которых 14 являются онкогенными. ВПЧ передается, главным образом, половым путем, и большинство людей заражаются ВПЧ вскоре после начала половой жизни. В 70% случаев причиной РШМ оказываются ВПЧ двух типов (16 и 18).',
        cervix_data1_title_column1: 'Факторы риска:',
        cervix_data1_text1_column1: 'раннее начало половой жизни;',
        cervix_data1_text2_column1: 'частая смена половых партнеров;',
        cervix_data1_text3_column1: 'отказ от барьерной контрацепции (презервативов);',
        cervix_data1_text4_column1: 'курение;',
        cervix_data1_text5_column1: 'прием иммуносупрессивных препаратов;',
        cervix_data1_text6_column1: 'травмы шейки матки в родах;',
        cervix_data1_text7_column1: 'наличие хронических воспалительных заболеваний как эрозия, лейкоплакия и другие;',
        cervix_data1_text8_column1: 'инфекции, передаваемые половым путем;',
        cervix_symptoms: 'Симптомы',
        cervix_data1_text3: 'К наиболее часто встречающимся симптомам РШМ относятся обильные водянистые бели и «контактные» кровянистые выделения из половых путей.',
        cervix_data1_text4: '????',
        cervix_data1_text5: 'При значительном распространении опухоли появляются боли, нарушения мочеиспускания и затруднения при акте дефекации.',
        cervix_data1_text6: 'Периодических или постоянных. При значительном распространении опухоли появляются боли, нарушения мочеиспускания и затруднения при акте дефекации.',
        cervix_data1_text7: 'Для определения группы риска просим зарегистрироваться на портале и пройти универсальный анкетный тест',
        cervix_data1_title_column2: 'Существуют три различных типа скрининговых тестов, рекомендуемых Всемирной организацией здравоохранения в настоящее время:',
        cervix_data1_text1_column2: 'тесты на ВПЧ, относящиеся к группе высокого риска;',
        cervix_data1_text2_column2: 'кольпоскопия;',
        cervix_data1_text3_column2: 'традиционный ПАП-тест и метод жидкостной цитологии (ЖЦ).',
        cervix_data3_text1: 'Скрининг рака шейки матки заключается в регулярном исследовании цитологических мазков (ПАП-тест), полученных с шейки матки, а также в проведении ВПЧ-тестирования и жидкостной цитологии. При подозрении на предопухолевое или опухолевое заболевание выполняется кольпоскопия с прицельной биопсией с подозрительных участков. Данная методика позволяет не только выявить рак шейки матки на начальных стадиях, но и предупредить его развитие.',
        cervix_data3_text2: 'Регулярный скрининг РШМ позволяет снизить заболеваемость раком шейки матки на 80% и смертность от этого заболевания на 72%',
        prostate_cancer: 'РАК ПРЕДСТАТЕЛЬНОЙ ЖЕЛЕЗЫ',
        prostate_description: 'РАК ПРЕДСТАТЕЛЬНОЙ ЖЕЛЕЗЫ - форма рака, развивающегося в предстательной железе (простате), которая принадлежит мужской репродуктивной системе.',
        prostate_data1_text1: 'В начальной стадии в течение длительного времени заболевание может протекать без каких-либо заметных симптомов. Поэтому после 45 лет мужчинам нужно обязательно проходить медицинский осмотр и регулярно сдавать анализы. Чем раньше будет обнаружена болезнь, тем больше шансов на успешное лечение. Чаще рак простаты диагностируют у мужчин в возрасте от 55 и старше. Каждый год в мире регистрируют приблизительно 6,5 тысяч новых случаев',
        prostate_data1_text2: 'В России заболеваемость раком простаты занимает 7-8 место.',
        prostate_data1_text3: 'В Республике Саха (Якутия) в 2019 году было выявлено 114 случаев рака предстательной железы. При этом на I-II стадии процесса было диагностировано 54% заболеваний, на III стадии – 8,8 % и на IV стадии – 37,2%. В течение первого года с момента установления диагноза умерло 6,7% пациентов.',
        prostate_symptoms: 'Симптомы',
        prostate_data1_title_column1: 'Симптомов, характерных только для рака предстательной железы, не существует.Возможны следующие симптомы:',
        prostate_data1_text1_column1: 'частые позывы к мочеиспусканию, ощущение неполного опорожнения мочевого пузыря, спастические или болевые ощущения в промежности;',
        prostate_data1_text2_column1: 'в запущенных случаях рака – затруднение при мочеиспускании, наличие прерывистой или тонкой струи мочи, задержка мочи, увеличение времени мочеиспускания, необходимость напрягать мышцы брюшного пресса для полного опорожнения мочевого пузыря',
        prostate_data1_text3_column1: 'Для определения группы риска просим зарегистрироваться на портале и пройти универсальный анкетный тест',
        prostate_data1_title_column2: 'К основным методам диагностики рака предстательной железы относятся:',
        prostate_data1_text1_column2: 'Исследование крови - скрининг на сывороточный уровень простат-специфического антигена (ПСА). Обычно скрининг проводится у мужчин 50 лет, но иногда скрининг начинают раньше у мужчин с высоким риском развития заболевания (например, с семейным анамнезом рака простаты',
        prostate_data1_text2_column2: 'Ректальное обследование простаты;',
        prostate_data1_text3_column2: 'Ультразвуковое исследование простаты;',
        breast_cancer: 'РАК МОЛОЧНОЙ ЖЕЛЕЗЫ',
        breast_description: 'РАК МОЛОЧНОЙ ЖЕЛЕЗЫ - развивается из железистой ткани молочной железы. У 3-10% больных раком молочной железы развитие заболевания связано с наличием мутаций в генах (BRCA1, BRCA2, CHEK, NBS1, tP53). У остальных пациентов РМЖ имеет случайный характер.',
        breast_data1_text1: 'Рак молочной железы является ведущей онкологической патологией у женского населения России, составляя 21,1% от всех злокачественных новообразований у женщин. Средний возраст больных РМЖ с впервые в жизни установленным диагнозом злокачественного новообразования в РФ в 2017 г. – 61,4 года. Каждая 8-я женщина на протяжении жизни рискует заболеть раком молочной железы. У мужчин также может возникнуть рак молочной железы, в структуре заболеваемости онкологическими заболеваниями у мужчин РМЖ составляет 0,3 %.',
        breast_data1_text2: 'В Республике Саха (Якутия) в 2019 году было выявлено 275 случаев рака молочной железы. При этом на I-II стадии процесса было диагностировано 67,3% заболеваний, на III стадии – 22,8 % и на IV стадии – 9,9%. В течение первого года с момента установления диагноза умерло 4,5% пациентов. ',
        breast_data1_title_column1: 'Факторы риска:',
        breast_data1_text1_column1: 'раннее начало менструации;',
        breast_data1_text2_column1: 'поздняя менопауза;',
        breast_data1_text3_column1: 'отсутствие родов;',
        breast_data1_text4_column1: 'наличие абортов;',
        breast_data1_text5_column1: 'курение;',
        breast_data1_text6_column1: 'алкоголь;',
        breast_data1_text7_column1: 'ожирение;',
        breast_data1_text8_column1: 'Симптомы',
        breast_data1_text9_column1: 'наличие сахарного диабета;',
        breast_symptoms: 'Симптомы',
        breast_data1_title_column2: 'К клиническим симптомам ранних форм РМЖ относятся следующие:',
        breast_data1_text1_column2: 'наличие опухолевого узла в ткани молочной железы;',
        breast_data1_text2_column2: 'плотная консистенция опухоли (опухоль может быть деревянистой или даже каменистой плотности);',
        breast_data1_text3_column2: 'ограниченная подвижность либо полное её отсутствие, как правило, безболезненность опухоли, наличие втяжение кожи над опухолью;',
        breast_data1_text4_column2: 'наличие лимфатического узла в подмышечной области на стороне опухоли возможны кровянистые выделения из соска.',
        breast_data1_title_column3: 'К симптомам, характерным для более распространенных форм опухоли, относятся:',
        breast_data1_text1_column3: 'заметная на глаз деформация кожи молочной железы над определяемой опухолью (особенно при осмотре с поднятыми вверх руками);',
        breast_data1_text2_column3: 'прорастание кожи опухолью и/или изъязвление опухоли;',
        breast_data1_text3_column3: 'ограниченная подвижность либо полное её отсутствие, как правило, безболезненность опухоли, наличие втяжение кожи над опухолью;',
        breast_data1_text4_column3: 'наличие лимфатического узла в подмышечной области на стороне опухоли возможны кровянистые выделения из соска;',
        breast_data2_text1_column1: 'Инструментальные методы исследования применяются как с профилактической, так и с уточняющей целью. Прежде всего – маммография, но показана она женщинам старше 35 лет. До 35 лет женщина должна проходить ультразвуковое исследование.',
        breast_data2_text2_column1: 'Один из основных путей снижения смертности от РМЖ — это его ранняя диагностика. Внедрение маммографического скрининга позволяет снизить смертность от РМЖ в среднем на 30% за счет выявления заболевания на ранних стадиях. В то же время его эффективность зависит от количества обследованных женщин. Скрининг считается эффективным, если им охвачено 70% женского населения. Выполнение такого количества маммографических исследований в течение года, даже в крупных городах, при наличии ограниченного количества маммографов и специалистов, затруднительно. Поэтому оптимальной системой скрининга является работа с группами повышенного риска на постоянной основе.',
        gut_cancer: 'РАК ТОЛСТОГО КИШЕЧНИКА И ПРЯМОЙ КИШКИ',
        gut_description: 'РАК ТОЛСТОГО КИШЕЧНИКА И ПРЯМОЙ КИШКИ - распространенное злокачественное новообразование толстого кишечника и прямой кишки. Образуется при нарушении клеточного деления слизистой оболочки кишечника, при котором формируются полипы. Через определённое время полип превращается в аденому, далее в большинство случаев происходит трансформация в раковые клетки.',
        gut_data1_text1: 'У 3–5 % больных развитие колоректального связано с наличием известных наследственных синдромов, наиболее распространенные из которых – синдром Линча, семейный аденоматоз толстой кишки и MutYH-ассоциированный полипоз. У остальных пациентов колоректальный рак носит случайный характер.',
        gut_data1_text2: 'По данным Всемирной организации здравоохранения, колоректальный рак занимает 3 место в структуре онкологической заболеваемости. Каждый год диагностируется более 1 млн. случаев рака толстой кишки, 600 тысяч человек ежегодно умирает от данной патологии. Позднее обращение в специализированные медицинские учреждения и несвоевременная диагностика приводит к тому, что у 30% пациентов диагностируют IV стадию рака. При этом основным фактором, определяющим благоприятный прогноз при раке толстой кишки, является ранняя диагностика.',
        gut_data1_title_column1: 'Факторы риска:',
        gut_data1_text1_column1: 'преобладание в рационе питания красного мяса, которое незаменимо для поддержания жизни людей в условиях Крайнего Севера Якутии;',
        gut_data1_text2_column1: 'хронические воспалительные заболевания толстой кишки (например, неспецифический язвенный колит, болезнь Крона);',
        gut_data1_text3_column1: 'курение;',
        gut_data1_text4_column1: 'чрезмерное употребление алкоголя;',
        gut_data1_text5_column1: 'ожирение или повышенный индекс массы тела;',
        gut_data1_text6_column1: 'низкая физическая активность',
        gut_symptoms: 'Симптомы',
        gut_data1_text1_column2: 'Как и многие другие онкологические заболевания, колоректальный рак может длительное время протекать бессимптомно. Поэтому большое значение имеет профилактическое обследование. При локализации опухоли в левых отделах толстой кишки возможны выделения крови с калом. Не только многие пациенты, но и врачи не придают особого значения этому важному симптому, трактуя его как проявление распространённого заболевания – геморроя.',
        gut_data1_text2_column2: 'Симптомом заболевания может быть внезапное выявление анемии. Снижение гемоглобина редко происходит без какой-то определённой причины и должно быть поводом для проведения обследования. Нередко первым проявлением рака может быть кишечная непроходимость.',
        gut_data1_text3_column2: 'Учитывая, что рак толстой кишки проявляется и начинает беспокоить только в поздних стадиях, жизненно важно проводить регулярные обследования, даже если ничего не беспокоит.',
        gut_data1_text4_column2: 'В рамках реализации Национального проекта «Здравоохранение» разработан универсальный анкетный скрининг для выявления групп риска с учетом региональной специфики.',
        gut_data2_text1: 'Самым распространенным и безопасным методом скрининга колоректального рака является исследование в кале скрытой крови. Эффективным считается экспресс метод иммунохимического исследования кала на скрытую кровь (на наличие человеческого гемоглобина). Если тест показывает наличие крови в кале, то тогда следует выполнить фиброколоноскопию, чтобы осмотреть всю толстую и прямую кишку на наличие полипов, аденом и рака.',

        name_lung_survey: 'Анкета для выявления заболеваний органов дыхания и грудной клетки (низкодозная компьютерная томография)',
        how_smoke_lung_survey: 'Сколько вы курите?',
        answer1_how_cigarettes_lung_survey: 'Сколько сигарет в день?',
        answer1_how_years_lung_survey: 'Сколько лет',
        answer1_no_lung_survey: 'Не курю',
        had_CT_lung_survey: 'Проходили ли вы в последний год компьютерную томографию',
        answer2_yes_lung_survey: 'Да',
        answer2_no_lung_survey: 'Нет',
        have_hemoptysis_lung_survey: 'У Вас бывает кровохарканье?',
        answer3_yes_lung_survey: 'Да',
        answer3_no_lung_survey: 'Нет',
        had_weight_loss_lung_survey: 'Имеется ли у Вас необъясняемое снижение массы тела?',
        answer4_yes_lung_survey: 'Да',
        answer4_no_lung_survey: 'Нет',
        have_malignancy_lung_survey: 'Имеются ли у Вас подтверждённые злокачественные новообразования',
        answer5_yes_lung_survey: 'Да',
        answer5_no_lung_survey: 'Нет',
        had_temperature_lung_survey: 'Последние 10 дней были ли у Вас эпизоды повышения температуры тела выше 37,5*С',
        answer6_yes_lung_survey: 'Да',
        answer6_no_lung_survey: 'Нет',
        consent_lung_survey: 'Согласие на обработку персональных данных',
        save_lung_survey: 'СОХРАНИТЬ',

        name_climatic_survey: '"Анкета для выявления заболевания печени (контроль АФП, УЗИ печени)"',
        had_hepatitis_climatic_survey: 'Вам, когда ни будь устанавливали диагноз хронический вирусный гепатит В и С ?',
        answer1_yes_climatic_survey: 'Да',
        answer1_no_climatic_survey: 'Нет',
        carrier_hbsag_climatic_survey: 'Являетесь ли Вы носителем HBsAg?',
        answer2_yes_climatic_survey: 'Да',
        answer2_no_climatic_survey: 'Нет',
        have_cirrhosis_climatic_survey: 'Вам, когда ни будь устанавливали диагноз цирроз печени?',
        answer3_yes_climatic_survey: 'Да',
        answer3_no_climatic_survey: 'Нет',
        skin_itching_climatic_survey: 'Отмечаете ли Вы безпричинный зуд кожи, особенно в ночное время?',
        answer4_yes_climatic_survey: 'Да',
        answer4_no_climatic_survey: 'Нет',
        pain_right_hypochondrium_climatic_survey: 'Отмечаете ли Вы боли в правом подреберье?',
        answer5_yes_climatic_survey: 'Да',
        answer5_no_climatic_survey: 'Нет',
        blood_vomit_climatic_survey: 'У Вас бывает рвота с примесью крови?',
        answer6_yes_climatic_survey: 'Да',
        answer6_no_climatic_survey: 'Нет',
        have_weight_loss_climatic_survey: 'Имеется ли у Вас необъясняемое снижение массы тела?',
        answer7_yes_climatic_survey: 'Да',
        answer7_no_climatic_survey: 'Нет',
        parents_cancer_climatic_survey: 'Когда-либо у родителей был ли установлен диагноз рак печени?',
        answer8_yes_climatic_survey: 'Да',
        answer8_no_climatic_survey: 'Нет',
        had_temperature_climatic_survey: 'Последние 10 дней были ли у Вас эпизоды повышения температуры тела выше 37,5*С',
        answer9_yes_climatic_survey: 'Да',
        answer9_no_climatic_survey: 'Нет',
        consent_climatic_survey: 'Согласие на обработку персональных данных',
        save_climatic_survey: 'СОХРАНИТЬ',

        name_prostate_survey: 'Скрининг заболеваний предстательной железы (ПСА (общий, связанный), УЗИ ПЖ)',
        how_bladder_prostate_survey: 'Как часто Вы просыпаетесь ночью, чтоб опорожнить мочевой пузырь?',
        answer1_no_prostate_survey: 'Не просыпаюсь',
        answer1_1_prostate_survey: '1 раз',
        answer1_2_more_prostate_survey: '2 и более раза',
        incomplete_emptying_prostate_survey: 'Отмечаете ли Вы чувство неполного опорожнения мочевого пузыря?',
        answer2_yes_prostate_survey: 'Да',
        answer2_no_prostate_survey: 'Нет',
        have_blood_urination_prostate_survey: 'Бывали ли у Вас эпизоды мочеиспускания с кровью?',
        answer3_yes_prostate_survey: 'Да',
        answer3_no_prostate_survey: 'Нет',
        have_urinary_retention_prostate_survey: 'Бывали ли у Вас, когда ни будь задержки мочеиспускания?',
        answer4_yes_prostate_survey: 'Да',
        answer4_no_prostate_survey: 'Нет',
        when_psa_prostate_survey: 'Когда последний раз сдавали онкомаркер на ПСА',
        answer5_enter_year_prostate_survey: 'Введите год сдачи',
        answer5_normal_prostate_survey: 'В пределах нормы',
        answer5_high_prostate_survey: 'Немного повышенный',
        answer5_no_prostate_survey: 'Не проходил',
        have_neoplasms_prostate_survey: 'Имеются ли у Вас подтверждённые злокачественные новообразования предстательной железы?',
        answer6_yes_prostate_survey: 'Да',
        answer6_no_prostate_survey: 'Нет',
        had_temperature_prostate_survey: 'Последние 10 дней были ли у Вас эпизоды повышения температуры тела выше 37,5*С',
        answer7_yes_prostate_survey: 'Да',
        answer7_no_prostate_survey: 'Нет',
        consent_prostate_survey: 'Согласие на обработку персональных данных',
        save_prostate_survey: 'СОХРАНИТЬ',

        name_cervix_survey: 'Скрининг рака шейки матки (визуальный осмотр, онкоцитология + жидкостная биопсия)',
        beginning_sexual_activity: 'Укажите пожалуйста начало половой жизни​',
        how_partners: 'Укажите пожалуйста количество половых партнёров',
        how_births: 'Укажите пожалуйста количество родов',
        had_cervix_injuries: 'При родах были ли травмы шейки матки (разрывы)',
        answer4_yes_cervix_survey: 'Да',
        answer4_no_cervix_survey: 'Нет',
        have_papillomavirus_vaccine: 'Получали ли вакцину от вируса папилломы человека (ВПЧ)?',
        answer5_yes_cervix_survey: 'Да',
        answer5_no_cervix_survey: 'Нет',
        have_discharge_genital: 'Имеются ли у Вас выделения из половых путей после полового акта',
        answer6_yes_cervix_survey: 'Да',
        answer6_no_cervix_survey: 'Нет',
        have_intercourse_pain: 'Бывают ли болевые ощущения при половом акте?',
        answer7_yes_cervix_survey: 'Да',
        answer7_no_cervix_survey: 'Нет',
        treated_diseases: 'Проходили ли Вы лечения по поводу заболеваний шейки матки?',
        answer8_erosion: '- эрозия шейки матки',
        answer8_dysplasia: '- Дисплазия шейки матки',
        answer8_leukoplakia: '- лейкоплакия шейки матки',
        answer8_endocervicitis: '- эндоцервицит',
        detected_hpv: 'Было ли выявлено носительство ВПЧ инфекции?',
        answer9_yes_cervix_survey: 'Да',
        answer9_no_cervix_survey: 'Нет',
        when_gynecologist: 'Когда были в последний раз у врача гинеколога?',
        answer10_this_year: 'Прошла в этом году',
        answer10_last_year: 'Прошла в прошлом году',
        answer10_no: 'Никогда не проходила',
        had_temperature: 'Последние 10 дней были ли у Вас эпизоды повышения температуры тела выше 37,5*С',
        answer11_yes_cervix_survey: 'Да',
        answer11_no_cervix_survey: 'Нет',
        consent_cervix_survey: 'Согласие на обработку персональных данных',
        save_cervix_survey: 'СОХРАНИТЬ',

        name_breast_survey: 'Скрининг заболевания молочной железы ( с 18-34 УЗИ; с 35 и старше - маммография)',
        cases_cancer_breast_survey: 'Есть ли среди Ваших родственников (отец/ мать; бабушка/дедушка; сестра/ брат) случаи заболевания раком молочной железы, раком толстой кишки, раком матки и яичников',
        answer1_yes_breast_survey: 'Да',
        answer1_no_breast_survey: 'Нет',
        determine_formation_breast_survey: 'При пальпации молочной железы определяете ли Вы какое ни будь образование?',
        answer2_yes_breast_survey: 'Да',
        answer2_no_breast_survey: 'Нет',
        have_lemon_peel_breast_survey: 'Имеются ли Вы на молочной железе участки кожи напоминающий «лимонную корку»?',
        answer3_yes_breast_survey: 'Да',
        answer3_no_breast_survey: 'Нет',
        have_nipple_discharge_breast_survey: 'Имеются ли Вы выделения из соска?',
        answer4_yes_breast_survey: 'Да',
        answer4_no_breast_survey: 'Нет',
        have_change_nipple_breast_survey: 'Не отмечали ли Вы изменение соска (втяжение)?',
        answer5_yes_breast_survey: 'Да',
        answer5_no_breast_survey: 'Нет',
        when_mammogram_breast_survey: 'Когда Вы проходили последний раз маммографию?',
        answer6_this_year_breast_survey: 'Прошла в этом году',
        answer6_last_year_breast_survey: 'Прошла в прошлом году',
        answer6_no_breast_survey: 'Никогда не проходила',
        have_confirmed_malignancy_breast_survey: 'Имеются ли у Вас подтверждённое злокачественное новообразование?',
        answer7_yes_breast_survey: 'Да',
        answer7_no_breast_survey: 'Нет',
        menstruation_age_breast_survey: 'Укажите возраст начала менструации',
        had_childbirth_breast_survey: 'Были ли у Вас роды ?',
        answer9_yes_breast_survey: 'Да',
        answer9_no_breast_survey: 'Нет',
        had_temperature_breast_survey: 'Последние 10 дней были ли у Вас эпизоды повышения температуры тела выше 37,5*С',
        answer10_yes_breast_survey: 'Да',
        answer10_no_breast_survey: 'Нет',
        consent_breast_survey: 'Согласие на обработку персональных данных',
        save_breast_survey: 'СОХРАНИТЬ',

        name_intestine_survey: 'Скрининг на заболевания толстой и прямой кишки (Кал на скрытую кровь, по показаниям ФКС)',
        relatives_cancer_intestine_survey_intestine_survey: 'Есть ли среди Ваших родственников (отец/ мать; бабушка/дедушка) случаи заболевания раком толстой или прямой кишки? ',
        answer1_yes_intestine_survey: 'Да',
        answer1_no_intestine_survey: 'Нет',
        have_constipation_intestine_survey: 'Бывает ли у Вас поносы переходящие на запоры',
        answer2_yes_intestine_survey: 'Да',
        answer2_no_intestine_survey: 'Нет',
        have_blood_feces_intestine_survey: 'Замечали ли Вы при акте дефекации наличие крови в кале?',
        answer3_yes_intestine_survey: 'Да',
        answer3_no_intestine_survey: 'Нет',
        have_mucus_feces_intestine_survey: 'Замечали ли Вы при акте дефекации наличие слизи (в большом количестве) в кале?',
        answer4_yes_intestine_survey: 'Да',
        answer4_no_intestine_survey: 'Нет',
        have_weight_loss_intestine_survey: 'Имеется ли у Вас необъясняемое снижение массы тела',
        answer5_yes_intestine_survey: 'Да',
        answer5_no_intestine_survey: 'Нет',
        changes_poop_intestine_survey: 'Отмечаете ли Вы изменения стула ?',
        answer6_yes_intestine_survey: 'Да',
        answer6_no_intestine_survey: 'Нет',
        incomplete_emptying_intestine_survey: 'Бывают ли у Вас чувство неполного опорожнения прямой кишки после акта дефекации?',
        answer7_yes_intestine_survey: 'Да',
        answer7_no_intestine_survey: 'нет',
        when_colonoscopy_intestine_survey: 'Когда Вы последний раз проходили колоноскопию',
        answer8_date: 'Указать дату',
        answer8_normal: 'Норма',
        answer8_no: 'Не проходил',
        answer8_had_polyp: 'Был полип',
        answer8_had_adenoma: 'Была аденома',
        have_chronic_diseases_intestine_survey: 'Имеются ли у Вас хронические заболевания толстой кишки (Болезнь крона или неспецифический язвенный колит, рак толстой кишки)? ',
        answer9_yes_intestine_survey: 'Да',
        answer9_no_intestine_survey: 'Нет',
        had_temperature_intestine_survey: 'Последние 10 дней были ли у Вас эпизоды повышения температуры тела выше 37,5*С.',
        answer10_yes_intestine_survey: 'Да',
        answer10_no_intestine_survey: 'Нет',
        consent_intestine_survey: 'Согласие на обработку персональных данных',
        save_intestine_survey: 'СОХРАНИТЬ',

        sex_profile: 'Пол',
        woman_profile: 'Женщина',
        man_profile: 'Мужчина',
        birthdate_profile: 'Дата рождения *',
        snils_profile: 'Снилс *',
        polis_profile: 'Полис ОМС *',
        foundation_profile: 'Выбор учреждения *',
        save_profile: 'Сохранить',
        check_intestine_survey_go: 'Перейти к исследованию',
        intestine_survey_go: 'Скриннинг на заболевания толстой и прямой кишки',
        lung_survey_go: 'Скриннинг на заболевания легкого',
        prostate_survey_go: 'Скриннинг на заболевания предстательной железы',
        cervix_survey_go: 'Скриннинг на заболевания шейки матки',
        breast_survey_go: 'Скриннинг на заболевания молочной железы',
        climatic_survey_go: 'Скриннинг на заболевания печени',
        back: 'Выйти',
        results: 'Результаты',
        schudule: 'Расписание',

        copyright_profile: ' Якутский республиканский онкологический диспансер.',
        usefulLinks: 'Полезные ссылки',
        partners: 'на партнеров проекта',
        health: 'Берегите здоровье!',
        health_desacription: '“Регистрация - Тестирование - Осмотр”',

        result_survey_modal: 'Тест не является на 100% точным Низкий риск означает, что вероятность развития рака мала, но не равна нулю. Необходимо ежегодно проходить медицинский осмотр.',
        result_survey_modal2: 'Группа высокого риска, это может означать, что у Вас имеются ряд факторов риска, которые могут вызвать развитие рака. Поэтому необходимо пройти обследование.',
        modal_surveys: 'Спешим напомнить, что НЕ ВСЕ тесты могут быть Вам доступны, в связи с тем, что скрининг разработан согласно рекомендациям ВОЗ и клиническим рекомендациям Ассоциации Онкологов России (АОР), учитывая ваш пол и возраст.',
        enter: 'Войти',
        forgotButtonText: 'ВОССТАНОВИТЬ ПАРОЛЬ',
        climatic_period: 'Климактерический синдром',
        Infertility: 'Бесплодие',
        endometriosis: 'Эндометриоз'
    },
    [LOCALES.SAKHA]: {
        question_answers_menu: 'Ыйытыы/хоруй',
        about_menu: 'Бырайыак туһунан',
        index_menu: 'Сүрүн',
        contacts_menu: 'Аадырыс',
        profile_menu: 'Тус хос',
        index_page_title: 'Саха сиригэр раагы эрдэ билии, диагноз туруоруу',
        partners_project: 'Бырайыак кыттыылаахтара',
        partners_names: 'ЯРОД, МИНЗДРАВ, ЯРМИАЦ, ЭСФЕРАЛЬ, АОРСЯ',
        profile_main_page: 'Тус хос',
        profile_description_main_page: 'Түмүктэр тус хоскор харалла сытыахтара. Туһата:',
        profile_plus_access_main_page: 'Сууккаҕа 24 чаас. нэдиэлэҕэ 7 күн тухары, ол аата хаһан баҕарар көрүөххэ сөп',
        profile_plus_integration_main_page: 'Балыыһалары кытта ситимнээх',
        profile_plus_appointment_time_main_page: ' Бырааска хаһан сылдьаргын бэйэҥ талыаххын сөп',
        profile_button_main_page: 'Тус хоскор көһүү',
        registration_main_page: 'Регистрация.',
        registration_description_main_page: 'Түргэн регистрация СМС көмөтүнэн уонна судургу толоруу көмөтүнэн хааччыллар.',
        questioning_main_page: 'Анкеталааһын',
        questioning_description_main_page: 'Анкеталары анал үөрэхтээх дьон кичэйэн оҥордулар.',
        reception_main_page: 'Бырааска көрдөрүү',
        reception_description_main_page: 'Уочаракка турбаккыт туһугар бырааска суруттарыыны судургу гынныбыт.',
        aisen_name_slider: 'Айсен Сергеевич Николаев',
        aisen_position_slider: ' Саха Өрөспүүбүлүкэтин Ил Дархана:',
        aisen_description_slider: ' “Национальнай бырайыактары туһааннаах көрдөрүүгэ таһаарарга урукку халыыбынан буолбакка, кэмигэр сөпкө дьаһайан, көдьүүстээхтик салайыахтаахпыт”.',
        lena_name_slider: 'Лена Николаевна Афанасьева',

        lena_position_slider: ' Саха Өрөспүүбүлүкэтин доруобуйа харыстабылын миниистирэ:',
        lena_description_slider: 'Бырайыак онкология ыарыыларын эрдэттэн, дириҥии иликтэринэ билэргэ туһуланар. Аныгы технологиялар сэрэтэр мэдиссиинэҕэ улаханнык туһалыахтара дии саныыбын.',
        tatiana_name_slider: ' Татьяна Ивановна Николаева',
        tatiana_position_slider: ' судаарыстыбаннай бүддьүөт тэрилтэтин сүрүн бырааһа',
        tatiana_description_slider: ' “Бырайыак онкология ыарыыларын кыайарбытыгар көмөлөһүө уонна Саха сирин олохтоохторугар туһалаах буолуо диэн эрэллээхпин”',
        feodosia_name_slider: 'Феодосия Гаврильевна Иванова. ',
        feodosia_position_slider: 'Доруобуйа харыстабылын министиэристибэтин штааты таһынан сүрүн онколога: ',
        feodosia_description_slider: '“Күннээҕи олоххо-дьаһахха туһуламмыт өй-санаа ускуустубаҕа кубулуйан дьоллоох олоҕу түстүүр, үтүө санааны угуттуур”.',
        surveys_main_page: 'Анкеталар',
        screening_lungs: 'Тыҥаны чинчийии',
        screening_climatic: 'Быары чинчийии',
        screening_colon: 'Суон оһоҕоһу чинчийии',
        screening_cervix: 'Киэли хапчаҕайын чинчийии',
        screening_prostate: 'Самах былчархайын чинчийии',
        screening_mammary_gland: 'Эмиийи чинчийии',
        our_mission_about_tag_page: 'Биһиги сүрүн сорукпут.',
        our_mission_about_page: 'Саха сиригэр элбэх киһини чинчийэн рагы эрдэ булуу.',
        population_about_page: 'СӨ олохтоохторун ахсаана 964330 ',
        registered_about_page: 'киһи СӨ онкологияҕа учуокка турар',
        mortality_about_page: 'СӨ сылга ортотунан рактан сылтаан өлөр',
        population_50_about_page: 'Өрөспүүбүлүкэ олохтоохторун 50 саастарыттан аҕа дьон',
        pilot_project_about_page: '“Доруобуйа харыстабыла” национальнай бырайыак иһинэн Саха өрөспүүбүлүкэтээҕи онкодиспансера “ОНКОПОИСК.РФ” диэн пилотнай бырайыагы оҥордо',
        about_about_page: 'Бырайыак туһунан',
        about_1_about_page: 'Скрининг ыытар “ОНКОПОИСК” бырайыак чөл туруктаах дьону чинчийэн раак буолаары гыммыт туруктаах, эбэтэр раагынан саҥа ыалдьан эрэр дьону булууга туһуланар. Бырайыак соруга - ыарыыны төһө кыалларынан эрдэ булуу, уталыппакка диагноз туруоруу, кэмигэр эмтээһини саҕалааһын. “Онкопоиск” бырайыак Саха сирин уратыларын учуоттаан ыалдьар кыахтаах дьону эрдэттэн түмэн сиһилии чинчийиигэ ыытыахтаах. Саха сиригэр саамай элбэх киһини өлөрөр рак 6 көрүҥүн таллыбыт. Бу искэннэргэ скринини (эрдэттэн элбэх киһини чинчийиини) туһанар улахан көдьүүстээҕэ дакаастанан турар.',
        about_2_about_page: 'Анкета толорооччу кутталлаах бөлөххө киирэрэ билиннэҕинэ, кинини Онкодиспансерга дириҥник чинчийиигэ барарыгар сүбэлиэхпит. Бу чинчийии ОМС иһинэн ыытыллар (ол аата ыарыһахха босхо) уонна ыарыһах хаһан, хаска кэлэрин бэйэтэ талар. Чинчийии программатыгар киһи ханнык бөлөххө киирэриттэн тутулуктанан маннык чинчийиилэр киириэхтэрин сөп: лаборатория анаалыстара, анал тэрил көмөтүнэн чинчийии, УЗИ, намыһах дьайыылаах түөс томографията. Эйигиттэн сөбүлэҥ эрэ ирдэнэр.',
        about_3_about_page: 'Анкеталааһын уонна эбии чинчийии кэнниттэн, ирдэнэр буоллаҕына, салгыы чинчийиини ааһарга кыах бэриллиэҕэ.',
        about_4_about_page: 'Тыҥа искэнингэр чинчийии - кутталлаах бөлөххө киирэр түгэҥҥэр намыһах дьайыылаах көмпүүтэр томографиятыгар (нууччалыы кылгатан НДКТ) түөскэр баар уорганнаргын көрдөрүөххүн сөп. Чинчийии 32 түһэриини оҥорор саҥа BodyTom диэн аппараатынан оҥоһуллар. НДКТ үчүгэйэ диэн, ФЛГ курдук намыһах дьайыылаах эрээри кыра да уларыйыыны чопчу көрдөрөр. Таарыйа онкомаркерга хаан туттарыаххын сөп.',
        about_5_about_page: 'Эмиий искэнигэр чинчийии - кутталлаах бөлөххө киирэр түгэҥҥэр хааҥҥын онкомаркердарга туттарыаххын, түөскүн УЗИ-латыаххын уонна маммография оҥотторуоххун сөп.',
        about_6_about_page: 'Суон оһоҕос искэнигэр чинчийии - кутталлаах бөлөххө киирэр түгэҥҥэр хааҥҥын онкомаркердарга туттарыаххын уонна хойуугар көстүбэт хаан баарын экспресс-тест көмөтүнэн билиэххин сөп. Тест кумааҕы лоскуйун курдук, туттарга судургу, чинчийиини бэйэҥ дьиэҕэр оҥостоҕун. ',
        about_7_about_page: 'Быар искэнигэр скрининг - кутталлаах бөлөххө киирэр түгэҥҥэр хааҥҥын онкомаркердарга туттарыаххын уонна быар УЗИ-тын ааһыаххын сөп. Ону таһынан хаан анаалыстарын ылыахтара: биохимия анаалыһын,  В уонна С гепатиттар вирустара баарын-суоҕун көрдөрөр анаалыһын',
        about_8_about_page: 'Киэли хапчаҕайын искэнигэр чинчийии -  кутталлаах бөлөххө киирэр түгэҥҥэр хааҥҥын онкомаркердарга туттарыаххын уонна онкогинеколог бырааска көрдөрүөххүн, хапчаҕайтан мазок биэрэн ПАП-тест диэн уонна убаҕас биопсия диэн анаалыстары туттарыаххын сөп, ону таһынан папиллома вируһун (ВПЧ) баарын-суоҕун билэр анаалыс туттарыаххын сөп.',
        about_9_about_page: 'Самах былчархайын  искэнигэр чинчийии - кутталлаах бөлөххө киирэр түгэҥҥэр хааҥҥын онкомаркердарга туттарыаххын уонна самах былчархайын УЗИ-латыаххын, простатспецифическэй антигеҥҥа анаалыс (ПСА) туттарыаххын сөп',
        faq_description: 'Манна скрининг туһунан элбэхтэ бэриллэр ыйытыыларга хоруйдуохпут.',
        faq_screening_canser: 'Рак скрининэ диэн тугуй?',
        faq_early_diagnosis: 'Рагы эрдэ диагностааһын диэн тугуй?',
        faq_how_does: '“Онкопоиск” хайдах үлэлиирий?',
        faq_not_working_surveys: 'Сорох анкеталар тоҕо үлэлээбэттэрий?',
        faq_i_have_cancer: 'Миигин үрдүк кутталлаах бөлөххө киллэрдилэр, ол аата  мин рактаахпын дуо?',
        faq_why_pilot_project: 'Бырайыак пилотнай дэммит, тоҕо?',
        faq_answer_screening_canser: 'Рак скрининэ диэн рагынан ыалдьыан иннинэ киһи организмыгар буолар уларыйыылары булуу, киһи бэйэтэ ыалдьыбытын билбэккэ сырыттаҕына рагы эрдэтээҥи стадиятыгар булуу.  Чинчийии рак ханнык эрэ уоргаҥҥа  үөскүүрэ үрдүк кутталлаах буоллаҕына ыытыллар. Маннык тестэр ыарыыны эрдэттэн, дириҥии илигинэ, эмтэнэр стадиятыгар буларга көмөлөһөллөр.',
        faq_answer_early_diagnosis: 'Рагы эрдэ диагностааһын диэн раак анал сибикитин таба көрөн ыарыыны эрдэ булуу. Төһө кыалларынан  эрдэ диагноз туруоран, уталыппакка эмтээһини саҕалыыр соруктаах.',
        faq_answer_how_does: 'Бастакы түһүмэх: ханнык баҕарар киһи анкетаны толорор, кутталлаах бөлөххө киирэрэ билиннэҕинэ, киһи бэйэтин баҕатынан скрининг чинчийиини ааһарыгар сүбэ бэриллэр. Иккис түһүмэх: Кутталлаах бөлөххө киирбит пациент Онкодиспансерга хас да чинчийиини ааһар. Үһүс түһүмэх: Пациент тус бэйэтин “хоһугар” чинчийии түмүгүн ылар. Искэн баара уорбаланар түгэнигэр киһиэхэ эбии чинчийиини ааһарыгар направление бэриллиэҕэ.',
        faq_answer_not_working_surveys: 'Былаан быһыытынан анкеталар түһүмэх-түһүмэх холбонуохтара',
        faq_answer_i_have_cancer: 'Суох. Үрдүк кутталлаах бөлөххө киирии - ол аата Эйиэхэ раак үөскүүрүгэр төрүөт буолар хас да кутталлаах фактор баара биллибит эбит. Ол иһин дириҥ чинчийиини барыахтааххын.',
        faq_answer_why_pilot_project: 'Бырайыак пилотнай дэммит, тоҕо? Саха сиригэр сабыс-саҥа бырайыак буолар, онон хайдах үлэлиирин сыаналыырга тургутууну ааһыахтаах. Элбэх дьону хабыахпыт иннинэ төһө эрэ киһини чинчийэр былааннаахпыт, ол түмүгүнэн хайдах салгыы тэрийэри, пациеннары хайдах түһүмэхтэн түһүмэххэ көһөрөрү нарылыахпыт, тупсарыахпыт. Кэлин бу бырайыакка Дьокуускай уонна улуустар поликлиникаларын холбуур былааннаахпыт.',
        faq_contact_us: 'БИҺИЭХЭ ТАХСЫЫ',
        faq_questions_remain: 'Эбии ыйытыылааххын дуо?',
        faq_write_us: 'Биһиэхэ суруйуҥ. 24 чаас иһигэр хоруйдуурга бэлэммит.',
        faq_call: 'ТӨЛӨПҮӨННЭЭҤ',
        faq_during_day: 'Сарсыарда аҕыстан киэһэ алтаҕа диэри',
        faq_name: 'Аатыҥ-суолуҥ',
        faq_email: 'Почтаҥ',
        faq_message: 'Этииҥ',
        faq_send_message: 'Ыытарга',
        contacts_reference_phones: 'Ыйытар төлөпүөннэрбит. ',
        contacts_reference_phones_description: 'Балыыһалар, дьаһалта төлөпүөннэрэ, туһалаах информация',
        contacts_yarod_name: 'Саха сиринээҕи Онкология диспансера, Дьокуускай куорат',
        contacts_chief_physician_label: 'Сүрүн быраас:',
        contacts_chief_physician: 'Николаева Татьяна Ивановна',
        contacts_address_label: 'Аадырыс:',
        contacts_address: 'Дьокуускай, Ф.Кон уул., ⅓',
        contacts_email_label: 'e-mail:',
        contacts_email: ' yarod@gov14.ru',
        contacts_reception_label: 'Приемнай:',
        contacts_reception: '(4112) 507-642',
        contacts_fax_label: 'Факс:',
        contacts_fax: '(4112) 43-21-63',
        contacts_site_label: 'Cаайт:',
        contacts_site: 'onkosakha.ru',
        contacts_hot_line_label: 'Куруук үлэлиир лииньийэ:',
        contacts_hot_line: '8 (914) 270-53-79',
        contacts_russian_line_label: 'Бүтүн Арассыыйатааҕы онкологияннан ыалдьааччыларга көмөлөһөр төлөпүөн:',
        contacts_russian_line: '8 (800) 100-01-91',
        entry: 'Киирии',
        entry_phone: 'Төлөпүөнүҥ нүөмэрэ *',
        entry_password: 'Аһарык (пароль) *',
        entry_remember_device: 'Тэрили өйдөөн хааларга',
        entry_forgot_password: 'Аһарыккын умнубут буоллаххына',
        entry_no_account: 'Бэлиэтэнэ иликкин дуо? Регистрациялан',
        registration: 'Бэлиэтэнии (регистрация)',
        registration_name: 'Аатыҥ*',
        registration_last_name: 'Араспаанньаҥ*',
        registration_patronymic: 'Аҕаҥ аата*',
        registration_missing: '/ Суох',
        registration_city: 'Куорат',
        registration_phone: 'Төлөпүөнүҥ нүөмэрэ*',
        registration_agreement: 'Тус бэйэҥ туһунан дааннайдары туһанарбытын көҥүллүүр сурук.',
        recovery_password: 'Аһарыгы (пароль) сөргүтүү',
        recovery_enter_phone_number: 'Бэлиэтэнэргэр ыйбыт төлөпүөнүҥ нүөмэрин киллэр.',
        go_to: 'көһүү',
        about_us: 'Бырайыак туһунан',
        about_title: 'Эн туруккун билэрбит суолталаах!',
        about_description: 'Эһиги тускутугар кыһаллар буолан, судургу уонна туһалаах чинчийиини тэрийдибит.',

        lung_cancer: 'ТЫҤА РААГА',
        description_lung_cancer: 'ТЫҤА РААГА - рак саамай тарҕаммыт уонна уодаһыннаах көрүҥнэриттэн биирдэстэрэ. 65 бырыһыана хойукку стадияларга (III, IV) ол аата атын уорганнарга тарҕаммытын, метастазтар баар буолбуттарын кэннэ биирдэ биллэр. Ону кытта бу ыарыыга өлүү таһыма үрдүк.',
        development_lung_cancer: 'Тыҥа рагын улаатыыта элбэх түгэннэртэн тутулуктаах. ВОЗ этэринэн рак сүрүн төрүөтүнэн тыҥа атын ыарыылара буоллаллар (куруук бэргиир хроническай бронхит, ХОБЛ, бронхоэктазтаах ыарыы уо.д.а.).',
        role_smoking_lung_cancer: 'Табахтааһын, саарбаҕа суох, рак ыарыы үөскүүрүгэр саамай тарҕаммыт фактор буолар. Рагы үөрэтэр аан дойдутааҕы агенство дааннайынан, рак 70-90 бырыһыана табаҕы кытта ситимнээх, табахтааһын рак үөскүүр кутталын 10 төгүл улаатынаннар.',
        reveald_lung_cancer: '"Өрөспүүбүлүкэҕэ 2019 сыллаахха 401 киһи тыҥа рагынан ыалдьыбыта биллибитэ. Ыарыы I-II стадиятыгар ыарыһахтартан 24,4 бырыһыаннарыгар диагноз турбут, III стадиятыгар - 35,8%, IV стадиятыгар - 39,4%. Диагноз турбутун кэннэ биир сыл иһинэн ыарыһах 55,9 бырыһыана өлбүт."',
        symptoms_lung_cancer: 'Симптомнара',
        asymptomatic_development_lung_cancer: 'Үксүгэр тыҥа рага туох да сибикитэ суох саҕаланар, онон рак эрдэтээҥи стадияларара сылын ахсын ыытыллар медицинскэй чинчийиилэр кэмнэригэр эбэтэр атын ыарыыны эмтиир кэмҥэ биллэр.',
        manifestation_symptoms_lung_cancer: 'Ол да буоллар, ыарыы сороҕор симптомнаах саҕаланар, ол эрээри, симптомнара атын ыарыылар симптомнарыгар  майгынныыр буолаллар.',
        main_symptoms_lung_cancer: 'Сүрүн симптомнара :',
        dyspnea_lung_cancer: '"-аҕылатар, салгыны эҕирийии ыараабыт курдук буолар;"',
        dry_cough_lung_cancer: '- арҕаҕырбыт силэ суох сөтөл;',
        hoarseness_lung_cancer: '- киһи саҥата кэһиэҕирэр;',
        constant_weakness_lung_cancer: '- киһи куруук сэниэтэ суох буолар;',
        weight_reduction_lung_cancer: '- аһыырыттан  тутулуга суох эмискэ дьүдэйии.',
        specific_symptom_lung_canser: 'Рак улахан стадияларыгар симптомнара атын ыарыттан уратылаах буолаллар:',
        loss_voice_lung_cancer: '- киһи саҥарбат буолан хаалар, эбэтэр саҥата уһун кэмҥэ кэһиэҕирэр;',
        debilitating_cough_lung_cancer: '- киһи сэниэтин эһэр сөтөл, силэ хойуу, тахсан биэрбэт (сороҕор хааннаах уонна ириҥэлээх буолар);',
        chest_pain_lung_cancer: '- түөс ыалдьар буолар;',
        lymph_nodes_lung_cancer: '-хомурҕан үрдүнээҕи былчархайдар көбөллөр уонна кытааталлар',
        take_test_lung_cancer: 'Кутталлаах бөлөххө киирэри билэргэ портаалга бэлиэтэн (регистрация) уонна тургутар анкетаны толор',
        view_surveys_lung_cancer: 'Анкеталары көрүү',
        diagnostic_methods_lung_cancer: 'ДИАГНОЗ ТУРУОРУУ НЬЫМАЛАРА',
        lung_ldct_lung_cancer: '"Тыҥа рагын эрдэ билэргэ түөс уорганнарын көннөрү флюорографияҕа түһэриллэр. Ону таһынан билигин намыһах дьайыылаах көмпүүтэр томограбынан (кылгатан нууччалыы НДКТ) чинчийиэххэ сөп. НДКТ — тыҥаҕа уонна түөс атын уорганнарыгар кыра да уларыйыы баар буоллаҕына чопчу көрдөрөр ньыма. НДКТ көмөтүнэн скрининг оҥоруу тыҥа рагыттан өлүүнү 44 бырыһыанынан кыччатара дакаастанан турар. "',
        universal_screening_lung_cancer: '“Доруобуйа харыстабыла” национальнай бырайыак толоруутун иһинэн киһиэхэ барытыгар сөп түбэһэр анкета көмөтүнэн чинчийии (скрининг) оҥоһуллубута. Бу анкета регион уратыларын учуоттуур.',
        indications_ldct_lung_cancer: 'НДКТ ОҤОРОРГО ТӨРҮӨТТЭР:',
        procedures_lung_cancer: 'Бу процедураны ханнык эрэ чопчу киһиэхэ оҥоруу кэтэһиллэр туһата киниэхэ оҥоһуллуон сөп омсотун куоһарыахтаах.',
        smokers_lung_cancer: 'Тыҥа ыарыытын симптомнара суох эбэтэр кыра симптомнаах 40 саастарыттан аҕа дьон, ону таһынан 20 сылтан ордук табахтаабыт дьон (күҥҥэ 1 пачканы 20 сыл устата  тардыбыт эбэтэр күҥҥэ 2 пачканы 10 сыл иһигэр тардыбыт дьон).',
        contraindications_ldct_lung_cancer: 'НДКТ МАННЫК ТҮГЭННЭРГЭ ОҤОҺУЛЛУБАТ:',
        age_lung_cancer: '- 40 сааһын туола илик дьоҥҥо',
        covid_lung_cancer: '- Соторутааҕыта ыалдьа сылдьыбыт буоллаҕына (биир ый иһинэн пневмония, ОРВИ, КОВИД-19)',
        malignant_neoplasms_lung_cancer: '- Атын рагынан ыалдьар эбэтэр ыалдьа сылдьыбыт буоллаҕына',
        year_ct_lung_cancer: '- КТ-ны биир сыл иһигэр ааһа сылдьыбыт буоллаҕына',
        fever_lung_cancer: '- 37,5 кыраадыстан үрдүк температуралаах буоллаҕына',
        elderly_lung_cancer: '- Наһаа кырдьаҕас (мөлтөөбүт) дьон',
        take_test_2_lung_cancer: 'Кутталлаах бөлөххө киирэри билэргэ портаалга бэлиэтэн (регистрация) уонна тургутар анкетаны толор',
        view_surveys_2_lung_cancer: 'Анкеталары көрүү',
        climatic_cancer: 'БЫАР РАГА',
        description_climatic_cancer: 'БЫАР РАГА (бастаан быартан саҕаламмыт рак) — рак саамай элбэх уонна уодаһыннаах көрүҥнэриттэн биирдэстэрэ. Түргэнник улаатар, метастазтыыр уонна быар үлэтин харгыстыырын түмүгэр киһини түргэнник мөлтөтөр.',
        devlopment_climatic_cancer: '"Быарга рак үөскээһинин сүрүн фактора — быар хроническай ыарыыта буолар. Онтон сылтаан быарга бастаан фиброз үөскүүр, салгыы цирроз буолар. Үксүн ол төрүөтүнэн B, C, D вируснай гепатиттар уонна быарга сыа элбээн гепатоз үөскээбитэ буолаллар. Ону таһынан кутталы улаатыннарар фактордар: сааһырыы, табахтааһын, арыгылааһын, уойуу, килиэккэлэр инсулины “истибэт” буолуулара."',
        reveald_climatic_cancer: '2019 сыллаахха Саха Өрөспүүбүлүкэтигэр 176 быар рага диагнозтаммыта. Ыарыы I-II стадиятыгар ыарыһахтартан 25,5 бырыһыаннарыгар диагноз турбут, III стадиятыгар - 47,7%, IV стадиятыгар - 28,1%. Диагноз турбутун кэннэ биир сыл иһинэн ыарыһах 57,9 бырыһыана өлбүт.',
        symptoms_climatic_cancer: 'Симптомнара',
        signs_hepatitis_climatic_cancer: 'Ыарыы түргэнник бэргиир, үксүн хойукку стадияларыгар көстөр. Быар рага быар атын ыарыыларыгар майгынныыр симптомнардаах буолар (холобур сибикилэрэ хроническай гепатит бэргииригэр, үөс тааһыгар иҥин майгынныыр). Быар рага саҕаланарыгар маннык симптомнардаах буолар:',
        bloating_climatic_cancer: '"-	ис үллүүттэ, адаарыйыыта;"',
        jaundice_climatic_cancer: '" -	саһарыы (үөс эргиирэ кэһиллиитин түмүгэр тирии, харах, айах иһэ, күөмэй саһархай дьүһүннэниитэ);"',
        colorless_feces_climatic_cancer: '"- иик хараҥа өҥнөнүүтэ, хойуу (саах) төттөрүтүн өҥө суох буолуута;"',
        constant_fatigue_climatic_cancer: '- куруук сылайбыт курдук сананыы, сэниэ суох буолуута;',
        heaviness_climatic_cancer: '- уҥа агда анныгар туох эрэ ыарахан баттыы сылдьыыта;',
        easy_bruising_climatic_cancer: ' - тириигэ онон-манан көҕөрүүлэр көстөллөр, кыратык да бааһыртахха хаан тохтоон биэрбэтэ',
        loss_appetite_climatic_cancer: '- аһыыр баҕа (аппетит) суоҕа эбэтэр кыратык да аһаатахха ис туолан хаалыыта;',
        weight_loss_climatic_cancer: ' - биллибэт төрүөтүнэн дьүдэйии.',
        specific_symptom_climatic_canser: 'Быар рагын улахан стадиятыгар симптомнара атын ыарыылартан уратыланан киирэн бараллар:',
        take_test_climatic_cancer: 'Кутталлаах бөлөххө киирэри билэргэ портаалга бэлиэтэн (регистрация) уонна тургутар анкетаны толор',
        view_syrveys_climatic_cancer: 'Анкеталары көрүү',
        diagnostics_methods_climatic_cancer: 'ДИАГНОСТИКА НЬЫМАЛАРА',
        early_diagnosis_climatic_cancer: '"Ханнык баҕарар онкология ыарыытын курдук быар рагар эмтээһин кыра стадияларга ордук хотуулаах буолар. Онон эрдэ диагноз туруоруу эмтээһинтэн кырата суох суолталаах."',
        standart_procedure_climatic_cancer: 'Билигин кутталлаах бөлөхтөргө скрининг оҥоруу стандартнай процедураларынан манныктар буолаллар:',
        ultrasound_climatic_cancer: '1) быары УЗИ-лааһын',
        tumor_marker_climatic_cancer: '2) Онкомаркерга анаалыс туттарыы (АФП).',
        indications_screening_climatic_cancer: 'СКРИНИНГ ОҤОРУУГА ТӨРҮӨТТЭР:',
        loss_voice_climatic_cancer: '- саҥа сүтэн хаалыыта, эбэтэр өр кэмҥэ саҥа кэһиэҕириитэ;',
        debilitating_cough_climatic_cancer: '- өр кэмҥэ тохтообот, сатаан тахсан биэрбэт хойуу силлээх сөтөл (сороҕор хааннаах уонна ириҥнэлээх);',
        chest_pain_climatic_cancer: '- түөс ыалдьыыта;',
        lymph_nodes_climatic_cancer: 'хомурҕан үрдүнэн кытаанах былчархай көбүүтэ',
        take_test_2_climatic_cancer: 'Кутталлаах бөлөххө киирэри билэргэ портаалга бэлиэтэн (регистрация) уонна тургутар анкетаны толор',
        view_surveys_climatic_cancer: 'Анкеталары көрүү',
        diagnostic_methods: 'ДИАГНОСТИКА КӨРҮҤНЭРЭ',
        cervix_cancer: 'КИЭЛИ ХАПЧАҔАЙЫН РАГА ',
        cervix_cancer_description: 'КИЭЛИ ХАПЧАҔАЙЫН РАГА (РАК ШЕЙКИ МАТКИ) - киэли хапчаҕайын ис бүрүөһүнүн килиэккэлэриттэн үөскүүр ыарыы. Бу рак дьахтар ууһуур уорганнарын саамай элбэхтик көстөр рага буолар, дьахталлар онкологияннан ыалдьалларыгар бигэ 5-с миэстэни ылар.',
        cervix_data1_text1: 'Киэли хапчаҕайын рагын сүрүн төрүөтүнэн папиллома вируһа (нууччалыы кылгатан ВПЧ) буолара дакаастанан турар.',
        cervix_data1_text2: 'ВПЧ - хас да вирус түмүллүбүт бөлөҕө, бу вирустар аан дойдуга сүрдээх киэҥник тарҕаммыттар. Сүүстэн тахса ВПЧ вируһун көрүҥэ баара биллэр, олортон 14-дэ онкология ыарыытын төрдө-төрүөтэ буолаллар. Вирус сүрүннээн сылдьыһыы кэмигэр тарҕанар (половой путь), онон дьон үксэ сылдьыһар саастарыгар тиийэн баран сутуллаллар. Рак 70 бырыһыаныгар ВПЧ икки көрүҥэ булуллар (16-с уонна 18-с).',
        cervix_data1_title_column1: 'Куттал үрдүүр фактордара:',
        cervix_data1_text1_column1: '"-	сылдьыһыы эрдэ саҕаланыыта"',
        cervix_data1_text2_column1: '- половой партнердары элбэхтэ уларытыы;',
        cervix_data1_text3_column1: '"-	презервативы туһаммакка сылдьыһыы;"',
        cervix_data1_text4_column1: '- табахтааһын;',
        cervix_data1_text5_column1: '- иммунитеты баттыыр эмтэри иһии;',
        cervix_data1_text6_column1: '"-	оҕолонорго киэли хапчаҕайын травмалара;"',
        cervix_data1_text7_column1: '"-	киэли хроническай ыарыылара: эрозия, лейкоплакия уо.д.а.;"',
        cervix_data1_text8_column1: '"-	сылдьыһыы сыстыганнаах ыарыылара."',
        cervix_symptoms: 'Симптомнара',
        cervix_data1_text3: 'Киэли хапчаҕайын рагын саамай элбэхтик көстөр симптомнарынан элбэх уутуҥу убаҕас тахсыыта уонна сылдьыһыы кэнниттэн хааннаах убаҕас тахсыыта буолар.',
        cervix_data1_text4: 'Төрүүр саастаах дьахталларга ыйдааҕыларын таһынан хаан кэлиитэ буолуон сөп, оттон менопауза буолбут буоллаҕына - ыарыы симптомунан кэмиттэн кэмигэр эбэтэр куруук кэлэр хаан буолуон сөп. ',
        cervix_data1_text5: 'Искэн улааттаҕына ыалдьар буолар, иик тахсыыта хааччахтанар уонна хойуулуурга мэһэйдиир буолар.',
        cervix_data1_text6: 'Периодических или постоянных. При значительном распространении опухоли появляются боли, нарушения мочеиспускания и затруднения при акте дефекации.',
        cervix_data1_text7: 'Кутталлаах бөлөххө киирэри билэргэ портаалга бэлиэтэн (регистрация) уонна тургутар анкетаны толор',
        cervix_data1_title_column2: 'ВОЗ скрининг үс көрүҥүн сүбэлиир:',
        cervix_data1_text1_column2: 'ВПЧ баарын-суоҕун билэр тест;',
        cervix_data1_text2_column2: 'кольпоскопия;',
        cervix_data1_text3_column2: 'ПАП-тест уонна убаҕас цитология ньымата (ЖЦ). ',
        cervix_data3_text1: 'Киэли хапчаҕайын рагын (рак шейки матки) эрдэттэн билээри элбэх киһини чинчийии (скрининг) үс ньымалаах: киэли хапчаҕыйаттан ылыллыбыт цитология мазоктарын чинчийии (ПАП-тест), ВПЧ баарын-суоҕун билэр анаалыс уонна убаҕас цитология (ЖЦ). Искэн баара уорбалар түгэнигэр кольпоскопия оҥоһуллар, уонна бу чинчийии кэмигэр биопсия оҥоһуллар. Бу мэтиэдикэ киэли хапчаҕайын рагын эрдэ булар эрэ  буолбатах, ыарыы саҕаланарын тохтотор кыахтаах.',
        cervix_data3_text2: 'Кэмиттэн кэмигэр көрдөрүнэр буоллахха киэли хапчаҕайын рагынан ыалдьыы 80% кыччыыр, оттон бу ыарыыттан өлүү 72% кыччыыр',
        prostate_cancer: 'САМАХ БЫЛЧАРХАЙЫН РАГА',
        prostate_description: 'САМАХ БЫЛЧАРХАЙЫН РАГА - эр киһи ууһуур уорганнарыгар киирэр самах былчархайыгар үөскүүр.',
        prostate_data1_text1: 'Ыарыы бастакы стадиятыгар искэн өр кэмҥэ туох да сибикитэ суох улаатыан сөп. Ол иһин 45 саастарын туолбут эр дьон кэмиттэн кэмигэр бырааска көрдөрүөхтээхтэр уонна анаалыс туттарыахтаахтар. Төһөннөн эрдэ ыарыы диагностанар да, оччонон эмтээһин туһалаах, үчүгэй түмүктээх буолар. Самах былчархайын раага 55 саастарыттан аҕа саастаах эр дьоҥҥо үксүн көстөр. Сылын ахсын аан дойдуга 6,5 тыһыынча киһи ыалдьара биллэр.',
        prostate_data1_text2: 'Арассыыйаҕа самах былчархайын рага 7-8 миэстэни ылар.',
        prostate_data1_text3: 'Саха Өрөспүүбүлүкэтигэр 2019 сыллаахха 114 киһи самах былчархайын раагынан ыалдьыбыта. Ыарыы I-II стадиятыгар ыарыһахтартан 54 бырыһыаннарыгар диагноз турбут, III стадиятыгар - 8,8%, IV стадиятыгар - 37,2%. Диагноз турбутун кэннэ биир сыл иһинэн ыарыһах 6,7 бырыһыана өлбүт.',
        prostate_symptoms: 'Симптомнара',
        prostate_data1_title_column1: 'Чопчу самах былчархайын рагын сибикитэ буолар симптом диэн суох. Маннык симптомнар баар буолуохтарын сөп:',
        prostate_data1_text1_column1: 'субу-субу ииктэтэр буолуута, ииктээбит кэннэ хабах  кураанахтамматах курдук буолуута, самахха туох эрэ хам тутар курдук буолуута эбэтэр ыалдьар  буолуута;',
        prostate_data1_text2_column1: '"Ыарыыны баалаппыт буоллахха - ииктииргэ хааччах үөскээһинэ, иик быста-быста кэлиитэ эбэтэр синньигэс баҕайытык кэлиитэ, иик кэлбэт буолуута, уһуннук ииктээһин, ииктииргэ ис былчыҥнарын көмөлөһүннэрии."',
        prostate_data1_text3_column1: 'Кутталлаах бөлөххө киирэри билэргэ портаалга бэлиэтэн (регистрация) уонна тургутар анкетаны толор',
        prostate_data1_title_column2: 'Самах былчархайын рагын диагностыырга маннык сүрүн ньымалары туһаналлар:',
        prostate_data1_text1_column2: 'Хаан анаалыһа - простат-специфическэй антиген (ПСА) хааҥҥа таһымын чинчийии. Скрининг 50 саастарын туолбут эр дьоҥҥо оҥоһуллар, ол эрээри сороҕор кутталлаах бөлөххө киирэр сорох дьонтон (холобур,  дьиэ кэргэннэригэр оннук ыарыы кимиэхэ эрэ диагностана сылдьыбыт түгэнигэр) эмиэ ылаллар:',
        prostate_data1_text2_column2: 'Самах былчархайын тарбаҕынан чинчийэн көрүү;',
        prostate_data1_text3_column2: 'Самах былчархайын УЗИ-лааһын.',
        breast_cancer: 'ЭМИИЙ РАГА ',
        breast_description: 'ЭМИИЙ РАГА - эмиий былчархайдарын килиэккэлэриттэн үөскүүр. Ыарыһахтар 3-10 бырыһыаннарыгар  кинилэр геннарыгар мутация баарын кытта ситимнээх буолар (BRCA1, BRCA2, CHEK, NBS1, tP53). Атыттарга эмиий рага түбэспиччэ көстөр.',
        breast_data1_text1: 'Эмиий рага Арассыыйа дьахталларын сүрүн онкология ыарытынан буолар, туох баар дьахтар онкологиятыттан 21,1% ылар. Арассыыйаҕа ыарыһахтар орто саастара 2017 сыллаахха 61,4 сыл этэ. Хас 8-с дьахтар ыалдьар кутталлаах. Эр дьоҥҥо эмиэ эмиий рага баар буолар, рак бу көрүҥэ эр дьоҥҥо бары онкологияттан 0,3% ылар.',
        breast_data1_text2: '"Өрөспүүбүлүкэҕэ 2019 сыллаахха 275 эмиий рага көстүбүтэ. Ыарыы I-II стадиятыгар ыарыһахтартан 67,3 бырыһыаннарыгар диагноз турбут, III стадиятыгар - 22,8%, IV стадиятыгар - 9,92%. Диагноз турбутун кэннэ биир сыл иһинэн ыарыһах 4,5 бырыһыана өлбүт."',
        breast_data1_title_column1: 'Куттал фактордара:',
        breast_data1_text1_column1: 'ыйданыы эрдэ саҕаланыыта;',
        breast_data1_text2_column1: 'менопауза хойут саҕаланыыта;',
        breast_data1_text3_column1: 'төрөөбөтөх дьахтар;',
        breast_data1_text4_column1: 'аборт оҥотторбут дьахтар;',
        breast_data1_text5_column1: 'табахтааһын;',
        breast_data1_text6_column1: 'арыгылааһын;',
        breast_data1_text7_column1: 'уойуу;',
        breast_data1_text8_column1: 'хамсаммат буолуу.',
        breast_data1_text9_column1: 'сахарнай диабетынан ыалдьыы',
        breast_symptoms: 'Сибикилэрэ',
        breast_data1_title_column2: 'Эмиий рагын эрдэтээҥи сибикилэрэ манныктар:',
        breast_data1_text1_column2: 'эмиийгэ искэн баар буолуута',
        breast_data1_text2_column2: 'искэн кытаанах буолуута (мас эбэтэр оннооҕор таас курдук кытаанах буолуон сөп);',
        breast_data1_text3_column2: 'искэн туттахха халбарыйан биэрбэт, биир сиргэ сылдьар, үксүгэр ыалдьыбат, түөс тириитэ иһирдьэ киирбит (тимирбит курдук) буолар;',
        breast_data1_text4_column2: 'искэн баар өттүнэн хоннох аннынааҕы былчархай көппүт буолар, эмиийтэн хааннаах убаҕас тахсыан сөп.',
        breast_data1_title_column3: 'Тэнийбит искэн сибикилэрэ маннык:',
        breast_data1_text1_column3: 'эмиий тас көстүүтэ искэн үрдүнэн уларыйар (илиини үөһэ ууннахха ордук биллэр);',
        breast_data1_text2_column3: 'эмиий тириитигэр искэн үүнэн киириитэ уонна/эбэтэр бааһыран тахсыыта;',
        breast_data1_text3_column3: 'искэн кыратык эрэ халбаҥныыр, эбэтэр хамсаабат, түөс ыалдьыбат, искэн үрдүнээҕи тирии иһирдьэ тимирбит курдук буолар;',
        breast_data1_text4_column3: 'искэн баар өттүнэн хоннох аннынааҕы былчархай көппүт буолар, эмиийтэн хааннаах убаҕас тахсыан сөп.',
        breast_data2_text1_column1: 'Үнүстүрүмүөн көмөтүнэн чинчийии ыарыыны сэрэтэр соруктаах уонна диагноһы туруорар соруктаах оҥоһуллар. Бастатан туран маммография оҥоһуллар, ол эрээри маны 35 саастарын туолбут эрэ дьахталларга оҥороллор. 35 саастарын туола илик дьахталлар эмиийдэрин УЗИ-латаллар.',
        breast_data2_text2_column1: 'Эмиий рагыттан өлүүнү кыччатыы сүрүн суола - ыарыыны эрдэ билии. Маммографияны элбэх киһиэхэ оҥорууну киллэрдэххэ эрдэ диагноз туруутун суотугар эмиий рагыттан өлүүнү ортотунан 30% кыччатыахха сөп. Маммография туһата төһө элбэх дьахтары хабартан тутулуктаах. Скрининг олохтоох дьахталлар 70% хаптаҕына туһаны аҕалар. Итиччэ элбэх чинчийиини оҥоруу оннооҕор улахан куораттарга маммографтар уонна анал специалистар аҕыйахтарынан уустук сорук. Онон куттал ордук күүскэ суоһуур бөлөхтөрүн эрэ маммографиялыыр ордук туһалаах буолан тахсар. ',
        gut_cancer: 'ОҺОҔОС РАГА',
        gut_description: '"ОҺОҔОС РАГА - суон оһоҕос уонна көнө оһоҕос тарҕаммыт искэнэ. Оһоҕос ис бүрүөтүн килиэккэлэрэ үллэһиллиилэрин алҕаһа таҕыстаҕына полиптар баар буолууларын кытта ситимнээх. Төһө эрэ кэм ааспытын кэннэ полип аденомаҕа кубулуйар, онтон үксүгэр рак килиэккэлэрэ үөскүүллэр."',
        gut_data1_text1: 'Ыарыһахтар 3-5 бырыһыаннара утумунан төрөппүттэриттэриттэн бэриллибит синдромнаах буолаллар, олортон саамай тарҕаммыттара Линч синдрома, суон оһоҕос аденоматоһа уонна MutYH-ситимнээх полипоз. Уоннааҕы ыарыһахтарга рак үөскээһинэ түбэспиччэ буолар.',
        gut_data1_text2: 'ВОЗ дааннайынан суон оһоҕос рага онкология ыарыыларыгар үһүс миэстэни ылар. Суон оһоҕос рагынан сылын ахсын 1 мөл. киһи ыалдьар, 600 тыһ. киһи сыл ахсын бу ыарыыттан сылтаан өлөр. Дьон быраастарга хойут тиийэр буолан, ыарыһахтар 30 бырыһыаннарыгар рак IV стадиятын диагностыыллар. Дьиҥэр, суон оһоҕос рага саҕаланан эрэрин эрдэ билии - киһи олоҕун уһун буолуутун сүрүн мэктиэтэ. ',
        gut_data1_title_column1: 'Кутталы үксэтэр фактордар:',
        gut_data1_text1_column1: '- күннэтэ аһыыр аска эт элбэҕэ (Саха сирин тыйыс айылҕатыгар бу, биллэн турар, олох ирдэбилэ);"',
        gut_data1_text2_column1: '- суон оһоҕос хроническай ыарыылара баара (холобур, неспецифическэй язвалаах колит, Крон ыарыыта);',
        gut_data1_text3_column1: '- табахтааһын;',
        gut_data1_text4_column1: '"- арыгыны элбэхтик иһии;"',
        gut_data1_text5_column1: '"- уойуу эбэтэр ыйааһын ииндэксэ үрдээһинэ;"',
        gut_data1_text6_column1: 'хамсаабат буолуу, сытан-олорон тахсыы.',
        gut_symptoms: 'Симптомнара',
        gut_data1_text1_column2: 'Атын онкология ыарыыларын курдук, суон оһоҕос рага өр кэм устата симптома суох улаатыан сөп. Ол иһин кэмиттэн кэмигэр профилактикалаан чинчийии улахан суолталаах. Суон оһоҕос хаҥас өттүгэр искэн үөскүүр түгэнигэр хойууга хаан булкаастаах буолуон сөп. Маны үгүс дьон эрэ буолбакка, оннооҕор быраастар аахайбат түгэннэрэ үгүс буолар, тоҕо диэтэххэ тарҕаммыт ыарыыны -  геморройу - кытта бутуйаллар.',
        gut_data1_text2_column2: 'Ыарыы симптомунан эмискэ биллибит анемия (хаан аҕыйааһына) буолуон сөп. Анаалыска гемоглобин түһүүтэ туох да төрүөтэ суох буолбат, оннугу көрдөххө тута салгыы чинчийиигэ барыллыахтаах. Сороҕор рак бастакы симптомунан хойуу тахсыбат буолан хаалыыта буолар.',
        gut_data1_text3_column2: 'Суон оһоҕос рагын симптомнара үксүн хойукку эрэ стадияларга биллэринэн, кэмиттэн кэмигэр хайаан да көрдөрүнэ сылдьар куолу, туох да ыалдьыбатын, кутталлаах симптомнар суохтарын да иһин - бу олоҕу быыһыан сөп.',
        gut_data1_text4_column2: '“Доруобуйа харыстабыла” национальнай бырайыак толоруутун иһинэн киһиэхэ барытыгар сөп түбэһэр анкета көмөтүнэн чинчийии (скрининг) оҥоһуллубута. Бу анкета регион уратыларын учуоттуур.',
        gut_data2_text1: '"Суон оһоҕос рагын скрининг көмөтүнэн билэргэ саамай куттала суох ньыманан хойууга (саахха) көстүбэт хааны чинчийии буолар. Хойууну иммунохимия көмөтүнэн экспресс-чинчийии (саахха гемоглобин баарын-суоҕун билии) диагностика бигэ ньымата буолар. Өскөтө хойууга хаан баара билиннэҕинэ фиброколоноскопия оҥорон суон оһоҕоһу иһиттэн хараҕынан көрөллөр, полип, аденома уонна рак искэнэ баарын быһаараллар."',

        name_lung_survey: 'Тыҥа ыарыыларын тургутуу ',
        how_smoke_lung_survey: 'Төһө өр табахтыыгытый?',
        answer1_how_cigarettes_lung_survey: 'Табах күҥҥэ ',
        answer1_how_years_lung_survey: 'Хас сыл табахтыыгын?',
        answer1_no_lung_survey: 'Табахтаабаппын',
        had_CT_lung_survey: 'Сыл иһигэр компьютернай томографияны ааспытыҥ дуо?',
        answer2_yes_lung_survey: 'Ааһан',
        answer2_no_lung_survey: 'Суох',
        have_hemoptysis_lung_survey: 'Хаанынан силлээччигин дуо?',
        answer3_yes_lung_survey: 'Силлээн',
        answer3_no_lung_survey: 'Суох',
        had_weight_loss_lung_survey: 'Эмискэ ыйааһыныҥ түспүтэ буолаарай? ',
        answer4_yes_lung_survey: 'Оннук баар этэ',
        answer4_no_lung_survey: 'Суох',
        have_malignancy_lung_survey: 'Раак искэнэ Эйиэхэ баар дуо?',
        answer5_yes_lung_survey: 'Баар',
        answer5_no_lung_survey: 'Суох',
        had_temperature_lung_survey: 'Бүтэһик 10 хонук иһигэр температуураҥ 37,5 кыраадыстан үрдүктүк тахса сылдьыбыттаах дуо?',
        answer6_yes_lung_survey: 'Тахсан',
        answer6_no_lung_survey: 'Суох',
        consent_lung_survey: 'Тэрили өйдөөн хааларга',
        save_lung_survey: 'ЫЫТАРГА',

        name_climatic_survey: 'Быар ыарыыларын тургутуу (быар сыстыганнаах ыарыылара)',
        had_hepatitis_climatic_survey: 'Хроническай вируснай гепатит Б эбэтэр С диагноһын туруора сылдьыбыттара дуо?',
        answer1_yes_climatic_survey: 'Туруоран',
        answer1_no_climatic_survey: 'Суох',
        carrier_hbsag_climatic_survey: '"Анаалыстаан баран HBsAg (аш-би-эс антиген) хааҥҥар баар (положительнай) дииллэр этэ дуо?"',
        answer2_yes_climatic_survey: 'Баар',
        answer2_no_climatic_survey: 'Суох',
        have_cirrhosis_climatic_survey: 'Быарыҥ циррозтаах дииллэр этэ дуо?',
        answer3_yes_climatic_survey: 'Инньэ диэччилэр',
        answer3_no_climatic_survey: 'Суох',
        skin_itching_climatic_survey: 'Тирииҥ туох да биллэр төрүөтэ суох кыһыйар дуо, кыһыйара түүнүн күүһүрэр дуо?',
        answer4_yes_climatic_survey: 'Кыһыйар',
        answer4_no_climatic_survey: 'Суох',
        pain_right_hypochondrium_climatic_survey: 'Уҥа агдаҥ аннынан аалан ыалдьааччы дуо?',
        answer5_yes_climatic_survey: 'Ыалдьааччы',
        answer5_no_climatic_survey: 'Суох',
        blood_vomit_climatic_survey: 'Хаан булкадаһыктааҕынан хотуолааччыгын дуо?',
        answer6_yes_climatic_survey: 'Хотуолаан',
        answer6_no_climatic_survey: 'Суох',
        have_weight_loss_climatic_survey: 'Ыйааһыныҥ эмискэ түстэ дуо?',
        answer7_yes_climatic_survey: 'Түһэн',
        answer7_no_climatic_survey: 'Суох',
        parents_cancer_climatic_survey: '"Төрөппүттэриҥ быар раагынан ыалдьа сылдьыбыттара дуо?"',
        answer8_yes_climatic_survey: 'Ыалдьан',
        answer8_no_climatic_survey: 'Суох',
        had_temperature_climatic_survey: 'Бүтэһик 10 хонук иһигэр температуураҥ 37,5 кыраадыстан үрдүктүк тахса сылдьыбыттаах дуо?',
        answer9_yes_climatic_survey: 'Тахсан',
        answer9_no_climatic_survey: 'Суох',
        consent_climatic_survey: 'Тэрили өйдөөн хааларга',
        save_climatic_survey: 'ЫЫТАРГА',

        name_prostate_survey: 'Самах былчархайын (простата) ыарыыларын тургутуу',
        how_bladder_prostate_survey: 'Түүн хаста ииктиигиний?',
        answer1_no_prostate_survey: 'Түүн турбаппын',
        answer1_1_prostate_survey: 'Биирдэ турабын',
        answer1_2_more_prostate_survey: 'Иккитэ эбэтэр элбэхтэ турабын',
        incomplete_emptying_prostate_survey: 'Ииктээн бүппүтүҥ кэннэ хабаҕыҥ билигин да кураанахсыйа илик курдук буолар дуо?',
        answer2_yes_prostate_survey: 'Буолар',
        answer2_no_prostate_survey: 'Суох',
        have_blood_urination_prostate_survey: 'Биирдэ эмит иигиҥ хааннаах этэ дуо?',
        answer3_yes_prostate_survey: 'Оннук буолбуттаах',
        answer3_no_prostate_survey: 'Суох',
        have_urinary_retention_prostate_survey: 'Хаһан эмит иигиҥ кыайан кэлбэт буолбут түгэнэ баара дуо?',
        answer4_yes_prostate_survey: 'Оннук буола сылдьыбыттаах',
        answer4_no_prostate_survey: 'Суох',
        when_psa_prostate_survey: 'ПСА онкомаркерга анаалыс бүтэһигин хаһан туттарбыккыный?',
        answer5_enter_year_prostate_survey: 'Күнүн-дьылын ый уонна түмүгүн',
        answer5_normal_prostate_survey: 'Норма иһинэн',
        answer5_high_prostate_survey: 'Кыратык улааппыт этэ',
        answer5_no_prostate_survey: 'Ааспатаҕым',
        have_neoplasms_prostate_survey: 'Бигэргэтиллибит раак ыарыылааххын дуо?',
        answer6_yes_prostate_survey: 'Баар',
        answer6_no_prostate_survey: 'Суох',
        had_temperature_prostate_survey: 'Бүтэһик 10 хонук иһигэр температуураҥ 37,5 кыраадыстан үрдүктүк тахса сылдьыбыттаах дуо?',
        answer7_yes_prostate_survey: 'Тахсан',
        answer7_no_prostate_survey: 'Суох',
        consent_prostate_survey: 'Тэрили өйдөөн хааларга',
        save_prostate_survey: 'ЫЫТАРГА',

        name_cervix_survey: 'Киэли хапчаҕайын (шейка матки) ыарыыларын тургутуу',
        beginning_sexual_activity: 'Эр киһилиин аан бастаан сылдьыспыт сааскын ый дуу',
        how_partners: 'Хас эр киһилиин сылдьыспыккын суруй дуу ',
        how_births: 'Хаста төрөөбүккүн суруй дуу ',
        had_cervix_injuries: 'Төрүүргэр киэли хапчаҕайа (шейка матки) хайда сылдьыбыттаах дуо? ',
        answer4_yes_cervix_survey: 'Хайдан',
        answer4_no_cervix_survey: 'Суох',
        have_papillomavirus_vaccine: 'Папиллома (ВПЧ) утары быһыы ылбытыҥ дуо?',
        answer5_yes_cervix_survey: 'Ылан',
        answer5_no_cervix_survey: 'Суох',
        have_discharge_genital: 'Эр киһилиин сылдьыспытыҥ кэннэ искиттэн убаҕас кэлэр дуо?',
        answer6_yes_cervix_survey: 'Кэлэр',
        answer6_no_cervix_survey: 'Суох',
        have_intercourse_pain: 'Эр киһилиин сылдьыһаргар ыалдьааччы дуо?',
        answer7_yes_cervix_survey: 'Ыалдьар',
        answer7_no_cervix_survey: 'Суох',
        treated_diseases: 'Киэли хапчаҕайын (шейка матки) ыарыыларыттан эмтэнэ сылдьыбытыҥ дуо?',
        answer8_erosion: 'Эрозия',
        answer8_dysplasia: 'Дисплазия',
        answer8_leukoplakia: 'Лейкоплакия',
        answer8_endocervicitis: 'Эндоцервицит',
        detected_hpv: 'Анаалыстаан папиллома вируһа (ВПЧ-инфекция) баар эбит дии сылдьыбыттара дуо?',
        answer9_yes_cervix_survey: 'Баар',
        answer9_no_cervix_survey: 'Суох',
        when_gynecologist: 'Гинеколог бырааска бүтэһигин хаһан сылдьыбыккыный?',
        answer10_this_year: 'Быйыл',
        answer10_last_year: 'Былырыын',
        answer10_no: 'Сылдьыбатаҕым ыраатта',
        had_temperature: 'Бүтэһик 10 хонук иһигэр температуураҥ 37,5 кыраадыстан үрдүктүк тахса сылдьыбыттаах дуо?',
        answer11_yes_cervix_survey: 'Тахсан',
        answer11_no_cervix_survey: 'Суох',
        consent_cervix_survey: 'Тэрили өйдөөн хааларга',
        save_cervix_survey: 'ЫЫТАРГА',

        name_breast_survey: 'Эмиий ыарыыларын тургутуу',
        cases_cancer_breast_survey: 'Чугас аймахтарыҥ (ийэҥ/аҕаҥ, эбэҥ/эһэҥ, бииргэ төрөөбүттэриҥ) эмиий раагынан, оһоҕос раагынан, дьахтар уорганнарын раагынан ыалдьа сылдьыбыттара дуо?',
        answer1_yes_breast_survey: 'Ыалдьан',
        answer1_no_breast_survey: 'Суох',
        determine_formation_breast_survey: 'Эмиийгин тутан көрдөххүнэ туох эмит кытаанах баар курдук дуо?',
        answer2_yes_breast_survey: 'Баар',
        answer2_no_breast_survey: 'Суох',
        have_lemon_peel_breast_survey: 'Түөһүҥ тириитигэр “лимон хаҕын курдук” учаастактаах дуо?',
        answer3_yes_breast_survey: 'Баар',
        answer3_no_breast_survey: 'Суох',
        have_nipple_discharge_breast_survey: 'Эмиийгиттэн убаҕас тахсар дуо?',
        answer4_yes_breast_survey: 'Тахсар',
        answer4_no_breast_survey: 'Суох',
        have_change_nipple_breast_survey: 'Эмиийиҥ чопчута уларыйбыт курдук буолла дуо (холобур, эмиийиҥ иһигэр киирбит курдук)?',
        answer5_yes_breast_survey: 'Уларыйда',
        answer5_no_breast_survey: 'Суох',
        when_mammogram_breast_survey: 'Бүтэһигин маммографияны хаһан ааспыккыный?',
        answer6_this_year_breast_survey: 'Быйыл',
        answer6_last_year_breast_survey: 'Былырыын',
        answer6_no_breast_survey: 'Хаһан да барбатаҕым',
        have_confirmed_malignancy_breast_survey: 'Бигэргэтиллибит раак ыарыылааххын дуо?',
        answer7_yes_breast_survey: 'Баар',
        answer7_no_breast_survey: 'Суох',
        menstruation_age_breast_survey: 'Ыйдааҕыҥ хас сааскар аан бастаан кэлбитэй?',
        had_childbirth_breast_survey: 'Төрөөбүтүҥ дуо?',
        answer9_yes_breast_survey: 'Төрөөбүтүм',
        answer9_no_breast_survey: 'Суох',
        had_temperature_breast_survey: 'Бүтэһик 10 хонук иһигэр температуураҥ 37,5 кыраадыстан үрдүктүк тахса сылдьыбыттаах дуо?',
        answer10_yes_breast_survey: 'Тахсан',
        answer10_no_breast_survey: 'Суох',
        consent_breast_survey: 'Тэрили өйдөөн хааларга',
        save_breast_survey: 'ЫЫТАРГА',

        name_intestine_survey: 'Суон уонна көнө оһоҕос ыарыыларын тургутуу',
        relatives_cancer_intestine_survey_intestine_survey: 'Аймахтаргар (ийэҕэр, аҕаҕар, эбэҕэр, эһэҕэр) оһоҕос раага баар этэ дуо?',
        answer1_yes_intestine_survey: 'Баара',
        answer1_no_intestine_survey: 'Суоҕа',
        have_constipation_intestine_survey: 'Сааҕыҥ хата-хата сыптарытар түгэннэрэ баар буолааччы дуо?',
        answer2_yes_intestine_survey: 'Баар',
        answer2_no_intestine_survey: 'Суох',
        have_blood_feces_intestine_survey: 'Сааххар хаан баарын көрөөччүгүн дуо?',
        answer3_yes_intestine_survey: 'Көрөн',
        answer3_no_intestine_survey: 'Суох',
        have_mucus_feces_intestine_survey: 'Сааххар сыраан курдук баар буолааччы дуо (элбэх)?',
        answer4_yes_intestine_survey: 'Баар',
        answer4_no_intestine_survey: 'Суох',
        have_weight_loss_intestine_survey: 'Ыйааһыныҥ эмискэ түстэ дуо?',
        answer5_yes_intestine_survey: 'Түһэн',
        answer5_no_intestine_survey: 'Суох',
        changes_poop_intestine_survey: 'Сааҕыҥ кэлин уларыйда дуо?',
        answer6_yes_intestine_survey: 'Уларыйбыт',
        answer6_no_intestine_survey: 'Суох',
        incomplete_emptying_intestine_survey: 'Саахтаан бүппүт кэннэ хайдах эрэ ситэ саахтаабатах курдук буолааччы дуо?',
        answer7_yes_intestine_survey: 'Буолааччы',
        answer7_no_intestine_survey: 'Суох',
        when_colonoscopy_intestine_survey: 'Биирдэ эмит колоноскопия ааспыккыт дуо?',
        answer8_date: 'Күнэ-дьыла',
        answer8_normal: 'Этэҥҥэ',
        answer8_no: 'Суох',
        answer8_had_polyp: 'Полип баара',
        answer8_had_adenoma: 'Аденома баара',
        have_chronic_diseases_intestine_survey: 'Суон оһоҕос ханнык эмит ыарыыта Эйиэхэ баар дуо (Крон ыарыыта, неспецифическэй язвалаах колит, суон оһоҕос раага)?',
        answer9_yes_intestine_survey: 'Баар',
        answer9_no_intestine_survey: 'Суох',
        had_temperature_intestine_survey: 'Бүтэһик 10 хонук иһигэр температуураҥ 37,5 кыраадыстан үрдүктүк тахса сылдьыбыттаах дуо?',
        answer10_yes_intestine_survey: 'Тахсан',
        answer10_no_intestine_survey: 'Суох',
        consent_intestine_survey: 'Тэрили өйдөөн хааларга',
        save_intestine_survey: 'ЫЫТАРГА',

        sex_profile: 'Эр киһитэ-дьахтара',
        woman_profile: 'Дьахтар',
        man_profile: 'Эр киһитэ',
        birthdate_profile: 'Төрөөбүт күнүн-дьылын *',
        snils_profile: 'Снилс *',
        polis_profile: 'Полис ОМС *',
        foundation_profile: 'Тэрилтэ талыы *',
        save_profile: 'Сохранить',
        check_intestine_survey_go: 'Чинчийииҕэ көһүү',
        intestine_survey_go: 'Суон уонна көнө оһоҕос ыарыыларын тургутуу ',
        lung_survey_go: 'Тыҥа ыарыыларын тургутуу ',
        prostate_survey_go: 'Самах былчархайын (простата) ыарыыларын тургутуу ',
        cervix_survey_go: 'Киэли хапчаҕайын (шейка матки) ыарыыларын тургутуу',
        breast_survey_go: 'Эмиий ыарыыларын тургутуу ',
        climatic_survey_go: 'Быар ыарыыларын тургутуу (быар сыстыганнаах ыарыылара)',
        back: 'Тахсыы',
        results: 'Түмүктэр',
        schudule: 'Араспысаанньа',

        copyright_profile: 'Саха сиринээҕи Онкология диспансера',
        usefulLinks: 'Туһалаах сыылкаларr',
        partners: 'төрүк кыттыгастара',

        health: 'Доруобуйаҕын харыстаа!',
        health_desacription: '“Регистрация - Тургутуу  - Көрүү”',

        result_survey_modal: 'Бу тест 100% чахчыны көрдөрбөт. Ол эрээри, рагынан төрүт ыалдьыбаккын диэн буолбатах. Онон, сылын аайы бэрэбиэркэтэ ааһа сылдьыаххын наада.',
        result_survey_modal2: 'Бу тест 100% чахчыны көрдөрбөт. Ол эрээри, рагынан төрүт ыалдьыбаккын диэн буолбатах. Онон, сылын аайы бэрэбиэркэтэ ааһа сылдьыаххын наада.',
        modal_surveys: 'Тест түмүгэ барыта көстөрө кыаллыбат. ВОЗ уонна Арассыыйа онкологтарын ассоциациятын рекомендациятынан эрэ тахсыбыт түмүктэр. Эн сааһыҥ уонна эр киһи/дьахтар диэн категория учуоттанна.',
        enter: 'Кирии',
        forgotButtonText: 'Аһарыгы сөргүтүү',

        climatic_period: '',
        Infertility: '',
        endometriosis: ''
    }
}