import React from 'react'

import { MyContainer, MyText } from '../../../components'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/system'

import themeMain from '../../../theme'

const GridImg = styled(Grid)({
    background: '#ea5858',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
})

const GridRoot = styled(Grid)(({ theme }) => ({
    boxShadow: '1px 2px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: 10,
    padding: 20,
    [theme.breakpoints.down('md')]: {
        padding: 5
    },
}))

const GridText = styled(Grid)(({ theme }) => ({
    padding: 20,
    [theme.breakpoints.down('sm')]: {
        padding: 5
    },
}))

const Span = styled('span')({
    marginLeft: 20,
    color: '#245852'
})

const TwoScreen = () => {
    const data = [
        {
            label: 'ГАУ РС (Я) «Поликлиника №1»',
            spans: ['Единый контакт-центр: 122 (доб.1)']
        },
        {
            label: 'ГАУ РС(Я) «Медицинский центр г. Якутска»',
            spans: ['Единый контакт-центр: 122 (доб. 4)']
        },
        {
            label: 'ГАУ РС(Я) «Якутская городская больница №3»',
            spans: ['Единый контакт-центр: 122 (доб. 0)']
        },
        {
            label: 'ГБУ РС(Я) «Якутская городская больница №2»',
            spans: ['Единый контакт-центр: 122 (доб.2)']
        },
        {
            label: 'AНО «Белая Роза-Саха» контакт-центр',
            spans: ['Единый контакт-центр: 122 (доб.2)']
        },
    ]
    return (
        <MyContainer wrapper={false} sx={{ mt: 5 }} minHeight={500}>
            <GridRoot container>
                <GridImg item lg={5} xl={5} md={5} sm={12} xs={12}>
                    <img src={'/img/Element/men.png'} style={{ width: '150%' }} />
                </GridImg>
                <GridText item lg={7} xl={7} md={7} sm={12} xs={12}>
                    <MyText variant="h6" sm={16} sx={{ fontWeight: 'normal' }}>Единый контакт центр медицинских организаций:<Span>122</Span></MyText>
                    {data.map((item, index) => (
                        <Box key={index}>
                            <MyText variant="h6" sm={16} sx={{ fontWeight: 'normal', mt: 2 }} >{item.label}</MyText>
                            <Box>
                                {item.spans.map((item, index) => (
                                    <MyText variant="body2" key={index}>
                                        {item}
                                    </MyText>
                                ))}
                            </Box>
                        </Box>
                    ))}
                </GridText>
            </GridRoot>
        </MyContainer>
    )
}

export default TwoScreen