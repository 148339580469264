import React, { useState } from 'react'

import { InfoScreen, TextInfoScreen, ThreeScreen, FourScreen, LinkToSurveysScreen, MyContainer, MyButton } from '../../../components'

import { CaruselSurvey, TitleScreen } from '../../../constructor'
import { FormattedMessage } from "react-intl";
import { useNavigate } from 'react-router-dom';
import cookie from 'js-cookie'

const Gut = () => {
    const navigate = useNavigate()
    const data1 = [
        // {
        //     type: 'text',
        //     elem: [
        //         'РАК МОЛОЧНОЙ ЖЕЛЕЗЫ (далее - РМЖ) - развивается из железистой ткани молочной железы. У 3-10% больных раком молочной железы развитие заболевания связано с наличием мутаций в генах (BRCA1, BRCA2, CHEK, NBS1, tP53). У остальных пациентов РМЖ имеет случайный характер. Заболевание является ведущей онкологической патологией у женского населения России, составляя 17,5% от всех злокачественных новообразований у женщин.',
        //         <FormattedMessage id="breast_data1_text2" />,
        //         <FormattedMessage id="breast_data1_title_column1" />
        //     ],
        // },
        // {
        //     type: 'text',
        //     elem: [
        //         '',
        //         <FormattedMessage id="breast_data1_text2" />,
        //         <FormattedMessage id="breast_data1_title_column1" />
        //     ],
        // },
        {
            title: 'Факторы риска',
            type: 'column',
            elem: [
                'раннее начало менструации;',
                'поздняя менопауза;',
                'отсутствие родов;',
                'наличие абортов;',
                'курение;',
                'алкоголь;',
                'низкая физическая активность;',
                'наличие сахарного диабета;',
                'ожирение;',
            ],
        },
        {
            title: 'К клиническим симптомам ранних форм РМЖ относятся:',
            type: 'text',
            elem: [
                'наличие опухолевого узла в ткани молочной железы;',
                'плотная консистенция опухоли (опухоль может быть деревянистой или даже каменистой плотности);',
                'ограниченная подвижность опухоли, либо полное её отсутствие;',
                'безболезненность опухоли;',
                'наличие втяжения кожи над опухолью;',
                'наличие лимфатического узла в подмышечной области;',
                'кровянистые выделения из соска.'
            ],
        },
        {
            title: 'К симптомам, характерным для более распространенных форм опухоли, относятся:',
            type: 'text',
            elem: [
                'заметная деформация кожи молочной железы над определяемой опухолью (особенно при осмотре с поднятыми вверх руками);',
                'прорастание кожи опухолью или изъязвление опухоли;',
                'ограниченная подвижность опухоли, либо полное её отсутствие;',
                'безболезненность опухоли;',
                'наличие втяжения кожи над опухолью;',
                'наличие лимфатического узла в подмышечной области;',
                'кровянистые выделения из соска.',
                'наличие лимфатического узла в подмышечной области на стороне опухоли; кровянистые выделения из соска.'
            ],
        },
        // {
        //     type: 'column',
        //     elem: [
        //         <FormattedMessage id="breast_data1_text1_column2" />,
        //         <FormattedMessage id="breast_data1_text2_column2" />,
        //         <FormattedMessage id="breast_data1_text3_column2" />,
        //         <FormattedMessage id="breast_data1_text4_column2" />,
        //         'безболезненность опухоли',
        //         'наличие втяжения кожи над опухолью',
        //         'кровянистые выделения из соска'
        //     ],
        // },
        // {
        //     type: 'text',
        //     elem: [
        //         <FormattedMessage id="breast_data1_title_column3" />,
        //     ],
        // },
        // {
        //     type: 'column',
        //     elem: [
        //         <FormattedMessage id="breast_data1_text1_column3" />,
        //         <FormattedMessage id="breast_data1_text2_column3" />,
        //         <FormattedMessage id="breast_data1_text3_column3" />,
        //         <FormattedMessage id="breast_data1_text4_column3" />,
        //         'безболезненность опухоли',
        //         'наличие втяжение кожи над опухолью',
        //         'кровянистые выделения из соска'
        //     ],
        // },
    ]

    const data2 = [
        {
            type: 'text',
            elem: [
                // <FormattedMessage id="breast_data2_text1_column1" />,
                // <FormattedMessage id="breast_data2_text2_column1" />,
                'Для диагностики РМЖ прежде всего используют метод маммографии. Маммография показана женщинам старше 35 лет. До 35 лет женщина должна проходить ультразвуковое исследование. Внедрение маммографического скрининга позволяет снизить смертность от РМЖ в среднем на 30% за счет выявления заболевания на ранних стадиях. Эффективность зависит от количества обследованных женщин. Скрининг считается эффективным, если им охвачено 70% женского населения. Выполнение большого количества маммографических исследований в течение года затруднительно. Поэтому оптимальной системой скрининга является работа с группами повышенного риска на постоянной основе.'
            ],
        },
    ]

    return (
        <div>
            <InfoScreen
                title={<FormattedMessage id="breast_cancer" />}
                description1='РАК МОЛОЧНОЙ ЖЕЛЕЗЫ (далее - РМЖ) - развивается из железистой ткани молочной железы. 
                У 3-10% больных раком молочной железы развитие заболевания связано с наличием мутаций в генах (BRCA1, BRCA2, CHEK, NBS1, tP53). У остальных пациентов РМЖ имеет случайный характер.
                Заболевание является ведущей онкологической патологией у женского населения России, составляя 17,5% от всех злокачественных новообразований у женщин. 
                Средний возраст больных РМЖ с впервые в жизни установленным диагнозом злокачественного новообразования в России в 2020 г. – 60-64 года. 
                В Республике Саха (Якутия) в 2021 году было выявлено 229 случая РМЖ. При этом на I-II стадии процесса было диагностировано 68,7% заболеваний, на III стадии – 22,5% и на IV стадии – 8,8%.'
                img="breastphoto"
                descriptionSize='h6'
            />
            <TextInfoScreen data={data1} />
            <ThreeScreen data={data2} showInfoText={true} />
            <FourScreen />
            <LinkToSurveysScreen link="" />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MyButton sx={{ bgcolor: '#EB5757', mb: 2 }}
                    onClick={() => {
                        navigate(cookie.get('jwttoken') ? '/surveys' : '/login')
                    }}
                >
                    <FormattedMessage id="view_surveys_lung_cancer" />
                </MyButton>
            </div>
            <MyContainer
                wrapper={false}
                minHeight={600}
            >
                <CaruselSurvey />
            </MyContainer>
        </div>
    )
}

export default Gut