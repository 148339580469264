import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Box, Grid, CircularProgress, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";

import {
    MyContainer,
    MenuProfile,
    ResearchCard,
    MyText,
} from "../../../components";
import Api from "../../../utils/api";
import themeMain from "../../../theme";

const BoxWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 20,
    },
}));

const Root = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    width: "70%",
    margin: "20px 0px",
    marginTop: 80,
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: 0,
    },
}));

const MySchedule = () => {
    const [schedules, setSchedules] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const fetchSchedules = async () => {
        setLoading(true);
        await Api.my_schedule().then((res) => {
            const result = res.data;
            setSchedules(result);
            console.log(res);
        });
        setLoading(false);
    };
    useEffect(() => {
        fetchSchedules();
    }, []);

    console.log("schedules", schedules);

    return (
        <MyContainer wrapper={false}>
            <BoxWrapper>
                <MenuProfile />
                <Root>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <MyText
                            variant="h5"
                            sx={{
                                color: themeMain.palette.primary.main,
                            }}
                        >
                            Мои расписания
                        </MyText>
                    </Box>
                    {loading ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                width: "100%",
                                marginTop: 3,
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Grid container>
                            {schedules.length ? (
                                schedules.map((item: any, index) => (
                                    <Grid
                                        item
                                        lg={4}
                                        xl={4}
                                        md={6}
                                        sm={6}
                                        xs={12}
                                        key={index}
                                    >
                                        <ResearchCard
                                            schedule={item}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <MyText
                                    variant="h6"
                                    sx={{ margin: "0 auto", mt: 5 }}
                                >
                                    В соответствии с Вашим полом и
                                    возрастом, доступных для Вас анкет
                                    - нет.
                                </MyText>
                            )}
                        </Grid>
                    )}
                </Root>
            </BoxWrapper>
        </MyContainer>
    );
};

export default MySchedule;
