import React, { useState, useContext } from 'react'
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Modal,
    Typography,
    Checkbox,
    FormControlLabel,
    CardActions,
    Button
} from "@mui/material";
import { styled } from '@mui/styles'
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom'

import Api from '../../utils/api'
import { DispatchContext, StateContext } from '../../store'

interface ResearchModal {
    showModal: any,
    handleCloseModal: any
}

const ModalWindow = styled(Card)(({ theme }) => ({
    backgroundColor: 'white',
    maxWidth: 350,
    margin: 'auto',
    borderRadius: 10,
    '&:focus': {
        outline: 'none'
    }
}))

const ModalActions = styled(CardActions)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
}))

const ResearchModal: React.FC<ResearchModal> = ({ showModal, handleCloseModal }) => {
    const [licenseCheck, setLicenseCheck] = useState<boolean>(false)

    const dispatch = useContext(DispatchContext)
    const state = useContext(StateContext)
    const navigate = useNavigate()

    const createAppointment = async () => {
        await Api.sendAppointment(state.scheduleModal.id, dispatch, navigate)
    }

    // const handleCloseConsent = () => {
    //     setShowConsent(false)
    // }

    // const handleOpenConsent = () => {
    //     setShowConsent(true)
    // }

    const handleAccept = () => {
        createAppointment()
        handleCloseModal()
    }

    const d = new Date(state.scheduleModal.datetime)
    return (
        <Modal
            open={showModal}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{ display: "flex", alignItems: 'center' }}
        >
            <ModalWindow>
                <CardContent>
                    <Typography paragraph variant={'h5'} align={'center'}>Запись к врачу</Typography>
                    <Typography >{state.scheduleModal.name}</Typography>
                    <Box mt={1}>
                        <Typography ><b>Дата:</b> {moment.tz(d, '').format('YYYY-MM-DD')}
                        </Typography>
                        <Typography
                        ><b>Время</b>: {moment.tz(d, '').format('HH:mm')}
                        </Typography>
                    </Box>
                    <Box mt={1} display={'flex'} flexDirection={"row"} flexWrap={'wrap'} alignItems={'center'}>
                        <FormControlLabel
                            value="top"
                            control={<Checkbox value={licenseCheck} onChange={e => setLicenseCheck(!licenseCheck)} color="primary" />}
                            label={<Typography>Принимаю условия </Typography>}
                            labelPlacement="end"
                        />
                        <Typography color={"primary"} style={{ cursor: "pointer" }}
                        >соглашения</Typography>
                    </Box>
                </CardContent>
                <ModalActions>
                    <Button color={'secondary'} onClick={handleCloseModal}>Закрыть</Button>
                    <Button color={'primary'} disabled={!licenseCheck} onClick={handleAccept}>Принять</Button>
                </ModalActions>
            </ModalWindow>
        </Modal>
    )
}

export default ResearchModal