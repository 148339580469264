import React, { useState } from 'react'

import { InfoScreen, TextInfoScreen, ThreeScreen, FourScreen, LinkToSurveysScreen, MyContainer, MyButton } from '../../../components'
import cookie from 'js-cookie'
import { useNavigate } from 'react-router-dom'

import { CaruselSurvey, TitleScreen } from '../../../constructor'
import { FormattedMessage } from "react-intl";

const Gut = () => {
    const navigate = useNavigate()
    const [state, setState] = useState(false)
    const data1 = [
        // {
        //     title: '',
        //     type: 'text',
        //     elem: [
        //         'Как показывает статистика бесплодия, сегодня в России 17% супружеских пар не имеют детей. По данным ВОЗ процент бесплодия составляет 15% и более – угроза для демографической ситуации страны. В России ежегодно проводится более 100 тысяч циклов ЭКО, более половины из них – по полисам ОМС. Эффективность ЭКО в лаборатории ОВРТ ГАУ РС (Я) «РБ№1-НЦМ» на сегодняшний день составляет 30–35%, что является хорошим показателем.',
        //         'Важно помнить, что в области репродукции время и возраст играют против женщины. Если возраст до 30 лет, то эффективность программы ЭКО приближается к 60%, в возрасте 40 и старше — это 10–15%. Связано это с тем, что у женщины, к сожалению, репродуктивный период недолгий, и с возрастом уменьшается не только количество яйцеклеток, но и страдает их качество. Поэтому эффективность ЭКО в старшем репродуктивном возрасте невысока. Вот почему, планируя беременность, стоит как можно раньше обратиться к квалифицированным специалистам.',

        //     ],
        // },
        {
            title: 'Виды бесплодия',
            type: 'text',
            elem: [
                'Первичное, при котором женщина не имела ни одной беременности в жизни.',
                'Вторичное — если у женщины беременность не наступает, хотя ранее это происходило (вне зависимости от того, чем она закончилась: родами, выкидышем или абортом).',
            ],
        },
        {
            title: 'Основные причины бесплодия.',
            type: 'text',
            elem: [
                '1.	Трубное бесплодие характеризуется непроходимостью маточных труб в ходе воспалительных процессов или их отсутствии после перенесенных операций.',
                '2.	Эндокринное бесплодие может быть следствием патологии яичников (их истощения, синдрома поликистозных яичников и др.) и других эндокринных  органов. ',
                // 'Время до зачатия увеличивается в 2 раза при ИМТ (индексе массы тела) больше 35 и в 4 раза при ИМТ меньше 18, (ИМТ = вес/рост в кв.м).',
                '3.	Маточное бесплодие - патология или отсутствие матки — органа, в котором происходит прикрепление эмбриона и вынашивание плода.',
                '4. Бесплодие при эндометриозе — состояние при котором эндометрий обнаруживается в  других органах.  Причинами бесплодия при эндометриозе является: спаечный процесс, хронический воспалительный процесс, нарушение овуляции, недостаточность лютеиновой фазы цикла.',
                '5.	Шеечный фактор бесплодия возникает при аномалии и рубцовых изменениях шейки матки.',
                '6.	«Бесплодие неясного генеза» (идиопатическое бесплодие) устанавливают, когда при всестороннем обследовании обоих партнёров не выявлено никаких причин для бесплодия.'
            ],
        },
        {
            title: 'Что влияет на способность зачатия:',
            type: 'text',
            elem: [
                'Способность к зачатию снижается почти в 2 раза у женщин в возрасте после 30 лет по сравнению с женщинами 20 лет,  значительно уменьшается после 35 лет.',
                // 'Время до зачатия увеличивается в 2 раза при ИМТ (индексе массы тела) больше 35 и в 4 раза при ИМТ меньше 18, (ИМТ = вес/рост в кв.м).',
                'Вероятность зачатия максимальна при частоте половых актов 3-4 раза в неделю.',
                'Курение оказывает существенное влияние на возможность зачатия, увеличивая риск бесплодия в 1,6 раз.',
                'Потребление алкоголя более 20 г. этанола в день увеличивает риск бесплодия на 60%.',
                'Высокий уровень потребления кофеина (500 мг или более 5 чашек в день) снижает шансы наступления беременности в 1,45 раз.'
            ],
        },

        // {
        //     title: 'Причина бесплодия:',
        //     type: 'column',
        //     elem: [
        //         '1.	Трубно-перитонеальное бесплодие характеризуется непроходимостью маточных труб в ходе воспалительных процессов или атрофии ресничек, обеспечивающих продвижении яйцеклетки, внутри труб. Абсолютное трубное бесплодие возникает после хирургического удаления обеих маточных труб или после проведенной стерилизации маточных труб с целью контрацепции.',
        //         '2.	Эндокринное бесплодие может быть следствием патологии яичников (их истощения, синдрома поликистозных яичников и др.) и других эндокринных и неэндокринных органов (печени, почек и др). К эндокринному бесплодию могут приводить нарушения обменных процессов, психический стресс и пр. Чем бы ни было вызвано эндокринное бесплодие, его ключевым моментом всегда является поломка механизма овуляции (ановуляция).',
        //         '3.	Маточное бесплодие - патология или отсутствие матки — органа, в котором происходит прикрепление эмбриона и вынашивание плода. Патология матки может быть врождённой (внутриматочная перегородка, двурогая матка, удвоение матки и др.) и приобретённой (удаление или рубцы на матке после операций, миома матки, эндометрит, полипоз, гиперплазия эндометрия и др.).',
        //         '4.	Бесплодие при эндометриозе — состояние при котором эндометрий (ткань, выстилающая полость матки) обнаруживается в яичниках, маточных трубах, на внешней поверхности матки и в других частях организма.',
        //         '5.	Шеечный фактор бесплодия возникает при аномалии и рубцовых изменениях шейки матки, а также при патологических состояниях, при которых цервикальная слизь изменяется настолько, что отсутствуют условия для беспрепятственного продвижения сперматозоидов в полость матки и далее в маточные трубы.',
        //         '6.	«Бесплодие неясного генеза» (идиопатическое бесплодие) устанавливают, когда при всестороннем обследовании обоих партнёров не выявлено никаких причин для бесплодия. Оба партнёра по заключению здоровы, но беременность не наступает.',
        //     ],
        // },
        {
            title: 'Когда требуется консультация генетиков.',
            type: 'column',
            elem: [
                'при бесплодии неясного генеза;',
                'привычном выкидыше;',
                'повторных неудачных попытках переноса нативных или размороженных эмбрионов (3-х попытках - у женщин моложе 35 лет, 2-х попытках - у женщин 35 лет и старше);',
                'тяжелых нарушениях сперматогенеза у мужчин.',
                'Факторами риска рождения ребенка с хромосомной или генной патологией являются:',
                'наличие хотя бы у одного из партнеров хромосомных или генных нарушений; ',
                'наличие хотя бы у одного из партнеров детей с хромосомными или генными нарушениями, врожденными пороками развития, умственной отсталостью;',
                'кровнородственный брак.'
            ],
        },
        {
            title: 'В каких случаях проводится экстракорпоральное оплодотворение (ЭКО). ',
            type: 'text',
            elem: [
                '-	абсолютное женское бесплодии (обе маточные трубы удалены или была проведена хирургическая стерилизация маточных труб); ',
                '-	тяжелый фактор мужского бесплодия, когда сперматозоиды получают при проведении биопсии яичек; ',
                '-	при любом виде бесплодия в случае неэффективности лечения в течение 6-12 месяцев;',
                '-	поздний репродуктивный возраст женщины (старше 36 лет) может стать показанием для более быстрого направления на ЭКО;',
                '-	наследственные заболевания, при которых необходимо провести генетическое исследование эмбрионов с целью выбора здоровых;',
                '-	ВИЧ-инфекция у дискордантных партнеров.',
            ],
        },
        {
            title: 'Виды лечения бесплодия',
            type: 'text',
            elem: [
                'Тактика ведения и лечение бесплодия зависит от многих факторов, основные - это причины бесплодия и возраст женщины. В зависимости от причины бесплодия, назначается следующее лечение',
            ],
        },
        {
            title: '',
            type: 'column',
            elem: [
                'при трубно-перитонеальном факторе бесплодия, генитальном эндометриозе, миоме матки больших размеров и др.  - лапароскопическая операция;',
                'при патологии эндометрия (полипы, гиперплазия, хронический эндометрит, спайки в полости матки) - гистероскопия;',
                'при мужском факторе бесплодия муж/партнер направляется на лечение к урологу. При тяжелых формах (отсутствие сперматозоидов в эякуляте) - проводится биопсия яичек ;',
                'при эндокринных причинах бесплодия -  гормональная терапия; ',
                'при незначительных изменениях спермограммы и проходимых маточных трубах могут порекомендовать процедуру искусственной инсеминации (ИИ) спермой мужа/партнера; ',
                'в случае отсутствия полового партнера, при проходимых маточных трубах  проводится искусственная инсеминация спермой донора.'
            ],
        },
    ]

    // const data2 = [
    //     {
    //         title: 'К основным методам диагностики климактерического периода относятся: ',
    //         type: 'column',
    //         elem: [
    //             'Рекомендуется проводить гормональное обследование  особенно, в случае неясного менопаузального статуса (уровень ФСГ в крови на 2-4 день менструального цикла;уровень эстрадиола в крови; уровень ТТГ в крови для дифференциальной диагностики заболеваний щитовидной железы; уровень пролактина в крови для дифференциальной диагностики нарушений менструального цикла).',
    //             'Биохимическое исследование показателей анализа крови и свертывающих факторов (коагулограмма).',
    //             'Рекомендуется при наличии переломов в анамнезе/ наличии факторов риска остеопороза проведение денситометрии (двухэнергетическая рентгеновская абсорбциометрия (ДЭРА) поясничного отдела позвоночника и шейки бедра ',
    //             'УЗИ органов малого таза;',
    //             'УЗИ молочных желез и маммографию;',
    //             'Цитологический анализ мазков из шейки матки и цервикального канала;'
    //         ],
    //     },
    // ]

    return (
        <div>
            <InfoScreen
                title='БЕСПЛОДИЕ'
                description1='Бесплодие – заболевание, которое характеризуется невозможностью достичь беременности после 12 месяцев регулярной половой жизни без предохранения. '
                description2='Лечение по поводу бесплодия может быть начато и ранее 1 года, в зависимости от возраста супругов, наличия в прошлом перенесенных операций на органах репродуктивной системы, особенно, на яичниках и данных результатов обследования.'
                img="cervix2"
                descriptionSize='h6'
            />
            <TextInfoScreen data={data1} />
            {/* <ThreeScreen data={data2} showInfoText={true} /> */}
            <LinkToSurveysScreen link="" />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MyButton sx={{ bgcolor: '#EB5757', mb: 2 }}
                    onClick={() => {
                        navigate(cookie.get('jwttoken') ? '/surveys' : '/login')
                    }}
                >
                    <FormattedMessage id="view_surveys_lung_cancer" />
                </MyButton>
            </div>
            <MyContainer
                wrapper={false}
                minHeight={600}
            >
                <CaruselSurvey />
            </MyContainer>
        </div>
    )
}

export default Gut