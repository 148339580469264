import axios from "axios";
import cookie from "js-cookie";

// const testURL = 'http://127.0.0.1:8000/'
const publicURL =
    "https://xn-----8kcahlfadeo7a1bpea7akm5f8g.xn--p1ai/";
// const publicURL = "/";

const api = (url) => {
    const token = cookie.get("jwttoken");
    if (token) {
        const instance = axios.create({
            baseURL: publicURL + url,
            headers: {
                Authorization: "Token " + token,
                "Content-Type": "application/json",
            },
        });
        return instance;
    } else {
        const instance = axios.create({
            baseURL: publicURL + url,
            headers: {
                "Content-Type": "application/json",
            },
        });
        return instance;
    }
};

class API {
    getToken({ username, password }, dispatch, navigate) {
        api("api/v1/users/login")
            .post(null, {
                username: username,
                password: password,
            })
            .then((res) => {
                cookie.set("jwttoken", res.data.token);
                dispatch({
                    type: "notification",
                    payload: {
                        status: "success",
                        active: true,
                        text: "авторизация прошла успешно",
                    },
                });
                navigate("/form-profile");
                console.log(res);
            })
            .catch(() => {
                dispatch({
                    type: "notification",
                    payload: {
                        status: "error",
                        active: true,
                        text: "такого пользователя не существует",
                    },
                });
            });
    }
    register(data, dispatch, navigate) {
        api("api/v1/users/registration")
            .post(null, data)
            .then((res) => {
                navigate("/login");
                dispatch({
                    type: "notification",
                    payload: {
                        status: "success",
                        active: true,
                        text: "регистрация прошла успешно",
                    },
                });
            })
            .catch((error) =>
                dispatch({
                    type: "notification",
                    payload: {
                        status: "error",
                        active: true,
                        text: "такой пользователь уже существует",
                    },
                })
            );
    }
    forgotPassword(data, dispatch, navigate) {
        api("api/v1/users/refresh-password")
            .post(null, data)
            .then((res) => {
                dispatch({
                    type: "notification",
                    payload: {
                        status: "success",
                        active: true,
                        text: "В ближайшее время на ваш телефон придет смс сообщение с новым паролем.",
                    },
                });
                navigate("/login");
            })
            .catch((error) =>
                dispatch({
                    type: "notification",
                    payload: {
                        status: "error",
                        active: true,
                        text: " Пароль не был восстановлен. Указанный номер телефона не зарегистрирован. Пожалуйста проверьте верно ли указан номер.",
                    },
                })
            );
    }
    async getCity() {
        let result = await api(`api/locations/city/`).get(null);
        return result;
    }
    async getOrg() {
        let result = await api(`api/v1/locations/organisations`).get(
            null
        );
        return result;
    }
    async getSurveysTypes() {
        let result = await api(`api/v1/researches/`).get(null);
        return result;
    }
    async getAccountUser() {
        let result = await api(`api/v1/users/me`).get(null);
        return result;
    }
    putAccountUser(data, dispatch) {
        api("api/v1/users/me")
            .put(null, data)
            .then((res) => {
                dispatch({
                    type: "notification",
                    payload: {
                        status: "success",
                        active: true,
                        text: "профиль изменен",
                    },
                });
            })
            .catch(() => {
                dispatch({
                    type: "notification",
                    payload: {
                        status: "error",
                        active: true,
                        text: "профиль не изменен",
                    },
                });
            });
    }
    sendSurveys(data, dispatch, name) {
        api("api/v1/researches/results")
            .post(null, data)
            .then((res) => {
                switch (name) {
                    case "endometrios":
                        switch (res.data.status) {
                            case "WARNING":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Возможно начальная стадия эндометриоза. Необходима консультация врача.",
                                    },
                                });
                                break;
                            case "DANGER":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Высокий риск эндометриоза. Срочно требуется консультация и осмотр врача.",
                                        status: res.data.status,
                                    },
                                });
                                break;
                            case "NORMAL":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Низкий риск эндометриоза.",
                                    },
                                });
                        }
                        break;
                    case "climatic_period":
                        switch (res.data.status) {
                            case "NORMAL":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Низкий риск климактерического синдрома",
                                    },
                                });
                                break;
                            case "WARNING":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Средний риск климактерического синдрома",
                                    },
                                });
                                break;
                            case "DANGER":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Высокий риск климактерического синдрома. Даже при слабом климактерическом синдроме вам необходимо обратиться за консультацией к врачу – гинекологу.",
                                        status: res.data.status,
                                    },
                                });
                        }
                        break;
                    case "infertility":
                        switch (res.data.status) {
                            case "NORMAL":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Продолжить попытки зачатия естественным путем. Возможно вам необходимо модифицировать образ жизни и/или увеличить регулярность половых контактов.",
                                    },
                                });
                                break;
                            case "DANGER":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Вам необходимо обратиться за консультацией к врачу – гинекологу.",
                                        status: res.data.status,
                                    },
                                });
                        }
                        break;
                    case "cervix":
                        switch (res.data.status) {
                            case "NORMAL":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "На сегодня Вам не показано прохождение скрининга, необходимо обратиться к участковому терапевту по месту жительства.",
                                    },
                                });
                                break;
                            case "WARNING":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Тест не является на 100% точным Низкий риск означает, что вероятность развития рака мала, но не равна нулю. Необходимо ежегодно проходить медицинский осмотр.",
                                    },
                                });
                                break;
                            case "DANGER":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Группа высокого риска, это может означать, что у Вас имеются ряд факторов риска, которые могут вызвать развитие рака. Поэтому необходимо пройти обследование.",
                                        status: res.data.status,
                                    },
                                });
                        }
                        break;
                    case "breast":
                        switch (res.data.status) {
                            case "NORMAL":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "На сегодня Вам не показано прохождение скрининга, необходимо обратиться к участковому терапевту по месту жительства.",
                                    },
                                });
                                break;
                            case "WARNING":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Тест не является на 100% точным Низкий риск означает, что вероятность развития рака мала, но не равна нулю. Необходимо ежегодно проходить медицинский осмотр.",
                                    },
                                });
                                break;
                            case "DANGER":
                                dispatch({
                                    type: "modalSurvey",
                                    payload: {
                                        open: true,
                                        description:
                                            "Группа высокого риска, это может означать, что у Вас имеются ряд факторов риска, которые могут вызвать развитие рака. Поэтому необходимо пройти обследование.",
                                        status: res.data.status,
                                    },
                                });
                        }
                }
            })
            .catch(() => {
                dispatch({
                    type: "notification",
                    payload: {
                        status: "error",
                        active: true,
                        text: "У вас ошибка в форме анкеты",
                    },
                });
            });
    }
    async schedule(date, id) {
        let result = await api(
            `api/v1/schedules/receptions?date=${date}&research=${id}`
        ).get(null);
        return result;
    }
    sendAppointment(id, dispatch, navigate) {
        api(`api/v1/schedules/receptions/${id}`)
            .get(null)
            .then((res) => {
                navigate("/schedule-allowed");
                dispatch({
                    type: "notification",
                    payload: {
                        status: "success",
                        active: true,
                        text: "Успешно! Вы записаны на прием!",
                    },
                });
            })
            .catch((error) => {
                dispatch({
                    type: "notification",
                    payload: {
                        status: "error",
                        active: true,
                        text: "Запись на выбраное время невозможна!",
                    },
                });
            });
    }
    feedback(data, dispatch) {
        api("api/feedback")
            .post(null, data)
            .then((res) => {
                dispatch({
                    type: "notification",
                    payload: {
                        status: "success",
                        active: true,
                        text: "ваше сообщение отправлено",
                    },
                });
                console.log(res);
            })
            .catch((error) => console.log(error));
    }
    async getResults() {
        let result = await api("api/v1/results/files").get(null);
        return result;
    }
    async schedule_allowed() {
        let result = await api(
            `api/v1/schedules/receptions/allowed-researches`
        ).get(null);
        return result;
    }
    async my_schedule() {
        let result = await api(`api/v1/schedules/receptions/my`).get(
            null
        );
        return result;
    }
}

export default new API();
