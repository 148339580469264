import React, { useReducer, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Route, BrowserRouter, Routes } from "react-router-dom";
import {
    DispatchContext,
    StateContext,
    LanguageContext,
    defaultStore,
} from "./store";
import { stateReducer } from "./reducer";

import HomePage from "./pages/home";
import AboutPage from "./pages/about";
import Faq from "./pages/faq";
import Contacts from "./pages/contacts";

import Breast from "./pages/surveysInfo/breast";
import Endometriosis from "./pages/surveysInfo/endometriosis";
import ClimaticPeriod from "./pages/surveysInfo/climatic_period";
import Infertility from "./pages/surveysInfo/Infertility";
import Cervix from "./pages/surveysInfo/cervix";

import BreastForm from "./pages/profile/surveys/detail/breast";
import ClimaticPeriodForm from "./pages/profile/surveys/detail/climatic_period";
import CervixForm from "./pages/profile/surveys/detail/cervix";
import EndometriosisForm from "./pages/profile/surveys/detail/endometriosis";
import InfertilityForm from "./pages/profile/surveys/detail/Infertility";

import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import ForgotPassword from "./pages/auth/forgotPassword";

import FormProfile from "./pages/profile/formProfile";
import Schedule from "./pages/profile/schedule";
import Results from "./pages/profile/results";
import Surveys from "./pages/profile/surveys";
import ScheduleAllowed from "./pages/profile/schedule-allowed";
import MySchedule from "./pages/profile/my-schedule";

import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import { Layout } from "./components";
import { IntlProvider } from "react-intl";

const App = () => {
    const [state, dispatch] = useReducer(stateReducer, defaultStore);
    const [currentLocale, setCurrentLocale] = useState(
        LOCALES.RUSSIAN
    );

    const changeLocale = (localeCode: string) => {
        setCurrentLocale(localeCode);
    };

    return (
        <IntlProvider
            messages={messages[currentLocale]}
            defaultLocale={currentLocale}
            locale={LOCALES.RUSSIAN}
        >
            <LanguageContext.Provider
                value={{ currentLocale, changeLocale }}
            >
                <DispatchContext.Provider value={dispatch}>
                    <StateContext.Provider value={state}>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    <Route
                                        index
                                        element={<HomePage />}
                                    />
                                    <Route
                                        path="about"
                                        element={<AboutPage />}
                                    />
                                    <Route
                                        path="faq"
                                        element={<Faq />}
                                    />
                                    <Route
                                        path="contacts"
                                        element={<Contacts />}
                                    />

                                    <Route
                                        path="breast"
                                        element={<Breast />}
                                    />
                                    <Route
                                        path="endometriosis"
                                        element={<Endometriosis />}
                                    />
                                    <Route
                                        path="Infertility"
                                        element={<Infertility />}
                                    />
                                    <Route
                                        path="climatic-period"
                                        element={<ClimaticPeriod />}
                                    />
                                    <Route
                                        path="cervix"
                                        element={<Cervix />}
                                    />

                                    <Route
                                        path="surveys/breast-survey"
                                        element={<BreastForm />}
                                    />
                                    <Route
                                        path="surveys/climatic-period-survey"
                                        element={
                                            <ClimaticPeriodForm />
                                        }
                                    />
                                    <Route
                                        path="surveys/endometriosis-survey"
                                        element={
                                            <EndometriosisForm />
                                        }
                                    />
                                    <Route
                                        path="surveys/cervix-survey"
                                        element={<CervixForm />}
                                    />
                                    <Route
                                        path="surveys/infertility-survey"
                                        element={<InfertilityForm />}
                                    />

                                    <Route
                                        path="login"
                                        element={<Login />}
                                    />
                                    <Route
                                        path="register"
                                        element={<Register />}
                                    />
                                    <Route
                                        path="forgot"
                                        element={<ForgotPassword />}
                                    />

                                    <Route
                                        path="form-profile"
                                        element={<FormProfile />}
                                    />
                                    <Route
                                        path="schedule"
                                        element={<Schedule />}
                                    />
                                    <Route
                                        path="results"
                                        element={<Results />}
                                    />
                                    <Route
                                        path="surveys"
                                        element={<Surveys />}
                                    />
                                    <Route
                                        path="schedule-allowed"
                                        element={<ScheduleAllowed />}
                                    />
                                    <Route
                                        path="my-schedule"
                                        element={<MySchedule />}
                                    />
                                </Route>
                            </Routes>
                        </BrowserRouter>
                    </StateContext.Provider>
                </DispatchContext.Provider>
            </LanguageContext.Provider>
        </IntlProvider>
    );
};

export default App;
