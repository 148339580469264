import React from 'react'

import { Box, Typography, Grid } from '@mui/material'
import { styled } from '@mui/system'

import { MyContainer, MyText } from '../../../components'
import { FormattedMessage } from "react-intl";

const Title = styled(Typography)(({ theme }) => ({
    color: 'white',
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontStyle: 'normal',
    textShadow: '1px 2px 4px rgba(0, 0, 0, 0.25)',
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
        fontSize: 30,
        textAlign: 'center'
    },
}))

const GridItem = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}))

const GridItemShow = styled(Grid)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        marginBottom: '-100px',
        marginTop: '-50px'
    },
}))

const GridItemColumn = styled(Grid)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
        display: 'none'
    },
}))

const Img = styled('img')(({ theme }) => ({
    width: '95%',
    [theme.breakpoints.down('sm')]: {
        width: '100%'
    },
}))

const StartScreen = () => {
    return (
        <MyContainer wrapper={true} sx={{
            // background: `url(${"/img/Background/backblue.png"})`,
            // backgroundSize: 'cover',
            // backgroundRepeat: 'no-repeat',
            // backgroundPosition: 'bottom',
            background: 'linear-gradient(180deg, rgba(36, 0, 255, 0.2) 0%, rgba(0, 148, 255, 0.0135417) 100%), #30D5C8',
            display: 'flex',
            alignItems: 'center',
            color: 'white',
        }} minHeight={200}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <Title variant="h3">
                    Женское здоровье
                </Title>
                {/* <MyText variant="body1" sx={{ mt: 4 }}>
                    Женское здоровье — это самое ценное, что есть у каждой представительницы прекрасной половины человечества. Сохранение психологического и физического здоровья является самым важным и жизненно необходимым приоритетом для любого современного человека. Для женщины здоровье — это возможность сохранить молодость на многие годы.
                </MyText>
                <MyText variant="body1" sx={{ mt: 2 }}>
                    К сожалению, в любом возрасте женщину подстерегают различные заболевания, которые могут не просто вызвать беспокойство, но и обернуться серьезными проблемами, влияющими на качество жизни.
                </MyText>
                <MyText variant="body1" sx={{ mt: 2 }}>
                    Одним из аспектов, влияющих на женское здоровье, являются гинекологические заболевания, с которыми приходится сталкиваться каждой женщине. Это могут быть как относительно безобидные кольпиты, так и серьезные болезни, результатом которых может стать развитие тяжелых осложнений вплоть до онкологии и лишения возможности стать матерью. Именно поэтому девушки должны знать основные моменты, касающиеся вопросов женской половой сферы: нормальная продолжительность менструаций, показатели здорового гормонального фона, вопросы планирования беременности, симптомы наличия гинекологических заболеваний и другие.
                </MyText> */}
                {/* <Grid container sx={{ mt: 5 }}>
                    <GridItemShow item lg={3} xl={3} md={3} sm={3} xs={12}>
                        <img style={{ width: '120%', marginTop: 30 }} src="/img/Element/anal4.png" />
                    </GridItemShow>
                    <GridItemColumn item lg={3} xl={3} md={3} sm={3} xs={12}>
                        <Img src="/img/Element/anal1.png" />
                        <Img sx={{ mt: 1 }} src="/img/Element/anal3.png" />
                    </GridItemColumn>
                    <GridItem item lg={3} xl={3} md={3} sm={3} xs={12}>
                        <Img src="/img/Element/Frame48.png" />
                    </GridItem>
                    <GridItem item lg={3} xl={3} md={3} sm={3} xs={12}>
                        <Img src="/img/Element/anal2.png" />
                    </GridItem>
                </Grid> */}
            </Box>
        </MyContainer>
    )
}

export default StartScreen