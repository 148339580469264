import React, { useState, useEffect, useContext } from 'react'

import { MenuProfile } from '../../../components'
import API from '../../../utils/api'
import { DispatchContext } from '../../../store'
import themeMain from '../../../theme'

import { CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import cookie from 'js-cookie'
import { FormattedMessage } from "react-intl";
import InputMask from 'react-input-mask'

import { Button, TextField, Grid, Box, Container, Typography, InputLabel, FormControl, Select, MenuItem } from '@mui/material'

const BoxWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20
    },
}))

const FormProfile = () => {
    const [name, setName] = useState('')
    const [date, setDate] = useState("");

    const [snils, setSnils] = useState("");
    const [oms, setOms] = useState("");
    const [surname, setSurname] = useState("");
    const [fatherName, setFatherName] = useState("");
    // const [org, setOrg] = useState([{ name: '1', id: '1' }])
    // const [institution, setInstitution] = useState();
    const [loading, setLoading] = useState<boolean>(true)
    const dispatch = useContext(DispatchContext)

    const handleUpdateProfile = () => {
        API.putAccountUser({
            first_name: name,
            birth_date: date,
            snils: snils,
            oms: oms,
            last_name: surname,
            patronymic: fatherName,
            // organisation: institution
        }, dispatch)
    }

    useEffect(() => {
        API.getAccountUser().then((res) => {
            const data = res.data
            setName(data.first_name)
            setDate(data.birth_date)
            setSurname(data.last_name)
            setFatherName(data.patronymic)
            setSnils(data.snils)
            setOms(data.oms)
            // setInstitution(data.organisation == null ? 1 : data.organisation)
            cookie.set('date', data.birth_date)
            dispatch({ type: 'modalSurvey', payload: { username: data.first_name } })
            setLoading(false)
        })
        API.getOrg().then((res) => {
            console.log(res)
            // setOrg(res.data)
        })
    }, [])
    // const handleInstitution = (event: any) => {
        // setInstitution(event.target.value)
    // }
    return (
        <Container>
            <BoxWrapper>
                <MenuProfile />
                <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
                    <Box>
                        <Box mt={3}>
                            <Typography component="h1" variant="h5" align={'center'} color={themeMain.palette.primary.main}>
                                ПРОФИЛЬ
                            </Typography>
                        </Box>
                        {loading ?
                            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 3 }}>
                                <CircularProgress />
                            </Box> :
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid item xs={12} sm={6} style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <TextField id={"outlined-basic"} fullWidth label={<FormattedMessage id="registration_name" />} variant={"outlined"} required value={name}
                                        onChange={(e) => {
                                            setName(e.target.value)
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField id={"outlined-basic"} fullWidth label={<FormattedMessage id="registration_last_name" />} variant={"outlined"} required
                                        value={surname}
                                        onChange={(e) => {
                                            setSurname(e.target.value)
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id={"outlined-basic"} fullWidth label={<FormattedMessage id="registration_patronymic" />} variant={"outlined"} required
                                        value={fatherName}
                                        onChange={(e) => {
                                            setFatherName(e.target.value)
                                        }} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id={"outlined-basic"} label={<FormattedMessage id="birthdate_profile" />} type={"date"}
                                        required
                                        fullWidth
                                        variant={"outlined"}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={date}
                                        onChange={(e) => {
                                            setDate(e.target.value)
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputMask
                                        mask="999-999-999-99"
                                        disabled={false}
                                        value={snils}
                                        onChange={(e) => {
                                            setSnils(e.target.value)
                                        }}
                                    >
                                        {() => <TextField id={"phone"} label={<FormattedMessage id="snils_profile" />} variant={"outlined"} required fullWidth />}
                                    </InputMask>

                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id={"phone"} label={<FormattedMessage id="polis_profile" />} variant={"outlined"} name={"phone"}
                                        required
                                        fullWidth
                                        value={oms}
                                        onChange={(e) => {
                                            setOms(e.target.value)
                                        }}
                                    />
                                </Grid>
                                {/*<Grid item xs={12}>*/}
                                {/*    <FormControl fullWidth>*/}
                                {/*        <InputLabel id="demo-simple-select-label"><FormattedMessage id="foundation_profile" /></InputLabel>*/}
                                {/*        <Select*/}
                                {/*            labelId="demo-simple-select-label"*/}
                                {/*            id="demo-simple-select"*/}
                                {/*            value={institution}*/}
                                {/*            label={<FormattedMessage id="foundation_profile" />}*/}
                                {/*            onChange={handleInstitution}*/}
                                {/*        >*/}
                                {/*            {org.map((item, index) => (*/}
                                {/*                <MenuItem value={item.id} key={index}>{item.name}</MenuItem>*/}
                                {/*            ))}*/}
                                {/*        </Select>*/}
                                {/*    </FormControl>*/}
                                {/*</Grid>*/}
                                <Grid item xs={12}>
                                    <Button style={{ marginTop: 20, background: themeMain.palette.primary.main }}
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        onClick={handleUpdateProfile}
                                    >
                                        <FormattedMessage id="save_profile" />
                                    </Button>
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </Container>
            </BoxWrapper>
        </Container>
    )
}

export default FormProfile