import React from 'react'

import { Box } from '@mui/material'

import { SurveysListScreen } from '../../components'
import { CaruselPerson } from '../../constructor'
import StartScreen from './components/StartScreen'
import PartnersScreen from './components/PartnersScreen'
import ProfileInfoScreen from './components/ProfileInfoScreen'
import FiveScreen from './components/FiveScreen'

const HomePage = () => {
    return (
        <div >
            <div style={{ background: '#edf9f8', }}>
                <StartScreen />
                <PartnersScreen />
                <ProfileInfoScreen />
            </div>
            <FiveScreen />
            <div style={{ marginBottom: 100 }}>
                <CaruselPerson />
            </div>
            <SurveysListScreen />
        </div>
    )
}

export default HomePage